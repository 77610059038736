<template>
  <v-card
    color="primary"
  >
   <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="128"
      ></v-progress-circular>
    </v-overlay>

    <v-form 
      ref="OverviewForm"
      class="pa-4"
      :key="skillMutateTracker"
    >
    <!--The Purpose of the skillMutateTracker is to allow real time updating of the Overview Page 
        whenever character skills are updated.  All other mutatations are visible by Vue.js, including
        the update to Backgrounds in the $store, due to the use of Array.prototype.splice() to explicitly
        trigger the visibility.  Alternative workaround would be to use skillMutateTracker and the emitter
        function in the BackgroundForm.vue as well.
    -->
    <v-btn 
      @click="submitNewCharacter()"
      :disabled="buildTotal<buildSpent || missingMandatoryFields() || characterSubmitStatus == 201 || characterSubmitStatus == 409"
      >Submit New Character for Approval</v-btn>
      <div v-if="!overlay">
        <span v-if="characterSubmitStatus==201">Your Character Has Been Submitted Successfully</span>
        <span v-else-if="characterSubmitStatus==409">A character for this card number has already been submitted.  Please try reloading your page and trying again.</span>
        <span v-else-if="characterSubmitStatus==500">An error has occurred.  Please try reloading your page and trying again.</span>
        <span v-else></span>
      </div>
      <v-container>
        <v-card class="tertiary rounded-xl">
          <v-card-title class="justify-center friendly-text font-weight-bold">
            {{name}}
          </v-card-title>
          <v-card-subtitle class="d-flex flex-column flex-nowrap justify-space-between text--primary" >
            <div class="d-flex flex-row flex-nowrap justify-space-between">
              <div class="d-flex flex-column flex-nowrap justify-start">
                <div class="mr-6"><strong>Build Total: </strong>{{buildTotal}}</div>
                <div class="mr-6"><strong>Build Spent: </strong>{{buildSpent}}</div>
              </div>
              <div class="d-flex flex-column flex-nowrap mr-5">
              </div>
            </div>

            <div class="d-flex flex-column flex-nowrap justify-start">
              <div class="mr-6"><strong>Race: </strong>{{race}}</div>
              <div class="mr-6"><strong>Faith: </strong>{{religion == null ? "None" : religion}}</div>
            </div>
             <!-- Commenting out alignments block until fix implemented
            <div class="d-flex flex-row flex-nowrap justify-start">
              <div><strong>Alignments: </strong></div>
            </div>
            -->
          </v-card-subtitle>
          <v-card-text class="text--primary">
            <h2 class="friendly-text">Known Classes:</h2>
            <v-container class="d-flex flex-column flex-nowrap">
              <div class="d-flex flex-row flex-wrap justify-start">
                <div 
                v-for="(kc, index) in class_names.slice(1,5)" 
                :key="kc"
                class="flex-grow-0 flex-shrink-0 mr-6"
                ><strong>{{class_names_list[index+1]}}:</strong> {{kc.name}}</div>
              </div>
              <div v-if="class_names.slice(5,8).length > 0" class="d-flex flex-row flex-wrap justify-start">
                <div 
                v-for="(kc, index) in class_names.slice(5,8)" 
                :key="kc"
                class="flex-grow-0 flex-shrink-0 mr-6"
                ><strong>{{class_names_list[index+5]}}:</strong> {{kc.name}}</div>
              </div>
              <div v-if="class_names.slice(9,11).length > 0" class="d-flex flex-row flex-wrap justify-start">
                <div 
                v-for="kc in class_names.slice(9,11) "
                :key="kc"
                class="flex-grow-0 flex-shrink-0 mr-6"
                ><strong>{{class_names_list[index+9]}}:</strong> {{kc.name}}<v-spacer></v-spacer></div>
              </div>
            </v-container>
            <h2 class="friendly-text">Background Skills:</h2>
            <v-container>
              <div v-for="background in this.backgrounds"
                :key="background"
                >
                {{getBackgroundSkill(background)}}
              </div>
            </v-container>


            <h2 
              class="friendly-text"
              v-if="abilities_full.filter(s=>s.ability_type==15).length > 0"
              >Proficiencies:</h2>
            <v-container>
              <div v-for="weaponProf in abilities_full.filter(s=>s.ability_type==15)"
              :key="weaponProf">
              {{weaponProf.name}}: +{{abilities[weaponProf.abilities_id].quantity}}
              </div>
            </v-container>
            <v-spacer></v-spacer>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header color="primary" class="friendly-text"><strong>Skills</strong></v-expansion-panel-header>
                <v-expansion-panel-content color="primary">
                  <v-data-table
                    :headers="skills_headers"
                    :items="abilities_full.filter(s=>![4,5,13,14].includes(s.ability_type))"
                    :items-per-page="10"
                    :sort-by.sync="sortBy"
                    :key="skillMutateTracker"
                    class="elevation-1"
                    >
                    <template v-slot:item.ability_type="{ item }">
                      {{ abilityTypeString(skills.find(s=>s.abilities_id == item.abilities_id).ability_type) }}
                    </template>
                    </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                v-if="abilities_full.filter(s=>s.ability_type==4).length > 0"
                >
                <v-expansion-panel-header color="primary" class="friendly-text"><strong>Spells</strong></v-expansion-panel-header>
                <v-expansion-panel-content color="primary">
                  <v-data-table
                    :headers="spells_talents_headers"
                    :items="abilities_full.filter(s=>s.ability_type==4)"
                    :items-per-page="10"
                    :sort-by.sync="sortBy"
                    :key="skillMutateTracker"
                    class="elevation-1"
                  >
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                v-if="abilities_full.filter(s=>s.ability_type==5).length > 0"
                >
                <v-expansion-panel-header color="primary" class="friendly-text"><strong>Talents</strong></v-expansion-panel-header>
                <v-expansion-panel-content color="primary">
                  <v-data-table
                    :headers="spells_talents_headers"
                    :items="abilities_full.filter(s=>s.ability_type==5)"
                    :items-per-page="10"
                    :sort-by.sync="sortBy"
                    :key="skillMutateTracker"
                    class="elevation-1"
                  >
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <br>
            <v-textarea
              v-model="characterMemo"
              outlined
              color="contrast"
              shaped
              label="Character Memo"
              counter= "200"
              counter-value
              >
              </v-textarea>
          </v-card-text>
            </v-card>
      </v-container>      
    </v-form>
  </v-card>
</template>

<script>

import cardService from '@/services/cardService';

export default {
  components: {},
  data: () => ({
    overlay: false,
    characterSubmitStatus: null,
    sortBy: 'name',
    skills_headers: [
          {
            text: 'Skill Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Quantity', 
            sortable: true,
            value: 'quantity' },
          { text: 'Ability Type', 
            sortable: true,
            value: 'ability_type'},
          { text: 'Build Spent', 
            sortable: true,
            value: 'build_spent' },
        ],
    spells_talents_headers: [
          {
            text: 'Skill Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Rank', sortable:true, value: 'rank' },
        ],
      abilities_full: [],
    class_names_list: ["Common List", "Lower List 1", "Lower List 2", "Lower List 3", "Lower List 4", "Master List 1", "Master List 2", "Master List 3", "Exalted List 1", "Exalted List 2"],
  }),
  watch: {
    skillMutateTracker: function(){
      this.setAbilitiesFull()
    }
  },
  props: {
    class_list: Array,
    skills: Array,
    skillMutateTracker: Number,
    background_list: Array,
  },

  mounted: function(){
    this.skillMutateTracker++
  },

  computed: {
    class_names: {
      get(){
        return this.classes.map(a=> this.class_list.find(c => c.id == a))
      }
    },
    name: {
      get(){
        return this.$store.state.character.name;
      }
    },
    race: {
      get(){
        return this.$store.state.character.race;
      }
    },
    religion: {
      get(){
        return this.$store.state.character.religion;
      }
    },
    classes: {
      get(){
        return this.getClasses();
      }
    },
    abilities: {
      get(){
        return this.getSkills();
      }
    },
    
    backgrounds: {
      get(){
        return this.getBackgrounds();
      }
    },
    alignments: {
      get(){
        return this.getAlignments();
      }
    },
    requiresDirectorApproval: {
      get(){
        return this.$store.state.character.directorApprovalRequired
      }
    },
    buildSpent: {
      get(){
        return this.$store.state.character.buildSpent
      }
    },
    buildTotal: {
      get(){
        return this.$store.state.character.buildTotal
      }
    },
    characterMemo: {
      get(){
        return this.$store.state.character.characterMemo
      },
      set(value){
        this.$store.state.character.characterMemo = value
      }
    }
  },
  methods: {
    missingMandatoryFields(){
      let eventsAttended = this.$store.state.character.eventsAttended
      let backstory = this.$store.state.character.backstory
      return this.backgrounds.includes(null)||this.classes[1]==null||this.name==""||this.race==""||isNaN(eventsAttended)||backstory.length>10000||this.characterMemo.length>200
    },

    setAbilitiesFull(){
      console.log("updating")
      let a_full = Object.keys(this.abilities).map(a=> this.skills.find(s => s.abilities_id == a))
        a_full.forEach(function(a){
          a.build_spent = this.abilities[a.abilities_id].cost;
          a.quantity = this.abilities[a.abilities_id].quantity;
          }, this)
        this.abilities_full = a_full
    },

    getClassName(c_id){
      return this.class_list.filter(c => c["id"]==c_id)[0]["name"];
    },

    getBackgroundSkill(b_id){
      let b = (b_id==null?null:this.background_list.find(b => b["id"]==b_id))
      if (b == null){
        return ""
      }
      else {
        return b["name"]+ " - " + b['skill']["name"]
      }
    },

    getSkillName(s_id){
      return s_id==null?"":this.skills.filter(s => s["abilities_id"]==s_id)[0]["name"]; 
    },

    getClasses(){
      return this.$store.state.character.classes.filter(function(c){return (c!=null)});
    },
    getSkills(){
      return this.$store.state.character.skills;
    },
    getBackgrounds(){
      return this.$store.state.character.background;
    },
    getAlignments(){
      let dedup = (items) => items.filter((v,i) => items.indexOf(v) === i)
      return dedup(this.$store.state.character.alignments.filter(a=>a!=null))
    },
    abilityTypeString(id){
      switch(id){
        case 1: return "Literacy"
        case 2: return "Passive"
        case 3: return "Periodic"
        case 4: return "Spell"
        case 5: return "Talent"
        case 6: return "Defense"
        case 7: return "Attack"
        case 8: return "Background"
        case 9: return "Attribute"
        case 10: return "Paragon"
        case 11: return "Lore"
        case 12: return "Hidden"
        case 13: return "Spell Purchase"
        case 14: return "Talent Purchase"
        case 15: return "Damage Proficiency"
        case 16: return "Prestige Skill"
        case 17: return "Prestige"
      }
    },

    async submitNewCharacter(timeoutCheck=false){
      this.overlay = true
      var that = this
      if(timeoutCheck==false){
        setTimeout(function(){
            if(that.overlay==true){
            that.overlay=false; 
            console.log("Emergency Timeout Used"); 
            that.submitNewCharacter(timeoutCheck=true)
          }
          }, 15000)
      }
      //Create a fake delay for character submission to improve UX
      setTimeout(() => {
           if(timeoutCheck==false){
            this.overlay = false
          }
        }, 3500)
      
      var response = await cardService.submitNewCharacter(this.$store.state.token);
      if(response.status == 409) {  
        if(timeoutCheck==true){
          this.characterSubmitStatus = 200
          this.$notify({
                    group: 'authentication',
                    title: `Submitted`,
                    text: `Your new character has been successfully submitted.`,
                    type: 'success'
                })
        }
        else{
          this.characterSubmitStatus = 409
          this.$notify({
                    group: 'authentication',
                    title: `Something went wrong`,
                    text: `A character with this card number has already been submitted.  Please reload and try again..`,
                    type: 'warning'
                })
          this.$router.push({"name": "MyCharacters"})
        }
      }
      else if(response.status != 201){
        this.characterSubmitStatus = 500
        this.$notify({
                    group: 'authentication',
                    title: `Error`,
                    text: `There appears to be an issue on the backend.  Please try reloading and submitting again.`,
                    type: 'error'
                })
      }
      else{
        this.characterSubmitStatus = 201
        this.$notify({
                    group: 'authentication',
                    title: `Submitted`,
                    text: `Your new character has been successfully submitted.`,
                    type: 'success'
                })
        this.$router.push({"name": "MyCharacters"})
      }
    }
  }
}
</script>