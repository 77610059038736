import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/home/Home'
import Dashboard from '@/components/dashboard/Dashboard'
import PrivacyPolicy from '@/components/documentation/PrivacyPolicy'
import TermsOfService from '@/components/documentation/TermsOfService'
import CardBuilder from '@/components/card_builder/CardBuilder'
import CardBuilderCustomCharacter from '@/components/card_builder/CardBuilderCustomCharacter'
import CardEdit from '@/components/card_builder/CardEdit'
import CardEditNoSubmission from '@/components/card_builder/CardEditNoSubmission'
import MyCharacters from '@/components/player_view/MyCharacters'
import CharacterApproval from '@/components/marshal_view/CharacterApproval'
import CharacterCheckIn from '@/components/staff_view/CharacterCheckIn'
import Logout from '@/components/registration/Logout'
import store from '@/store'
import playerService from '@/services/playerService'
import CharacterView from '@/components/player_view/CharacterView'
import Events from '@/components/staff_view/Events'
import Calendar from '@/components/player_view/Calendar'
import UpdateCharacterCards from '@/components/marshal_view/UpdateCharacterCards'
import CharacterUpdate from '@/components/marshal_view/CharacterUpdate'
import CharactersPendingUpdate from '@/components/marshal_view/CharactersPendingUpdate'
import RoleDelegation from '@/components/staff_view/RoleDelegation'

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            beforeEnter: (to, from, next) => {
              const user = JSON.parse(store.state.authenticatedUser)
              if(user) {
                next()
              } else {
                next('/')
                }
            }

        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout,
            beforeEnter: (to, from, next) => {
                if(store.state.authenticatedUser) {
                store.dispatch('logout')
                next('/?logout=true')
                }
                else{
                    next('/')
                }
            }
        },
        {
            path: '/privacy-policy',
            name: 'privacy-policy',
            component: PrivacyPolicy
        },
        {
            path: '/terms-of-service',
            name: 'terms-of-service',
            component: TermsOfService,
        },
        {
            path: '/characters/new',
            name: 'cardbuilder',
            component: CardBuilder,
            beforeEnter: (to, from, next) => {
              const user = JSON.parse(store.state.authenticatedUser)
              if(user && playerService.getPlayerRemainingCharacterCount()>0) {
                next()
              } else {
                next("/")
                }
            }
        },
        {
            path: '/characters/custom',
            name: 'cardbuildercustom',
            component: CardBuilderCustomCharacter,
            beforeEnter: (to, from, next) => {
              const user = JSON.parse(store.state.authenticatedUser)
              if(user && playerService.getPlayerRemainingCharacterCount()>0) {
                next()
              } else {
                next("/")
                }
            }
        },
        {
            path: '/characters/edit/:cardNumber',
            name: 'cardedit',
            component: CardEdit,
            props: true,
            beforeEnter: (to, from, next) => {
              const user = JSON.parse(store.state.authenticatedUser)
              if (user){
                const suffix = to.params.cardNumber.replace(user.player.cardnumber_prefix, "")
                var existingCards = "abcdefghij"
                user.player.card_suffix_list.split('').forEach(letter => {
                    existingCards = existingCards.replace(letter, "")
                });
                if(suffix.length==1 && existingCards.includes(suffix)) {
                    next()
                } else {
                    next("/characters")
                    }
              }
              else next("/")
            }
        },
        {
            path: '/characters/wizard/:cardNumber',
            name: 'cardeditnosubmission',
            component: CardEditNoSubmission,
            props: true,
            beforeEnter: (to, from, next) => {
              const user = JSON.parse(store.state.authenticatedUser)
              if (user){
                const suffix = to.params.cardNumber.replace(user.player.cardnumber_prefix, "")
                var existingCards = "abcdefghij"
                user.player.card_suffix_list.split('').forEach(letter => {
                    existingCards = existingCards.replace(letter, "")
                });
                if(suffix.length==1 && existingCards.includes(suffix)) {
                    next()
                } else {
                    next("/characters")
                    }
              }
              else next("/")
            }
        },
        {
            path: '/characters/view/:cardNumber',
            name: 'CharacterView',
            component: CharacterView,
            props: true,
            beforeEnter: (to, from, next) => {
              const user = JSON.parse(store.state.authenticatedUser)
              if (user){
                if(user.player.is_staff || user.player.is_director || user.player.is_marshal || user.player.is_admin){
                    next()
                }
                else{
                    const suffix = to.params.cardNumber.replace(user.player.cardnumber_prefix, "")
                    var existingCards = "abcdefghij"
                    user.player.card_suffix_list.split('').forEach(letter => {
                        existingCards = existingCards.replace(letter, "")
                    });
                    if(suffix.length==1 && existingCards.includes(suffix)) {
                        next()
                    } else {
                        next("/characters")
                        }
                    }
              }
              else next("/")
            }
        },
        {
            path: '/password_reset/:resetToken',
            name: 'passwordreset',
            props: true,
            component: () => import("../components/password_reset/Password_Reset.vue"),
        },
        {
            path: '/email_verification/:verificationToken',
            name: 'emailverification',
            props: true,
            component: () => import("../components/email_verification/Email_Verification.vue"),
        },
        {
            path: '/characters',
            name: 'MyCharacters',
            component: MyCharacters,
            beforeEnter: (to, from, next) => {
              const user = JSON.parse(store.state.authenticatedUser)
              if(user) {
                next()
              } else {
                next('/')
                }
            }
        },
        {
            path: '/marshal/approvals',
            name: 'CharacterApproval',
            component: CharacterApproval,
            beforeEnter: (to, from, next) => {
              const user = JSON.parse(store.state.authenticatedUser)
              if(user){
                if(user.player.is_staff || user.player.is_director || user.player.is_marshal || user.player.is_admin){
                    next()
                }
              } else {
                next('/')
                }
            }
        },
        {
            path: '/staff/check_in',
            name: 'CharacterCheckIn',
            component: CharacterCheckIn,
            beforeEnter: (to, from, next) => {
              const user = JSON.parse(store.state.authenticatedUser)
              if(user){
                if(user.player.is_staff || user.player.is_director || user.player.is_marshal || user.player.is_admin){
                    next()
                }
              } else {
                next('/')
                }
            }
        },
        {
            path: '/staff/events',
            name: 'events',
            component: Events,
            beforeEnter: (to, from, next) => {
              const user = JSON.parse(store.state.authenticatedUser)
              if(user){
                if(user.player.is_staff || user.player.is_director || user.player.is_admin){
                    next()
                }
              } else {
                next('/')
                }
            }
        },
        {
          path: '/marshal/pending',
          name: 'CharactersPendingUpdate',
          component: CharactersPendingUpdate,
          beforeEnter: (to, from, next) => {
            const user = JSON.parse(store.state.authenticatedUser)
            if(user){
              if(user.player.is_marshal || user.player.is_staff || user.player.is_director || user.player.is_admin){
                  next()
              }
            } else {
              next('/')
              }
          }
      },
        {
            path: '/calendar',
            name: 'Calendar',
            component: Calendar,
            beforeEnter: (to, from, next) => {
              const user = JSON.parse(store.state.authenticatedUser)
              if(user) {
                next()
              } else {
                next('/')
                }
            }
        },
        {
          path: '/marshal/update/:cardNumber',
          name: 'UpdateCharacterCards',
          component: UpdateCharacterCards,
          props: true,
          beforeEnter: (to, from, next) => {
            const user = JSON.parse(store.state.authenticatedUser)
            if (user.player.is_marshal || user.player.is_staff || user.player.is_director || user.player.is_admin){
              next()
              }
            else {
              next("/")
            }
          }
      },
      {
        path: '/marshal/update',
        name: 'CharacterUpdate',
        component: CharacterUpdate,
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(store.state.authenticatedUser)
          if (user.player.is_marshal || user.player.is_staff || user.player.is_director || user.player.is_admin){
            next()
            }
          else {
            next("/")
          }
        }
    },
    {
      path: '/staff/roles',
      name: 'RoleDelegation',
      component: RoleDelegation,
      beforeEnter: (to, from, next) => {
        const user = JSON.parse(store.state.authenticatedUser)
        if (user.player.is_staff || user.player.is_director || user.player.is_admin){
          next()
          }
        else {
          next("/")
        }
      }
  },
    ]
})


