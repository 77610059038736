<template>
  <v-card
    color="primary"
  >
   <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="128"
      ></v-progress-circular>
    </v-overlay>

    <v-form 
      ref="EditOverviewForm"
      class="pa-4"
      :key="skillMutateTracker"
    >
    <!--The Purpose of the skillMutateTracker is to allow real time updating of the Overview Page 
        whenever character skills are updated.  All other mutatations are visible by Vue.js, including
        the update to Backgrounds in the $store, due to the use of Array.prototype.splice() to explicitly
        trigger the visibility.  Alternative workaround would be to use skillMutateTracker and the emitter
        function in the BackgroundForm.vue as well.
    -->
    <v-btn 
      @click="submitUpdateCharacter()"
      :disabled="buildTotal<buildSpent || missingMandatoryFields() /*|| characterSubmitStatus == 200 || characterSubmitStatus == 404*/"
      >Update Character</v-btn>
      <div v-if="!overlay">
        <span v-if="characterSubmitStatus==200">Your Character Update Has Been Submitted Successfully</span>
        <span v-else-if="characterSubmitStatus==404">A character for this card number does not exist within the database.  Please try reloading your page and try again.</span>
        <span v-else-if="characterSubmitStatus==500">An error has occurred.  Please try reloading your page and trying again.</span>
        <span v-else></span>
      </div>
      <v-container>
        <v-card class="tertiary rounded-xl">
          <v-card-title class="justify-center friendly-text font-weight-bold">
            {{name}}
          </v-card-title>
          <v-card-subtitle class="d-flex flex-column flex-nowrap justify-space-between text--primary" >
            <div class="d-flex flex-row flex-nowrap justify-space-between">
              <div class="d-flex flex-column flex-nowrap justify-start">
                <div class="mr-6"><strong>Build Total: </strong>{{buildTotal}}</div>
                <div class="mr-6"><strong>Build Spent: </strong>{{buildSpent}}</div>
              </div>
              <div class="d-flex flex-column flex-nowrap mr-5">
              </div>
            </div>

            <div class="d-flex flex-column flex-nowrap justify-start">
              <div class="mr-6"><strong>Race: </strong>{{race}}</div>
              <div class="mr-6"><strong>Faith: </strong>{{religion == null ? "None" : religion}}</div>
            </div>
            <!-- Commenting out alignments block until fix implemented
            <div class="d-flex flex-row flex-nowrap justify-start">
              <div><strong>Alignments: </strong></div>
            </div>
            -->
          </v-card-subtitle>
          <v-card-text class="text--primary">
            <h2 class="friendly-text">Known Classes:</h2>
            <v-container class="d-flex flex-column flex-nowrap">
              <div class="d-flex flex-row flex-wrap justify-start">
                <div 
                v-for="(kc, index) in class_names.slice(1,5)" 
                :key="kc"
                class="flex-grow-0 flex-shrink-0 mr-6"
                ><strong>{{class_names_list[index+1]}}:</strong> {{kc.name}}</div>
              </div>
              <div v-if="class_names.slice(5,8).length > 0" class="d-flex flex-row flex-wrap justify-start">
                <div 
                v-for="(kc, index) in class_names.slice(5,8)" 
                :key="kc"
                class="flex-grow-0 flex-shrink-0 mr-6"
                ><strong>{{class_names_list[index+5]}}:</strong> {{kc.name}}</div>
              </div>
              <div v-if="class_names.slice(9,11).length > 0" class="d-flex flex-row flex-wrap justify-start">
                <div 
                v-for="kc in class_names.slice(9,11) "
                :key="kc"
                class="flex-grow-0 flex-shrink-0 mr-6"
                ><strong>{{class_names_list[index+9]}}:</strong> {{kc.name}}<v-spacer></v-spacer></div>
              </div>
            </v-container>
            <h2 class="friendly-text">Background Skills:</h2>
            <v-container>
              <div v-for="background in this.backgrounds"
                :key="background"
                >
                {{getBackgroundSkill(background)}}
              </div>
            </v-container>


            <h2 
              class="friendly-text"
              v-if="abilities_full.filter(s=>s.ability_type==15).length > 0"
              >Proficiencies:</h2>
            <v-container>
              <div v-for="weaponProf in abilities_full.filter(s=>s.ability_type==15)"
              :key="weaponProf">
              {{weaponProf.name}}: +{{abilities[weaponProf.abilities_id].quantity}}
              </div>
            </v-container>
            <v-spacer></v-spacer>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header color="primary" class="friendly-text"><strong>Skills</strong></v-expansion-panel-header>
                <v-expansion-panel-content color="primary">
                  <v-data-table
                    :headers="skills_headers"
                    :items="abilities_full.filter(s=>![4,5,13,14].includes(s.ability_type))"
                    :items-per-page="10"
                    :sort-by.sync="sortBy"
                    :key="skillMutateTracker"
                    class="elevation-1"
                    >
                    <template v-slot:item.ability_type="{ item }">
                      {{ abilityTypeString(skills.find(s=>s.abilities_id == item.abilities_id).ability_type) }}
                    </template>
                    </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                v-if="abilities_full.filter(s=>s.ability_type==4).length > 0"
                >
                <v-expansion-panel-header color="primary" class="friendly-text"><strong>Spells</strong></v-expansion-panel-header>
                <v-expansion-panel-content color="primary">
                  <v-data-table
                    :headers="spells_talents_headers"
                    :items="abilities_full.filter(s=>s.ability_type==4)"
                    :items-per-page="10"
                    :sort-by.sync="sortBy"
                    :key="skillMutateTracker"
                    class="elevation-1"
                  >
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                v-if="abilities_full.filter(s=>s.ability_type==5).length > 0"
                >
                <v-expansion-panel-header color="primary" class="friendly-text"><strong>Talents</strong></v-expansion-panel-header>
                <v-expansion-panel-content color="primary">
                  <v-data-table
                    :headers="spells_talents_headers"
                    :items="abilities_full.filter(s=>s.ability_type==5)"
                    :items-per-page="10"
                    :sort-by.sync="sortBy"
                    :key="skillMutateTracker"
                    class="elevation-1"
                  >
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <br>
            <v-textarea
              v-model="characterMemo"
              outlined
              color="contrast"
              shaped
              label="Character Memo"
              counter= "200"
              counter-value
              >
              </v-textarea>
          </v-card-text>
            </v-card>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>

import cardService from '@/services/cardService';

export default {
  components: {},
  data: () => ({
    overlay: false,
    characterSubmitStatus: null,
    sortBy: 'name',
    skills_headers: [
          {
            text: 'Skill Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Quantity', 
            sortable: true,
            value: 'quantity' },
          { text: 'Ability Type', 
            sortable: true,
            value: 'ability_type'},
          { text: 'Build Spent', 
            sortable: true,
            value: 'build_spent' },
        ],
    spells_talents_headers: [
          {
            text: 'Skill Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Rank', sortable:true, value: 'rank' },
        ],
      abilities_full: [],
    class_names_list: ["Common List", "Lower List 1", "Lower List 2", "Lower List 3", "Lower List 4", "Master List 1", "Master List 2", "Master List 3", "Exalted List 1", "Exalted List 2"],
  }),
  watch: {
    skillMutateTracker: function(){
      this.setAbilitiesFull()
    }
  },
  props: {
    class_list: Array,
    skills: Array,
    skillMutateTracker: Number,
    background_list: Array,
    originalCharacter: Object,
  },
  mounted: function(){
    //check backgrounds to see if they have already been loaded
    if(this.backgrounds.includes(null)){
      //if we have nulls, this has not yet been loaded, so let's load the values
      this.$store.state.character.background.splice(0, 1, this.originalCharacter.background_1.id)
      this.$store.state.character.background.splice(1, 1, this.originalCharacter.background_2.id)
    }
    //check to see if the skills/classes/alignments have been loaded
    //to cheat and save time, we check to see if Lower List 1 is loaded, since if
    //it is not, we can safely assume none of this page has yet been loaded in
    if(this.classes[1]==null){
      //Load in known classes
      var knownClasses = this.classes
      this.originalCharacter.known_classes.forEach(kc => {
        knownClasses[kc.class_index] = kc.class_field.id
      });

      //Load in character alignments
      var light = this.originalCharacter.is_light ? "Light" : null
      var dark = this.originalCharacter.is_dark ? "Dark" : null
      var chaos = this.originalCharacter.is_chaos ? "Chaos" : null
      var order = this.originalCharacter.is_order ? "Order" : null
      this.alignments = [light, dark, chaos, order]
      
      //Load in character skills
      //Also Calculate Built total
      var knownSkills = {}
      this.originalCharacter.abilities.forEach(ka => {
        knownSkills[ka.ability.abilities_id] = {quantity: ka.quantity, cost: ka.build_spent}
        this.buildSpent += ka.build_spent
      });
      this.abilities = knownSkills

      //Load in selected master list skills
      const masterSkills = this.originalCharacter.master_list_skills[0]        
      this.$store.commit('updateSelectedMasterSkills', [masterSkills.master_skill_1, masterSkills.master_skill_2, masterSkills.master_skill_3, masterSkills.master_skill_4, masterSkills.master_skill_5, masterSkills.master_skill_6, masterSkills.master_skill_7, masterSkills.master_skill_8, masterSkills.master_skill_9])

      //Load in selected weapon prof skills
      const weaponProfs = this.originalCharacter.weapon_proficiency_skills[0]        
      this.$store.commit('updateSelectedWeaponProf', [weaponProfs.weapon_proficiency_skill_1, weaponProfs.weapon_proficiency_skill_2, weaponProfs.weapon_proficiency_skill_3, weaponProfs.weapon_proficiency_skill_4, weaponProfs.weapon_proficiency_skill_5, weaponProfs.weapon_proficiency_skill_6, weaponProfs.weapon_proficiency_skill_7, weaponProfs.weapon_proficiency_skill_8, weaponProfs.weapon_proficiency_skill_9, weaponProfs.weapon_proficiency_skill_10, weaponProfs.weapon_proficiency_skill_11, weaponProfs.weapon_proficiency_skill_12])
    }
    this.skillMutateTracker++
  },
  computed: {
    class_names: {
      get(){
        return this.classes.map(a=> this.class_list.find(c => c.id == a))
      }
    },
    name: {
      get(){
        return this.$store.state.character.name;
      }
    },
    race: {
      get(){
        return this.$store.state.character.race;
      }
    },
    religion: {
      get(){
        return this.$store.state.character.religion;
      }
    },
    classes: {
      get(){
        return this.getClasses();
      }
    },
    abilities: {
      get(){
        return this.getSkills();
      },
      set(value){
        this.$store.state.character.skills = value
      }
    },
    
    backgrounds: {
      get(){
        return this.getBackgrounds();
      }
    },
    alignments: {
      get(){
        return this.getAlignments();
      },
      set(value){
        this.$store.state.character.alignments = value;
      }
    },
    requiresDirectorApproval: {
      get(){
        return this.$store.state.character.directorApprovalRequired
      }
    },
    buildSpent: {
      get(){
        return this.$store.state.character.buildSpent
      }
    },
    buildTotal: {
      get(){
        return this.$store.state.character.buildTotal
      }
    },
    characterMemo: {
      get(){
        return this.$store.state.character.characterMemo
      },
      set(value){
        this.$store.state.character.characterMemo = value
      }
    }
  },
  methods: {
    missingMandatoryFields(){
      let backstory = this.$store.state.character.backstory
      return this.backgrounds.includes(null)||this.classes[1]==null||this.name==""||this.race==""||backstory.length>10000 || this.characterMemo.length>200
    },

    setAbilitiesFull(){
      let a_full = Object.keys(this.abilities).map(a=> this.skills.find(s => s.abilities_id == a))
        a_full.forEach(function(a){
          a.build_spent = this.abilities[a.abilities_id].cost;
          a.quantity = this.abilities[a.abilities_id].quantity;
          }, this)
        this.abilities_full = a_full
    },

    getClassName(c_id){
      return this.class_list.find(c => c["id"]==c_id)["name"];
    },

    getBackgroundSkill(b_id){
      let b = (b_id==null?null:this.background_list.find(b => b["id"]==b_id))
      if (b == null){
        return ""
      }
      else {
        return b["name"]+ " - " + b['skill']["name"]
      }
    },

    getSkillName(s_id){
      return s_id==null?"":this.skills.find(s => s["abilities_id"]==s_id)["name"]; 
    },

    getClasses(){
      return this.$store.state.character.classes.filter(function(c){return (c!=null)});
    },
    getSkills(){
      return this.$store.state.character.skills;
    },
    getBackgrounds(){
      return this.$store.state.character.background;
    },
    getAlignments(){
      let dedup = (items) => items.filter((v,i) => items.indexOf(v) === i)
      return dedup(this.$store.state.character.alignments.filter(a=>a!=null&&a!=""))
    },

    async submitUpdateCharacter(timeoutCheck=false){
      let zip = (a, b) => a.map((k, i) => [k, b[i]]);
      let arraysEqual = function(a, b) {
          if (a === b) return true;
          if (a == null || b == null) return false;
          if (a.length !== b.length) return false;

          for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
          }
          return true;
        };

      var skillChange = {}
      var originalClasses = [null, null, null,null, null, null, null, null, null, null]
      this.originalCharacter.known_classes.forEach(kc => originalClasses[kc.class_index] = [kc.class_field.id, (kc.entity==""?null:kc.entity)])
      originalClasses.forEach(function(oc, index) {
                                this[index] = oc == null ? [null, null]: oc
                              }, originalClasses)
      let currentClasses = zip(this.$store.state.character.classes, this.$store.state.character.entityList)
      console.log("Classes Have Changed?: " + !arraysEqual(currentClasses.map(cc => cc[0]+"|"+cc[1]), originalClasses.map(oc => oc[0]+"|"+oc[1])))

      let changedClasses = []
      currentClasses.forEach(function(value, index){
        let c = {class_index: index, class_id: value[0], entity:value[1]}
        if(originalClasses[c.class_index][0] != c.class_id || originalClasses[c.class_index][1]!=c.entity){
          changedClasses.push(c)
        }
      })      
      var newSkills = this.$store.state.character.skills
      var currentSkills = {}
      this.originalCharacter.abilities.forEach(c=> currentSkills[c.ability.abilities_id.toString()] = {quantity: c.quantity, cost: c.build_spent, skillfullness: c.skillfullness})
      
      let new_ids = Object.keys(newSkills)
      let current_ids = Object.keys(currentSkills)

      //find removed skills
      //difference = A.filter(x => !B.includes(x));
      //intersection = A.filter(x => B.includes(x));
      //symmetrical_difference = A.filter(x => !B.includes(x)).concat(B.filter(x => !A.includes(x)))

      let added_skills = new_ids.filter(x => !current_ids.includes(x))
      let removed_skills = current_ids.filter(x => !new_ids.includes(x))
      let same_skills = current_ids.filter(x => new_ids.includes(x))

      //add skills
      added_skills.forEach(skill_id => skillChange[skill_id] = {
          quantity: newSkills[skill_id].quantity, 
          cost: newSkills[skill_id].cost,
          skillfullness: newSkills[skill_id].skillfullness,
          operation: "add"
          })
      //remove skills
      removed_skills.forEach(skill_id => skillChange[skill_id] = {
        quantity: 0,
        cost: 0,
        skillfullness: 0,
        operation: "remove"
        })
      //update changed skills
      same_skills.forEach(function(skill_id){
        let operation = (newSkills[skill_id].quantity!=currentSkills[skill_id].quantity || newSkills[skill_id].cost!=currentSkills[skill_id].cost || newSkills[skill_id].skillfullness!=currentSkills[skill_id].skillfullness )? "update" : ""
        if (operation=="update"){
          skillChange[skill_id] = 
          {
            quantity: newSkills[skill_id].quantity,
            cost: newSkills[skill_id].cost,
            skillfullness: newSkills[skill_id].skillfullness,
            operation: operation
          }
        }
      })

      //check to see if we have any skills changed
      console.log("Skill Changes?: " + (Object.keys(skillChange).length>0))

      //check for changes to known master skills
      let current_master_skills = Object.values(this.originalCharacter.master_list_skills[0]).slice(2)
      let new_master_skills = this.$store.state.character.selectedMasterSkills

      console.log("New Master Skills?: " + !arraysEqual(current_master_skills, new_master_skills))

      //check for changes to selected weapon profs
      let current_weapon_profs = Object.values(this.originalCharacter.weapon_proficiency_skills[0]).slice(2)
      let new_weapon_profs = this.$store.state.character.selectedWeaponProf

      console.log("New Weapon Prof Skills?: " + !arraysEqual(current_weapon_profs, new_weapon_profs))

      //check name

      //check to make sure total build hasn't been modified

      //check to make sure that this character isn't already approved
      console.log("Character already approved?: "+this.originalCharacter.is_approved)

      //building the update payload
      let data = {
        cardNumber: this.originalCharacter.cardnumber,
        character: this.originalCharacter.id,
        player: this.originalCharacter.player,
        classes: changedClasses,
        skills: skillChange,
        event: 1,
        selectedMasterSkills: (!arraysEqual(current_master_skills, new_master_skills))?new_master_skills: null,
        selectedWeaponProf: (!arraysEqual(current_weapon_profs, new_weapon_profs))?new_weapon_profs: null,
      }
      if((this.originalCharacter.is_approved && !this.originalCharacter.allow_rewrite)|| this.originalCharacter.build_total!= this.$store.state.character.buildTotal || this.originalCharacter.past_event_count!= this.$store.state.character.eventsAttended){
        //character cannot be updated
        console.log("You are not authorized to make these edits.")
        return
      }

      this.overlay = true
      var that = this
      if(timeoutCheck==false){
        setTimeout(function(){
            if(that.overlay==true){
            that.overlay=false; 
            console.log("Emergency Timeout Used"); 
            //that.submitUpdateCharacter(timeoutCheck=true)
          }
          }, 15000)
      }
      //Create a fake delay for character submission to improve UX
      setTimeout(() => {
           if(timeoutCheck==false){
            this.overlay = false
          }
        }, 3500)
      var response = await cardService.updateCharacter(data, this.$store.state.token);
      if(response.status == 404) {  
        if(timeoutCheck==true){
          this.characterSubmitStatus = 200
          this.$notify({
                    group: 'authentication',
                    title: `Submitted`,
                    text: `Your character update has been successfully submitted.`,
                    type: 'success'
                })
          this.$router.push({"name": "MyCharacters"})
        }
        else{
          this.characterSubmitStatus = 404
          this.$notify({
                    group: 'authentication',
                    title: `Error`,
                    text: `A character with this card number does not exist.`,
                    type: 'error'
                })
        }
      }
      else if(response.status != 200){
        this.characterSubmitStatus = 500
        this.$notify({
                    group: 'authentication',
                    title: `Error`,
                    text: `There appears to be an issue on the backend.  Please try reloading and submitting again.`,
                    type: 'error'
                })
      }
      else{
        this.characterSubmitStatus = 200
        this.$notify({
                    group: 'authentication',
                    title: `Submitted`,
                    text: `Your character update has been successfully submitted.`,
                    type: 'success'
                })
        this.$router.push({"name": "MyCharacters"})
      }
    },

    abilityTypeString(id){
      switch(id){
        case 1: return "Literacy"
        case 2: return "Passive"
        case 3: return "Periodic"
        case 4: return "Spell"
        case 5: return "Talent"
        case 6: return "Defense"
        case 7: return "Attack"
        case 8: return "Background"
        case 9: return "Attribute"
        case 10: return "Paragon"
        case 11: return "Lore"
        case 12: return "Hidden"
        case 13: return "Spell Purchase"
        case 14: return "Talent Purchase"
        case 15: return "Damage Proficiency"
        case 16: return "Prestige Skill"
        case 17: return "Prestige"
      }
    },
  }
}
</script>