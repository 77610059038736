import api from '@/services/api'
import store from '@/store'

export default {
  async printCard(cardNumber){
    try{
        const token = store.state.token
        var baseURL
        baseURL = `/print_card/${cardNumber}/`
        return await api.get(baseURL, {
          responseType: "arraybuffer", 
          headers:{
            "Authorization": `Bearer ${token}`,
          }
        
        })
    }catch (e) {
        if (e.response.status == 401) {
            // eslint-disable-next-line 
            console.error("Invalid token")
            // eslint-disable-next-line 
            store.dispatch('refreshToken')
              .then(() => {
                const token = store.state.token
                this.printCard(cardNumber, token)
              })
          }
        // eslint-disable-next-line no-console
        console.error(e);
        return e.response
    }
},
  async getCharacterCard(cardNumber){
      try{
          const token = store.state.token
          var baseURL
          baseURL = `/characters/${cardNumber}/`
          return await api.get(baseURL, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
          if (e.response.status == 401) {
              // eslint-disable-next-line 
              console.error("Invalid token")
              // eslint-disable-next-line 
              console.log("Token:" + token);
              store.dispatch('refreshToken')
                .then(() => {
                  const token = store.state.token
                  this.getCharacterCard(cardNumber, token)
                })
            }
          // eslint-disable-next-line no-console
          console.error(e);
          return e.response
      }
  },
  async getClasses(){
    try{
        const token = store.state.token
        var baseURL
        baseURL = `/class/`
        return await api.get(baseURL, {headers:{"Authorization": `Bearer ${token}`}})
    }catch (e) {
        if (e.response.status == 401) {
            // eslint-disable-next-line 
            console.error("Invalid token")
            // eslint-disable-next-line 
            console.log("Token:" + token);
            store.dispatch('refreshToken')
              .then(() => {
                const token = store.state.token
                this.getClasses(token)
              })
          }
        // eslint-disable-next-line no-console
        console.error(e);
        return e.response
    }
  },
  async getSkills(){
    try{
        const token = store.state.token
        var baseURL
        baseURL = `/abilities/`
        return await api.get(baseURL, {headers:{"Authorization": `Bearer ${token}`}})
    }catch (e) {
        if (e.response.status == 401) {
            // eslint-disable-next-line 
            console.error("Invalid token")
            // eslint-disable-next-line 
            console.log("Token:" + token);
            store.dispatch('refreshToken')
              .then(() => {
                const token = store.state.token
                this.getSkills(token)
              })
          }
        // eslint-disable-next-line no-console
        console.error(e);
        return e.response
    }
  },
  async getSkillsByClass(){
    try{
        const token = store.state.token
        var baseURL
        baseURL = `/abilities_by_class/`
        return await api.get(baseURL, {headers:{"Authorization": `Bearer ${token}`}})
    }catch (e) {
        if (e.response.status == 401) {
            // eslint-disable-next-line 
            console.error("Invalid token")
            // eslint-disable-next-line 
            console.log("Token:" + token);
            store.dispatch('refreshToken')
              .then(() => {
                const token = store.state.token
                this.getSkillsByClass(token)
              })
          }
        // eslint-disable-next-line no-console
        console.error(e);
        return e.response
    }
  },
  async getBackgrounds() {        
      try { 
          const token = store.state.token
          var baseURL
          baseURL = `/backgrounds/`
          return await api.get(baseURL, {headers: {"Authorization": `Bearer ${token}`}})
      } catch (e) {
          if (e.response.status == 401) {
            // eslint-disable-next-line 
            console.error("Invalid token")
            // eslint-disable-next-line 
            console.log("Token:" + token);
            store.dispatch('refreshToken')
              .then(() => {
                const token = store.state.token
                this.getBackgrounds(token)
              })
            }
          // eslint-disable-next-line no-console
          console.error(e);
          return e.response
      } 
  },
  async getRaces() {        
    try { 
        const token = store.state.token
        var baseURL
        baseURL = `/race/`
        return await api.get(baseURL, {headers: {"Authorization": `Bearer ${token}`}})
    } catch (e) {
        if (e.response.status == 401) {
          // eslint-disable-next-line 
          console.error("Invalid token")
          // eslint-disable-next-line 
          console.log("Token:" + token);
          store.dispatch('refreshToken')
            .then(() => {
              const token = store.state.token
              this.getRaces(token)
            })
          }
        // eslint-disable-next-line no-console
        console.error(e);
        return e.response
    } 
  },
  async getReligions() {        
    try { 
        const token = store.state.token
        var baseURL
        baseURL = `/religion/`
        return await api.get(baseURL, {headers: {"Authorization": `Bearer ${token}`}})
    } catch (e) {
        if (e.response.status == 401) {
          // eslint-disable-next-line 
          console.error("Invalid token")
          // eslint-disable-next-line 
          console.log("Token:" + token);
          store.dispatch('refreshToken')
            .then(() => {
              const token = store.state.token
              this.getReligions(token)
            })
          }
        // eslint-disable-next-line no-console
        console.error(e);
        return e.response
    } 
  },
  async getAlignmentByClass(){
    try{
      const token = store.state.token
      var baseURL
      baseURL = `/alignment_by_class/`
      return await api.get(baseURL, {headers:{"Authorization": `Bearer ${token}`}})
    }catch (e) {
      if (e.response.status == 401) {
          // eslint-disable-next-line 
          console.error("Invalid token")
          // eslint-disable-next-line 
          console.log("Token:" + token);
          store.dispatch('refreshToken')
            .then(() => {
              const token = store.state.token
              this.getClasses(token)
            })
        }
      // eslint-disable-next-line no-console
      console.error(e);
      return e.response
  }
  },
  async submitNewCharacter(){
    const zip = (a, b) => a.map((k, i) => [k, b[i]]);
    try{
        //construct the Post Data Here
        const playerData = JSON.parse(store.state.userPlayerProfile)
        const character = store.state.character
        const data = {
          character:{
          "allow_rewrite": 1,
          "cardnumber": playerData.cardnumber_prefix.toString() + playerData.card_suffix_list.charAt(0),
          "player": playerData.id,
          "active": 1,
          "last_updated_date": new Date().toISOString(),
          "name": character.name,
          "home_game": 1,
          "past_event_count": character.eventsAttended,
          "rewrite_notes": "Allow Free Rewrite",
          "is_new_character": 1,
          "race": character.race,
          "background_1": character.background[0],
          "background_2": character.background[1],
          "literacy": character.literacy,
          "build_total": character.buildTotal,
          "build_spent": character.buildSpent,
          "religion": character.religion != null? character.religion: "",
          "is_light": character.alignments.includes("Light"),
          "is_dark": character.alignments.includes("Dark"),
          "is_order": character.alignments.includes("Order"),
          "is_chaos": character.alignments.includes("Chaos"),
          "requires_director_approval": character.directorApprovalRequired,
          "is_approved": false,
          "is_retired": false,
          "backstory": character.backstory,
          "backstory_approved": false,
          "character_memo": character.characterMemo
        },
        classes: zip(character.classes, character.entityList),
        abilities: character.skills,
        master_selections: character.selectedMasterSkills,
        weapon_proficiencies: character.selectedWeaponProf
      }

        const token = store.state.token
        var baseURL
        baseURL = `/create_character/`
        return await api.post(baseURL, data, {headers:{"Authorization": `Bearer ${token}`}})
    }catch (e) {
        if (e.response.status == 401) {
            // eslint-disable-next-line 
            console.error("Invalid token")
            // eslint-disable-next-line 
            console.log("Token:" + token);
            store.dispatch('refreshToken')
              .then(() => {
                const token = store.state.token
                this.submitNewCharacter(token)
              })
          }
        // eslint-disable-next-line no-console
        console.error(e);
        return e.response
    }
  },
  async updateCharacter(data){
    try{
        //construct the Patch Data Here
        const character = store.state.character
        const editData = {
          character:{
          id: data.character,
          "last_updated_date": new Date().toISOString(),
          "name": character.name,
          "race": character.race,
          "background_1": character.background[0],
          "background_2": character.background[1],
          "literacy": character.literacy,
          "build_spent": character.buildSpent,
          "religion": character.religion != null? character.religion: "",
          "is_light": character.alignments.includes("Light"),
          "is_dark": character.alignments.includes("Dark"),
          "is_order": character.alignments.includes("Order"),
          "is_chaos": character.alignments.includes("Chaos"),
          "requires_director_approval": character.directorApprovalRequired,
          "is_approved": false,
          "is_retired": character.is_retired,
          "backstory": character.backstory,
          "character_memo": character.characterMemo
        },
        player: data.player,
        event: data.event,
        classes: data.classes,
        abilities: data.skills,
        master_selections: data.selectedMasterSkills,
        weapon_proficiencies: data.selectedWeaponProf
      }

        const token = store.state.token
        var baseURL
        baseURL = `/update_character/${data.cardNumber}/`
        return await api.patch(baseURL, editData, {headers:{"Authorization": `Bearer ${token}`}})
    }catch (e) {
        if (e.response.status == 401) {
            // eslint-disable-next-line 
            console.error("Invalid token")
            // eslint-disable-next-line 
            console.log("Token:" + token);
            store.dispatch('refreshToken')
              .then(() => {
                const token = store.state.token
                this.updateCharacter(data, token)
              })
          }
        // eslint-disable-next-line no-console
        console.error(e);
        return e.response
    }
  },
  async getCharacterAttendance(cardNumber){
    try{
      const token = store.state.token
      var baseURL
      baseURL = `/character_attendance/${cardNumber}/`
      return await api.get(baseURL, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
        if (e.response.status == 401) {
            // eslint-disable-next-line 
            console.error("Invalid token")
            // eslint-disable-next-line 
            console.log("Token:" + token);
            store.dispatch('refreshToken')
              .then(() => {
                const token = store.state.token
                this.getCharacterAttendance(cardNumber, token)
              })
          }
        // eslint-disable-next-line no-console
        console.error(e);
        return e.response
      }
  },
  async updateCharacterNotes(data, cardNumber){
    try{
        const token = store.state.token
        var baseURL
        baseURL = `/characters/${cardNumber}/`
        return await api.patch(baseURL, data, {headers:{"Authorization": `Bearer ${token}`}})
    }catch (e) {
        if (e.response.status == 401) {
            // eslint-disable-next-line 
            console.error("Invalid token")
            // eslint-disable-next-line 
            console.log("Token:" + token);
            store.dispatch('refreshToken')
              .then(() => {
                const token = store.state.token
                this.updateCharacterNotes(cardNumber, token)
              })
          }
        // eslint-disable-next-line no-console
        console.error(e);
        return e.response
    }
  },

  async getPlayerServicePoints(player_id){
    try{
      const token = store.state.token
      var baseURL
      baseURL = `/player_servicepoints/${player_id}/`
      return await api.get(baseURL, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
        if (e.response.status == 401) {
            // eslint-disable-next-line 
            console.error("Invalid token")
            // eslint-disable-next-line 
            console.log("Token:" + token);
            store.dispatch('refreshToken')
              .then(() => {
                const token = store.state.token
                this.getPlayerServicePoints(player_id, token)
              })
          }
        // eslint-disable-next-line no-console
        console.error(e);
        return e.response
      }
  },

  async getCharacterBuildPoints(cardnumber){
    try{
      const token = store.state.token
      var baseURL
      baseURL = `/character_buildpoints/${cardnumber}/`
      return await api.get(baseURL, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
        if (e.response.status == 401) {
            // eslint-disable-next-line 
            console.error("Invalid token")
            // eslint-disable-next-line 
            console.log("Token:" + token);
            store.dispatch('refreshToken')
              .then(() => {
                const token = store.state.token
                this.getCharacterBuildPoints(cardnumber, token)
              })
          }
        // eslint-disable-next-line no-console
        console.error(e);
        return e.response
      }
  }

  }