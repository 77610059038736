<template>
<v-layout>
  <v-container>
    <v-overlay :value="!classes_loaded || !skills_loaded || !skills_by_class_loaded || !alignments_by_class_loaded || !backgrounds_loaded || !religions_loaded || !races_loaded">
      <v-progress-circular
        indeterminate
        size="128"
      ></v-progress-circular>
    </v-overlay>
    <h1 class="friendly-text invert--text" align="center">Knight Realms - Character Card Builder</h1>
    <h2 v-show="!this.mobileLandscape" class="friendly-text invert--text" align="center"><br>Please Turn Your Mobile Device To Landscape Mode To Utilize The Card Builder.</h2>
    <v-tabs 
      v-show="this.mobileLandscape"
      centered
      background-color="tertiary"
      slider-color="contrast"
    >
      <v-tab>Character</v-tab>
      <v-tab-item>
        <CharacterForm
        :next="this.next" 
        :race_list="race_list"
        :religion_list="religion_list"
        :isNewCharacter="true"
        :startingBuild="50"
        />
      </v-tab-item>
      <v-tab>Backgrounds</v-tab>
      <v-tab-item background-color="primary">
        <BackgroundForm 
        :next="this.next" 
        :background_list="background_list"
        :skills="skills"
        :isNewCharacter="true"
        />
      </v-tab-item>
      <v-tab>Classes/Skills</v-tab>
      <v-tab-item>
        <ClassSkillForm 
          :next="this.next" 
          :class_list="class_list" 
          :skills="skills"
          :skills_by_class="skills_by_class"
          :alignment_by_class="alignment_by_class"
          @skill_mutate="skill_mutate"
          :isNewCharacter="true"
          /> 
      </v-tab-item>
      <v-tab>Overview</v-tab>
      <v-tab-item>
        <OverviewForm 
        :next="this.next"
        :class_list="class_list" 
        :skills="skills"
        :skillMutateTracker="skillMutateTracker"
        :background_list="background_list"
        /> 
      </v-tab-item>
    </v-tabs>
  </v-container>
</v-layout>
</template>

<script>

import CharacterForm from '@/components/card_builder/CharacterForm';
import BackgroundForm from '@/components/card_builder/BackgroundForm';
import ClassSkillForm from '@/components/card_builder/ClassSkillForm';
import OverviewForm from '@/components/card_builder/OverviewForm';
import cardService from '@/services/cardService';

export default {
  name: 'CardBuilder',
  components: {
    CharacterForm,
    BackgroundForm,
    ClassSkillForm,
    OverviewForm
  },
  data: () => ({
    class_list: Array,
    skills: Array,
    skills_by_class: Array,
    background_list: Array,
    race_list: Array,
    religion_list: Array,
    alignment_by_class: Array,
    skillMutateTracker: 0,
    mobileLandscape: Boolean,
    classes_loaded: false,
    skills_loaded: false,
    skills_by_class_loaded: false,
    alignments_by_class_loaded: false,
    backgrounds_loaded: false,
    religions_loaded: false,
    races_loaded: false,
  }),

  watch: {
  },
  computed: {
    loggedIn: {
      get() {
        return JSON.parse(this.$store.state.isLoggedIn)
      }
    },
  },
  mounted: function(){
    this.clearCharacterStore();
    this.fetchFormData();
    this.mobileLandscape =  (window.screen.orientation.type === "landscape-primary" ? true : false)
    window.addEventListener(
      "orientationchange",
      this.handleOrientationChange
    );
  },
  methods: {

    handleOrientationChange() {
      const orientation = window.screen.orientation.type
      if (orientation === "portrait-primary") {
        this.mobileLandscape = false
      } else if (orientation === "landscape-primary") {
        this.mobileLandscape = true
      }
    },  

    skill_mutate(){
      this.skillMutateTracker++
    },

    fetchFormData(){
      this.fetchClassesData()
      this.fetchSkillsData()
      this.fetchSkillsByClassData()
      this.fetchAlignmentsByClassData()
      this.fetchBackgrounds()
      this.fetchReligions()
      this.fetchRaces()
      this.updatePlayerProfile()
    },
    async fetchClassesData(){
      var response = await cardService.getClasses(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.class_list = response.data.sort(function (a,b){return a.name<b.name ? -1 : 1});
        this.classes_loaded = true;
      }
    },
    async fetchSkillsData(){
      var response = await cardService.getSkills(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.skills = response.data;
        this.skills.filter(a=> [1, 11].includes(a.ability_type)).forEach(s => s.name = s.name.match(/\(([^)]+)\)/)[1])
        this.skills_loaded = true;
      }
    },
    async fetchSkillsByClassData(){
      var response = await cardService.getSkillsByClass(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.skills_by_class = response.data;
        this.skills_by_class_loaded = true;
      }
    },
    async fetchAlignmentsByClassData(){
      var response = await cardService.getAlignmentByClass(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.alignment_by_class = response.data.filter(a=> !a.is_hidden).sort(function (a,b){return a.entity<b.entity ? -1 : 1});
        this.alignments_by_class_loaded  = true;
      }
    },
    async fetchBackgrounds(){
      var response = await cardService.getBackgrounds(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.background_list = response.data.sort(function (a,b){return a.name<b.name ? -1 : 1});
        this.backgrounds_loaded = true;
      }
    },
    async fetchRaces(){
      var response = await cardService.getRaces(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.race_list = response.data.map(r=>r["name"]).sort();
        this.races_loaded = true;
      }
    },
    async fetchReligions(){
      var response = await cardService.getReligions(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.religion_list = response.data.map(r=>r["name"]).sort();
        this.religions_loaded = true;
      }
    },

    async updatePlayerProfile(){
      this.$store.dispatch('getUserFromToken', this.$store.state.token)
                .then((user) => {
                  if(user.is_verified){
                    this.$store.commit('updateSessionUserPlayerProfile', user.player)
                  }
                })
    },
    clearCharacterStore(){
      this.$store.dispatch('clearCharacter')
    }
  }
}

</script>