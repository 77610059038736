var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('v-layout',{staticClass:"pl-6 ml-2 mb-2 pb-2",attrs:{"row":"","no-gutters":""}},[_c('div',{staticClass:"invert--text friendly-text"},[_c('h1',[_vm._v("Role Delegation")]),_c('h4',[_vm._v("Please select roles to delegate to individuals.")])])]),_c('v-card',{staticClass:"rounded-xl pa-6 mx-6 mb-6",attrs:{"color":"primary","height":"100%"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"tertiary elevation-2",attrs:{"headers":_vm.headers,"items":this.UsersList,"items-per-page":10,"search":_vm.search,"no-data-text":"No Players"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"d-flex flex-column flex-wrap"},[_c('v-text-field',{attrs:{"outlined":"","color":"contrast","prepend-inner-icon":"mdi-magnify","label":"Search","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"d-flex flex-row flex-wrap justify-space-between"})],1)]},proxy:true},{key:"item.player.is_marshal",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":"","color":"contrast"},model:{value:(item.player.is_marshal),callback:function ($$v) {_vm.$set(item.player, "is_marshal", $$v)},expression:"item.player.is_marshal"}})]}},{key:"item.player.is_staff",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":"","color":"contrast"},model:{value:(item.player.is_staff),callback:function ($$v) {_vm.$set(item.player, "is_staff", $$v)},expression:"item.player.is_staff"}})]}},{key:"item.player.is_director",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":"","color":"contrast"},model:{value:(item.player.is_director),callback:function ($$v) {_vm.$set(item.player, "is_director", $$v)},expression:"item.player.is_director"}})]}},{key:"item.buttons",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }