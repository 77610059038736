<template>
  <v-layout column>
    <v-layout row no-gutters class="pl-6 ml-2 mb-2 pb-2">
      <div class="invert--text friendly-text">
        <h1>Character Card Update</h1>
        <h4>Search for Characters to Update in between games.</h4>
      </div>
    </v-layout>
    <v-card 
      color="primary" 
      height="100%"
      class="rounded-xl pa-6 mx-6 mb-6">
        <v-container fluid>
            <v-row>
              <v-col 
                cols="12">
                <v-data-table
                  :headers="headers"
                  :items="this.CharactersList"
                  :items-per-page="10"
                  disable-sort
                  class="tertiary elevation-2"
                  no-data-text="No Characters Exists with the given Parameters"
                >
                <template v-slot:top>
                  <v-container class="d-flex flex-column flex-wrap">
                  <div class="d-flex flex-row flex-wrap justify-space-between">
                    <v-select
                    class="flex-grow-0 flex-shrink-1"
                    :items="attributeList"
                    v-model="attribute"
                    label="Search By"
                    color="contrast"
                    item-color="contrast"
                    >
                  </v-select>
                  </div>
                  <v-text-field
                  class="flex-grow-0 flex-shrink-0"
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  label="Enter search here"
                  color="contrast"
                  clearable
                  v-on:keyup.enter="getCharacters()"
                  ></v-text-field>
                   <v-btn
                    class="flex-grow-0 flex-shrink-0"
                    color="primary"
                    @click="getCharacters()"
                    >Search
                  </v-btn>
                  </v-container>
                </template>
                <template v-slot:item.is_approved="{ item }">
                  <v-simple-checkbox 
                    :value="item.is_approved" 
                    disabled>
                    </v-simple-checkbox>
                </template>
                <template v-slot:item.is_retired="{ item }">
                  <v-simple-checkbox 
                    :value="item.is_retired" 
                    disabled>
                    </v-simple-checkbox>
                </template>
                <template v-slot:item.buttons="{ item }">
                  <v-btn
                    class="flex-grow-0 flex-shrink-0"
                    color="secondary"
                    @click="updateCharacter(item.cardnumber)"
                    >Update</v-btn>
                    <v-btn
                    color="accent"
                    @click="viewCharacter(item.cardnumber)"
                    >View</v-btn>
                </template>
                </v-data-table>
                </v-col>
            </v-row>
      </v-container>
    </v-card>
  </v-layout>
</template>

<script>
  import userService from '@/services/userService';
  import staffService from '@/services/staffService';

  export default {
    name: 'CharacterUpdate',
    data: () => ({
        CharactersList: [],
        search: '',
        attribute: "Card Number Prefix",
        attributeList: ["Card Number", "Card Number Prefix", "Character Name", "Email", "Player Name"],
        headers: [
        {
          text: 'Character Card Number',
          align: 'start',
          value: 'cardnumber',
        },
        { text: 'Name', value: 'name', filterable:false},
        { text: 'Email', value: 'player.email', filterable:false },
        { text: 'Player', value: 'player.name', filterable:false },
        { text: 'Approved?', value:'is_approved', filterable: false},
        { text: 'Retired?', value:'is_retired', filterable: false},
        { text: '', value: 'buttons', filterable:false }
        ],

    }),
    methods: {
        async refreshUser() {
          const user = JSON.parse(this.$store.state.authenticatedUser)

          if(!user.id) {
            this.$router.replace('logout')
          }

          const response = await userService.fetchUser(user.id, this.$store.state.token)
          if(response.status != 200) {
            this.$router.replace('logout')
          } else {
            const refreshedUser = response.data
            this.$store.commit('updateSessionUser', refreshedUser)
          }
        },
        async getCharacters() {
          const data = {
            "search": this.search,
            "attribute": this.attribute
          }
          var response = await staffService.getCheckInCharacters(data);
          if(response.status != 200) {
              console.log("Failed Call");
          }
          else{
            this.CharactersList = response.data.sort(function(a,b){
              var keyA = a.cardnumber.slice(-1)
              var keyB = b.cardnumber.slice(-1)
              if(keyA > keyB) return 1
              else if(keyB > keyA) return -1
              else return 0
            });
          }
        },

        updateCharacter(cardNumber){
          let routeData = this.$router.resolve({name: 'UpdateCharacterCards', params: {cardNumber: cardNumber}});
          window.open(routeData.href, '_blank');
        },

        viewCharacter(cardNumber){
          let routeData = this.$router.resolve({name: 'CharacterView', params: {cardNumber: cardNumber}});
          window.open(routeData.href, '_blank');
        },

    },
    computed: {
    },
    mounted: function(){
        this.refreshUser()
    },
    watch: {

    }
  }
</script>