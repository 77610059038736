import api from '@/services/api'
import store from '@/store'

export default {
    async getUnapprovedCharacters(){
      try{
          const token = store.state.token
          var baseURL
          baseURL = `/approvals_characters/`
          return await api.get(baseURL, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
          if (e.response.status == 401) {
              // eslint-disable-next-line 
              console.error("Invalid token")
              // eslint-disable-next-line 
              console.log("Token:" + token);
              store.dispatch('refreshToken')
                .then(() => {
                  const token = store.state.token
                  this.getUnapprovedCharacters(token)
                })
            }
          // eslint-disable-next-line no-console
          console.error(e);
          return e.response
      }
    },

    async getPendingCharacters(selectedEvent){
      try{
          const token = store.state.token
          var baseURL
          baseURL = `/pending_characters/${selectedEvent}`
          return await api.get(baseURL, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
          if (e.response.status == 401) {
              // eslint-disable-next-line 
              console.error("Invalid token")
              // eslint-disable-next-line 
              console.log("Token:" + token);
              store.dispatch('refreshToken')
                .then(() => {
                  const token = store.state.token
                  this.getPendingCharacters(selectedEvent, token)
                })
            }
          // eslint-disable-next-line no-console
          console.error(e);
          return e.response
      }
    },

    async approveCharacter(cardNumber){
      try{
          const token = store.state.token
          var baseURL
          baseURL = `/approvals_characters/${cardNumber}/`
          return await api.patch(baseURL,{}, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
          if (e.response.status == 401) {
              // eslint-disable-next-line 
              console.error("Invalid token")
              // eslint-disable-next-line 
              console.log("Token:" + token);
              store.dispatch('refreshToken')
                .then(() => {
                  const token = store.state.token
                  this.approveCharacter(token)
                })
            }
          // eslint-disable-next-line no-console
          console.error(e);
          return e.response
      }
    },

    async getCheckInCharacters(searchData){
      try{
          const token = store.state.token
          var baseURL
          baseURL = `/check_in_lookup/`
          return await api.post(baseURL, searchData, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
          if (e.response.status == 401) {
              // eslint-disable-next-line 
              console.error("Invalid token")
              // eslint-disable-next-line 
              console.log("Token:" + token);
              store.dispatch('refreshToken')
                .then(() => {
                  const token = store.state.token
                  this.getCheckInCharacters(searchData, token)
                })
            }
          // eslint-disable-next-line no-console
          console.error(e);
          return e.response
      }
    },

    async submitCharacterCheckIn(checkInData){
      try{
          const token = store.state.token
          var baseURL
          baseURL = `/check_in/`
          return await api.post(baseURL, checkInData, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
          if (e.response.status == 401) {
              // eslint-disable-next-line 
              console.error("Invalid token")
              // eslint-disable-next-line 
              console.log("Token:" + token);
              store.dispatch('refreshToken')
                .then(() => {
                  const token = store.state.token
                  this.submitCharacterCheckIn(checkInData, token)
                })
            }
          // eslint-disable-next-line no-console
          console.error(e);
          return e.response
      }
    },

    async getEventsList(){
      try{
          const token = store.state.token
          var baseURL
          baseURL = `/events/`
          return await api.get(baseURL, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
          if (e.response.status == 401) {
              // eslint-disable-next-line 
              console.error("Invalid token")
              // eslint-disable-next-line 
              console.log("Token:" + token);
              store.dispatch('refreshToken')
                .then(() => {
                  const token = store.state.token
                  this.getEventsList(token)
                })
            }
          // eslint-disable-next-line no-console
          console.error(e);
          return e.response
      }
    },

    async createNewEvent(eventData){
      try{
          const token = store.state.token
          var baseURL
          baseURL = `/events/`
          return await api.post(baseURL, eventData, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
          if (e.response.status == 401) {
              // eslint-disable-next-line 
              console.error("Invalid token")
              // eslint-disable-next-line 
              console.log("Token:" + token);
              store.dispatch('refreshToken')
                .then(() => {
                  const token = store.state.token
                  this.createNewEvent(eventData, token)
                })
            }
          // eslint-disable-next-line no-console
          console.error(e);
          return e.response
      }
    },

    async updateExistingEvent(eventData, eventID){
      try{
          const token = store.state.token
          var baseURL
          baseURL = `/events/${eventID}/`
          return await api.patch(baseURL, eventData, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
          if (e.response.status == 401) {
              // eslint-disable-next-line 
              console.error("Invalid token")
              // eslint-disable-next-line 
              console.log("Token:" + token);
              store.dispatch('refreshToken')
                .then(() => {
                  const token = store.state.token
                  this.createNewEvent(eventData, token)
                })
            }
          // eslint-disable-next-line no-console
          console.error(e);
          return e.response
      }
    },

    async printCard(cardNumber){
      try{
          const token = store.state.token
          var baseURL
          baseURL = `/staff/print_card/${cardNumber}/`
          return await api.get(baseURL, {
            responseType: "arraybuffer", 
            headers:{
              "Authorization": `Bearer ${token}`,
            }
          
          })
      }catch (e) {
          if (e.response.status == 401) {
              // eslint-disable-next-line 
              console.error("Invalid token")
              // eslint-disable-next-line 
              store.dispatch('refreshToken')
                .then(() => {
                  const token = store.state.token
                  this.printCard(cardNumber, token)
                })
            }
          // eslint-disable-next-line no-console
          console.error(e);
          return e.response
      }
    },

    async updateCharacter(data){
      try{
          //construct the Patch Data Here
          const character = store.state.character
          const editData = {
            character:{
            id: data.character,
            "past_event_count": data.past_event_count,
            "last_updated_date": new Date().toISOString(),
            "name": character.name,
            "vanity_title": character.vanity_title,
            "race": character.race,
            "background_1": character.background[0],
            "background_2": character.background[1],
            "literacy": character.literacy,
            "build_spent": character.buildSpent, 
            "religion": character.religion != null? character.religion: "",
            "is_light": data.alignments.light,
            "is_dark": data.alignments.dark,
            "is_order": data.alignments.order,
            "is_chaos": data.alignments.chaos,
            "is_retired": character.is_retired
          },
          player: data.player,
          event: data.event,
          classes: data.classes,
          abilities: data.skills,
          master_selections: data.selectedMasterSkills,
          weapon_proficiencies: data.selectedWeaponProf,
          eventInfo: data.eventInfo,
          increment_event: data.increment_event
        }
  
          const token = store.state.token
          var baseURL
          baseURL = `/staff/update_character/${data.cardNumber}/`
          return await api.patch(baseURL, editData, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
          if (e.response.status == 401) {
              // eslint-disable-next-line 
              console.error("Invalid token")
              // eslint-disable-next-line 
              console.log("Token:" + token);
              store.dispatch('refreshToken')
                .then(() => {
                  const token = store.state.token
                  this.updateCharacter(data, token)
                })
            }
          // eslint-disable-next-line no-console
          console.error(e);
          return e.response
      }
    },

    async getUsers(){
      try{
        const token = store.state.token
        var baseURL
        baseURL = `/users/`
        return await api.get(baseURL, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
          if (e.response.status == 401) {
              // eslint-disable-next-line 
              console.error("Invalid token")
              // eslint-disable-next-line 
              console.log("Token:" + token);
              store.dispatch('refreshToken')
                .then(() => {
                  const token = store.state.token
                  this.getUsers(token)
                })
            }
          // eslint-disable-next-line no-console
          console.error(e);
          return e.response
      }
    },

    async permissionUpdate(data, player){
      try{
          //construct the Patch Data Here
          let data = {
            operation: data
          }
  
          const token = store.state.token
          var baseURL
          baseURL = `/staff/player_update/${player}/`
          return await api.patch(baseURL, data, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
          if (e.response.status == 401) {
              // eslint-disable-next-line 
              console.error("Invalid token")
              // eslint-disable-next-line 
              console.log("Token:" + token);
              store.dispatch('refreshToken')
                .then(() => {
                  const token = store.state.token
                  this.permissionUpdate(data, token)
                })
            }
          // eslint-disable-next-line no-console
          console.error(e);
          return e.response
      }
    },

  }