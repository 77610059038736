import api from "@/services/api";
import router from '@/router'

export default function setup() {

//Interceptors
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.common["Authorization"] = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
         
         //do something
          break;
        case 401:
          //alert("session expired");
  
          router.replace({
            path: "/logout",
            query: { redirect: router.currentRoute.fullPath }
          });
          break;
        case 403:
          router.replace({
            path: "/logout",
            query: { redirect: router.currentRoute.fullPath }
          });
           break;
        /*case 404:
          //alert('page not exist');
          router.replace({
            path: "/logout",
            query: { redirect: router.currentRoute.fullPath }
          });
          break;*/
        case 502:
         setTimeout(() => {
            router.replace({
              path: "/logout",
              query: {
                redirect: router.currentRoute.fullPath
              }
            });
          }, 1000);
      }
      return Promise.resolve(error.response);
    }
  }
);

}
