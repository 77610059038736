<template>
  <v-layout column>
    <v-layout row no-gutters class="pl-6 ml-2 mb-2 pb-2">
      <div class="invert--text friendly-text">
        <h1>Manage Account</h1>
        <h4>Manage your account information.</h4>
      </div>
    </v-layout>

    <v-card color="primary" 
    class="rounded-xl pa-6 mx-6 mb-6">
      <v-container>
        <v-card-title>Account Information</v-card-title>
        <v-flex class="ma-4">
        <p><strong>Name: </strong>{{ user.name }}</p>
        <p><strong>Email: </strong>{{ user.email }}</p>
        <p><strong>Card Number Prefix: </strong>{{prefix}}</p>

        </v-flex>
        <v-form 
          class="pa-4"
        >

          <v-text-field 
            label="Change your name"
            outlined
            color="contrast"
            v-model="newName"
          >
          </v-text-field>
          <v-btn 
            @click="updateName()"
            outlined
            color="contrast"
            :disabled="!newName != ''"
          >
            Change Name
          </v-btn>
        </v-form>

        <v-form 
          class="pa-4"
          v-model="emailValid"
        >
          <v-text-field 
            label="New Email" 
            outlined
            color="contrast"
            v-model="newEmail"
            :rules="emailRules"
          >
          </v-text-field>

          <v-text-field 
            label="Confirm Email"
            outlined
            color="contrast"
            :rules="emailRules"  
            v-model="confirmedEmail"
            :error-messages="emailErrors()"
          >
          </v-text-field>
          <v-btn 
            @click="updateEmail()"
            outlined
            color="contrast"
            :disabled="!emailValid"
          >
            Change Email
          </v-btn>
        </v-form>

        <v-form 
          class="pa-4"
        >
          <v-text-field
            label="Current Medical Notes"
            v-model="user.player.medical_notes"
            disabled>
          </v-text-field>
          <v-text-field 
            label="Change your medical notes"
            outlined
            color="contrast"
            v-model="newMedicalNotes"
          >
          </v-text-field>
          <v-btn 
            @click="updateMedicalNotes()"
            outlined
            color="contrast"
            :disabled="!newMedicalNotes != ''"
          >
            Change Medical Notes
          </v-btn>
        </v-form>
      </v-container>

      <v-container>
        <v-card-title>Password</v-card-title>
        <v-form 
          class="pa-4"
          v-model="passwordValid"
        >

          <v-text-field 
            type="password" 
            label="New Password"
            v-model="newPassword"
            outlined
            color="contrast"
            :rules="[passwordRules.min, passwordRules.allNum, passwordRules.allLetters, sameAsEmail(newPassword)]"
          >
          </v-text-field>

          <v-text-field 
            type="password" 
            label="Confirm password"
            v-model="confirmedPassword"
            outlined
            color="contrast"
            :rules="[passwordRules.min, passwordRules.allNum, passwordRules.allLetters, sameAsEmail(confirmedPassword)]"
            :error-messages="passwordErrors()"
          >
          </v-text-field>
          <v-btn
            @click="updatePassword()"
            :disabled="!passwordValid"
            outlined
            color="contrast"
          >
            Change Password
          </v-btn>
        </v-form>
      </v-container>

    </v-card>
  </v-layout>
</template>

<script>
  import userService from '@/services/userService';
  import playerService from '@/services/playerService';

  export default {
    name: 'Account',
    data() {
      return {
        newName: '',
        newEmail: '',
        confirmedEmail: '',
        newPassword: '',
        confirmedPassword: '',
        newMedicalNotes: '',
        emailValid: false,
        passwordValid: false,
        nameValid: false,
        passwordRules: {
          min: v => v.length >= 8 || 'Password must be 8 or more characters.',
          allNum: v => !(/^\d+$/.test(v)) || 'Password cannot be only numbers',
          allLetters: v => !(/^[A-z]+$/.test(v)) || 'Password cannot be only letters'
        },
        emailRules: [
          v => !!v || 'Email is required.',
          v => /.+@.+\..+/.test(v) || 'Please use a valid email.',
        ],
      }
    },
    mounted() {
      this.refreshUser()
    },
    methods: {

      clearFields (){
        this.newName = ''
        this.newEmail = ''
        this.confirmedEmail = ''
        this.newPassword = ''
        this.confirmedPassword = ''
        this.newMedicalNotes = ''
      },

      sameAsEmail(v) {
        return (this.user.email != v) ? '' : 'Password must be different than your email address.'
      },
    
    async updateEmail() {
      const userId = this.user.id
      const token = this.token // eslint-disable-line no-unused-vars
      const data = {
        "email": this.newEmail
      }

      await userService.updateUser(userId, data)
        .then((response) => {
          if(response.status == 204) {
            this.$notify({
              group: 'authentication',
              title: 'Email updated!',
              text: `You have updated your email to ${this.newEmail}.`,
              type: 'success'
            })
            this.refreshUser()
            this.clearFields()
          } else {
              if(response.status == 409){
                this.$notify({
                  group: 'authentication',
                  title: 'Failed to update email.',
                  text: `It appears that this email address is already in use.  Please use a different email address.`,
                  type: 'error'
              })}
              else{
                this.$notify({
                  group: 'authentication',
                  title: 'Failed to update email.',
                  text: `An error occurred while attempting to update email. Please contact support.`,
                  type: 'error'
              })}
          }
        })
    },
    async updatePassword() {
      const userId = this.user.id
      const token = this.token // eslint-disable-line no-unused-vars
      const data = {
        "password": this.newPassword
      }

      await userService.updateUser(userId, data)
        .then((response) => {
            if(response.status == 204) {
              this.$notify({
                group: 'authentication',
                title: 'Password updated!',
                text: `You have successfully changed your password.`,
                type: 'success'
              })
              this.refreshUser()
              this.clearFields()
            } else {
              this.$notify({
                group: 'authentication',
                title: 'Failed to update password.',
                text: `An error occurred while attempting to update your password. Please contact support.`,
                type: 'error'
              })
            }
        })
    },
    async updateName() {
      const userId = this.user.id
      const token = this.token // eslint-disable-line no-unused-vars
      const data = {
        "name": this.newName
      }

      await userService.updateUser(userId, data)
        .then((response) => {
            if(response.status == 204) {
              this.$notify({
                group: 'authentication',
                title: 'Your name has been updated!',
                text: `You have successfully changed your name.`,
                type: 'success'
              })
              this.refreshUser()
                this.clearFields()
            } else {
              this.$notify({
                group: 'authentication',
                title: 'Failed to update name.',
                text: `An error occurred while attempting to update your name. Please contact support.`,
                type: 'error'
              })
            }
        })
    },
    async updateMedicalNotes() {
      const playerId = this.user.player.id
      const token = this.token // eslint-disable-line no-unused-vars
      const data = {
        "medical_notes": this.newMedicalNotes
      }

      await playerService.updatePlayer(playerId, data)
        .then((response) => {
            if(response.status == 204) {
              this.$notify({
                group: 'authentication',
                title: 'Your medical notes have been updated!',
                text: `You have successfully changed your medical notes.`,
                type: 'success'
              })
              this.refreshUser()
                this.clearFields()
            } else {
              this.$notify({
                group: 'authentication',
                title: 'Failed to update medical notes.',
                text: `An error occurred while attempting to update your medical notes. Please contact support.`,
                type: 'error'
              })
            }
        })
    },
    async refreshUser() {
      const user = JSON.parse(this.$store.state.authenticatedUser)
      if(!user.id) {
        this.$router.replace('logout')
      }

      const response = await userService.fetchUser(user.id, this.$store.state.token)
      if(response.status != 200) {
        this.$router.replace('logout')
      } else {
        const refreshedUser = response.data
        this.$store.commit('updateSessionUser', refreshedUser)
        this.newMedicalNotes = refreshedUser.medical_notes
      }
    },
    emailErrors() {
      return (this.newEmail === this.confirmedEmail) ? '' : 'Emails must match.'
    },
    passwordErrors() {
      return (this.newPassword === this.confirmedPassword) ? '' : 'Passwords must match.'
    }
    },
    computed: {
      user: {
        get() {
          return JSON.parse(this.$store.state.authenticatedUser)
        }
      },
      token: {
        get() {
          return this.$store.state.token
        }
      },
      prefix: {
        get() {
          return JSON.parse(this.$store.state.userPlayerProfile).cardnumber_prefix
        }
      }
    },
    watch: {
      confirmedEmail: function() {
        if(this.confirmedEmail !== this.newEmail) {
          this.emailValid = false
        } else {
          this.emailValid = true
        }
      },
      confirmedPassword: function() {
        let min = v => v.length >= 8
        let allNum= v => !(/^\d+$/.test(v))
        let allLetters= v => !(/^[A-z]+$/.test(v))
        let sameAsEmail= v => this.user.email != v

        if(this.confirmedPassword !== this.newPassword) {
          this.passwordValid = false
        } 
        else if(!min(this.confirmedPassword) || !min(this.newPassword)){
          this.passwordValid = false
        }
        else if(!allNum(this.confirmedPassword) || !allNum(this.newPassword)){
          this.passwordValid = false
        }
        else if(!allLetters(this.confirmedPassword) || !allLetters(this.newPassword)){
          this.passwordValid = false
        }
        else if(!sameAsEmail(this.confirmedPassword) || !sameAsEmail(this.newPassword)){
          this.passwordValid = false
        }
        else {
          this.passwordValid = true
        }
      }
    }
  }
</script>