<template>
  <v-card
    color="primary"
    class="darken-1"
    tile
  >
  <v-container>
    <h1 class="display-2 ma-4 pa-2 text-center">Register</h1>

    <v-form 
      ref="registrationForm" 
      v-model="valid" 
      lazy-validation
      class="pa-4"
    >
      <v-row align="center">
        <v-col cols="12">
          <v-text-field 
            v-model="name" 
            label="Your name" 
            required
            outlined
            color="contrast"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="6">
          <v-text-field 
            v-model="email" 
            :rules=emailRules
            :error-messages="emailErrors()"
            label="Email" 
            required 
            outlined
            color="contrast"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field 
            v-model="confirmedEmail" 
            :rules=emailRules 
            label="Confirm Email" 
            :error-messages="emailErrors()"
            required 
            outlined
            color="contrast"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field 
            v-model="password" 
            label="Password" 
            type="password" 
            :rules="[passwordRules.min, passwordRules.allNum, passwordRules.allLetters, sameAsEmail(password)]"
            required 
            :error-messages="passwordErrors()"
            outlined
            color="contrast"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field 
            v-model="confirmedPassword" 
            label="Confirm Password" 
            type="password" 
            :rules="[passwordRules.min, passwordRules.allNum, passwordRules.allLetters, sameAsEmail(confirmedPassword)]"
            :error-messages="passwordErrors()"
            required 
            outlined
            color="contrast"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-checkbox
        v-model="agreement"
        >
          <span slot="label">I agree to LARPHive's <a class="accent--text" target="_blank" @click.stop href='/terms-of-service'>Terms of Service</a> and <a  class="accent--text" target="_blank" @click.stop href='/privacy-policy'>Privacy Policy</a>.</span>
        </v-checkbox>
        <vue-recaptcha 
          ref="invisibleRecaptcha"
          sitekey="6LfHk8gbAAAAABabtN3PLTUvVV8WUHn9ipRVswGE" 
          :loadRecaptchaScript="true"
          @verify="onVerify"
          @expired="onExpired"
          size="invisible"
          >
        </vue-recaptcha>
      <v-btn 
        outlined 
        @click="submit()" 
        :disabled="!valid"
      >Sign Up</v-btn>
    </v-form>

    </v-container>
  </v-card>
</template>

<script>
import userService from '@/services/userService';
import VueRecaptcha from 'vue-recaptcha';
import reCaptchaService from '@/services/recaptchaService';



export default {
  components: {VueRecaptcha},
  data: () => ({
    agreement: false,
    name: '',
    nameRules: [
      v => !!v || 'Name is required.',
    ],
    password: '',
    passwordRules: {
      min: v => v.length >= 8 || 'Password must be 8 or more characters.',
      allNum: v => !(/^\d+$/.test(v)) || 'Password cannot be only numbers',
      allLetters: v => !(/^[A-z]+$/.test(v)) || 'Password cannot be only letters'
    },
    confirmedPassword: '',
    passwordValid: false,
    email: '',
    emailValid: false,
    confirmedEmail: '',
    emailRules: [
      v => !!v || 'Email is required.',
      v => /.+@.+\..+/.test(v) || 'Please use a valid email.',
    ],
  }),
  emits: {
    closeDialog: null
  },
  watch: {
    
    confirmedPassword: function (oldVal, newVal) // eslint-disable-line no-unused-vars 
    {
      if (this.confirmedPassword !== this.password) {
        // passwords do not match
        this.passwordValid = false
      } else {
        this.passwordValid = true
      }
    },
  
    confirmedEmail: function (oldVal, newVal) // eslint-disable-line no-unused-vars
    {
      if (this.confirmedEmail !== this.email) {
        this.emailValid = false
      } else {
        this.emailValid = true
      }
    },
  },
  computed: {
    valid:  {
      set(newVal) { // eslint-disable-line no-unused-vars
        return this.emailValid && this.passwordValid && this.agreement
      },
      get() {
        return this.emailValid && this.passwordValid && this.agreement
      }
    }
  },
  props: {
    next: {
      type: String,
      default: 'dashboard'
    }
  },
  methods: {
    emailErrors() {
      return (this.email === this.confirmedEmail) ? '' : 'Emails must match.'
    },
    passwordErrors() {
      return (this.password === this.confirmedPassword) ? '' : 'Passwords must match.'
    },

    sameAsEmail(v) {
      return (this.email != v) ? '' : 'Password must be different than your email address.'
    },
    
    
    async register() {
    let payload = {
      "name": this.name,
      "email": this.email,
      "password": this.password
    }

    const response = await userService.createUser(payload) // eslint-disable-line no-unused-vars
      .then((response) =>{
        switch(response.status) {
        case (500): 
          this.displayNotification('authentication', 'Cannot register your account at this time.', 'Please visit our Discord for help.', 'error')
          break;
        case (404): 
          this.displayNotification('authentication', 'Cannot register your account at this time.', 'Please visit our Discord for help.', 'error')
          break;
        case (400): 
          this.displayNotification('authentication', 'An account for ' + payload.email + ' already exists.', 'Please try a different email address.', 'error')
          break;  
        case(201):
          this.$emit("closeDialog")
          this.displayNotification('authentication', 'Registration successful!',`Welcome aboard! Please check your inbox for your verification email in order to log in.`, 'success')
          /*
          this.$store.dispatch('obtainToken', { email: this.email, password: this.password})
            .then((response) => {
              this.$store.dispatch('getUserFromToken', response.data.access)
                .then((user) => {
                  if (!user.id) {
                    this.displayNotification('authentication', 'Login failed', 'Please try refreshing the page and logging in again.', 'error')
                  } else {
                    this.$store.commit('updateSessionUser', user)
                      this.openDashboard();
                  }
                })    
              })*/
              break;
          default:
            this.displayNotification('authentication', 'Registration failed', response.data.detail, 'error')
            break;
          }
      })
    },
    submit: function () {
      this.$refs.invisibleRecaptcha.execute()
    },
    resetRecaptcha() {
      this.$refs.invisibleRecaptcha.reset() // Direct call reset method
    },
    onVerify: async function (response) {
      let data = {"response": response}
      let r = await reCaptchaService.checkReCaptcha(data);
      if(r.data.success==true){
        this.register()
        this.resetRecaptcha()
      }
      else{
        this.displayNotification('authentication', 'Login failed', 'Our system has flagged you as a robot.' + '\n' + 'If you believe this is an error, please refresh your page and try again.',  'error')
        this.$emit("closeDialog")
        this.resetRecaptcha()
      }
    },
    onExpired: function () {
      console.log('Expired')
    },
    openDashboard() {
      this.$router.replace(this.next)
    },
    displayNotification(group, title, text, type) {
      this.$notify({
                  group: group,
                  title: title,
                  text: text,
                  type: type
                })
    }
  }
}
</script>