<template>
    <v-layout column>
    <v-layout row  no-gutters class="pl-6 ml-2 mb-2 pb-2">
      <div class="invert--text friendly-text">
        <h1>Support</h1>
        <h4>Contact us below</h4>
      </div>
    </v-layout>
      <v-card
        height="100%"
        color="primary"
        class="rounded-xl pa-6 mx-6 mb-6">
        <p class="body-2">
          Support Page not yet completed.
        </p>
      </v-card>
    </v-layout> 
</template>

<script>

export default {
            
props: [],         
data()  {
    return {
    };
},   
mounted() {
    },

methods: {

    },
watch: {},
computed: {
    cols() {
      const {
        mdAndUp,
        mdAndDown
      } = this.$vuetify.breakpoint
      return mdAndUp ? 6 : mdAndDown ? 12 : 12
    },
    user: {
      get() {
        return JSON.parse(this.$store.state.authenticatedUser)
      }
    },
  },
};
    
</script>