<template>
  <v-card
    color="primary"
  >
  <v-container>

    <v-form 
      ref="BackgroundForm"
      class="pa-4"
    >
      <v-row align="center">
        <v-col cols="6">
          <v-row>
            <v-autocomplete
            v-model="background1"
            :items="shown_backgrounds1"
            label="Background Skill #1"
            item-value="id"
            :item-text="item => item.name +' - '+ item.skill.name"
            required
            :dark="$vuetify.theme.dark"
            outlined
            color="contrast"
            item-color="contrast"
            ></v-autocomplete>
          </v-row>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="6">
          <v-row>
            <v-autocomplete
            v-model="background2"
            :items="shown_backgrounds2"
            label="Background Skill #2"
            item-value="id"
            :item-text="item => item.name +' - '+ item.skill.name"
            required
            :dark="$vuetify.theme.dark"
            outlined
            color="contrast"
            item-color="contrast"
            ></v-autocomplete>
          </v-row>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="6">
          <v-row>
            <v-autocomplete
            v-model="cultural_literacy"
            :items="shown_literacies"
            label="Free Regional Literacy"
            item-value="abilities_id"
            item-text="name"
            required
            :dark="$vuetify.theme.dark"
            outlined
            color="contrast"
            item-color="contrast"
            ></v-autocomplete>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-textarea
        v-model="backstory"
        outlined
        color="contrast"
        shaped
        label="Character Backstory"
        counter= "10000"
        counter-value
        >
        </v-textarea>
      </v-row>
    </v-form>

    </v-container>
  </v-card>
</template>

<script>



export default {
  components: {},
  data: () => ({
    shown_backgrounds1: Array,
    shown_backgrounds2: Array,
    shown_literacies: Array,
  }),
  props: {
    background_list: Array,
    originalCharacter: Object,
    skills: Array,
    isNewCharacter: Boolean
  },
  watch: {
  },
  mounted: function(){
    if(!this.isNewCharacter){
      //Logic to handle whether or not this page is mounted before the overview page
        this.background1 = this.background1==null? this.originalCharacter.background_1.id: this.background1
        this.background2 = this.background2==null? this.originalCharacter.background_2.id: this.background2
        if(this.cultural_literacy==null){
          if(this.originalCharacter.literacy != null){
            this.cultural_literacy = this.originalCharacter.literacy.abilities_id
          }
        }
        this.backstory = this.originalCharacter.backstory
      }
    this.shown_backgrounds1 = this.background_list
    this.shown_backgrounds2 = this.background_list
    this.shown_literacies = this.skills.filter(a=> a.ability_type == 1)
  },
  computed: {
    background1: {
            get: function(){
                return this.$store.state.character.background[0];
            },
            set: function(value){
                this.$store.state.character.background.splice(0, 1, value)
                //this.$store.state.character.background[0] = value;
                this.shown_backgrounds2 = this.background_list.filter(b => b.id != value)
            }
        },
    background2: {
            get: function(){
                return this.$store.state.character.background[1];
            },
            set: function(value){
                this.$store.state.character.background.splice(1, 1, value)
                //this.$store.state.character.background[1] = value;
                this.shown_backgrounds1 = this.background_list.filter(b => b.id != value)
            }
        },
    cultural_literacy: {
            get: function(){
              return this.$store.state.character.literacy
            },
            set: function(value){
              this.$store.state.character.literacy = value
            }
      },
        backstory: {
          get: function(){
            return this.$store.state.character.backstory
          },
          set: function(value){
            this.$store.state.character.backstory = value
          }
        }
    
        
  },
  methods: {
  }
}
</script>