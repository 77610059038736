<template>
    <v-footer
      padless
    >
      <v-card
        width="100%"
        color="primary"
        class="rounded-0"
      >

        <v-divider></v-divider>

        <v-card-text class="contrast--text text-left friendly-text">
          <v-col cols="12">
              {{ new Date().getFullYear() }} — <strong> LARPHive </strong>
             <img class="float-right mt-n3" src="@/assets/powered_by_larphive.png" />
          </v-col>
        </v-card-text>
      </v-card>
    </v-footer>
</template>

<script>
  export default {
    data: () => ({
      icons: [
        'home',
        'mail',
        'event',
        'delete',
      ],
      items: [
        'default',
        'absolute',
        'fixed',
      ],
      padless: false,
      variant: 'default',
    }),
    computed: {
      localAttrs () {
        const attrs = {}

        if (this.variant === 'default') {
          attrs.absolute = false
          attrs.fixed = false
        } else {
          attrs[this.variant] = true
        }
        return attrs
      },
    },
  }
</script>