<template>
  <v-layout column>
    <v-layout row  no-gutters class="pl-6 ml-2 mb-2 pb-2">
      <div class="invert--text friendly-text">
        <h1>Calendar of Events</h1>
        <h4>Check when the next game will be</h4>
      </div>
    </v-layout>

    <v-card
      color="primary"  
      height="100%"
      class="rounded-xl pa-6 mx-6 mb-6">
      <v-container fluid>
        <vc-calendar
        class="tertiary"
        :rows="$vuetify.breakpoint.xl?'4':$vuetify.breakpoint.lg?'3':'2'"
        :is-dark="$vuetify.theme.dark"
        is-expanded
        color="primary"
        :attributes="eventsList"
        >
        </vc-calendar>
      </v-container>
    </v-card>
  </v-layout>
</template>

<script>
  import userService from '@/services/userService';
  import staffService from '@/services/staffService';

  export default {
    name: 'Calendar',
    data: () => ({
      eventsList: [],
      focus: '',
    }),
    computed: {
    },
    methods: {
        async refreshUser() {
          const user = JSON.parse(this.$store.state.authenticatedUser)

          if(!user.id) {
            this.$router.replace('logout')
          }

          const response = await userService.fetchUser(user.id, this.$store.state.token)
          if(response.status != 200) {
            this.$router.replace('logout')
          } else {
            const refreshedUser = response.data
            this.$store.commit('updateSessionUser', refreshedUser)
          }
        },

        async getEvents() {
          var response = await staffService.getEventsList();
          if(response.status != 200) {
              console.log("Failed Call");
          }
          else{
            this.eventsList = response.data.filter(e=> !["Before Times", "Character Creation"].includes(e.name)).map(
              function(e){
                return {
                  key: e.id,
                  highlight: this.getEventColor(e.event_type),
                  dates: {
                    start: new Date(e.date), 
                    end: e.end_date==null? new Date(e.date): new Date(e.end_date)
                    },
                  popover: {
                    label: e.name
                  }
                }
              } , this)
          }
        },

        getEventColor(eventType){
          switch(eventType){
            case 1:
              return "primary";
            case 2:
              return "secondary";
            default:
              return "green";
          }
        },

        getHeight(){
          if(this.$vuetify.breakpoint.lgAndUp){
            return 4
          }
          else{
            return 2
          }
        }



    },
    mounted: function(){
        this.refreshUser()
        this.getEvents()
        this.getHeight()
    }
  }
</script>

<style lang="scss">

</style>