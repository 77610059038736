<template>
<v-card
  color="primary"
  class="darken-1"
  tile
>
  <v-container>
    <h1 class="display-2 text-center ma-4 pa-2">Login</h1>
    <h3 class="subtitle text-center ma-4 pa-2">Welcome back. Login to manage your account.</h3>
    <v-form 
      ref="form" 
      v-model="valid" 
      lazy-validation
      class="pa-4"
    >
      <v-col cols="12" md="12">
        <v-row align="center">
          <v-text-field 
            v-model="email" 
            :rules=emailRules 
            label="Email" 
            required
            outlined
            color="contrast"
          ></v-text-field>
        </v-row>
        <v-row align="center">
          <v-text-field 
            v-model="password"
            label="Password"
            required
            type="password"
            outlined
            color="contrast"
            v-on:keyup.enter="submit"
          ></v-text-field>
        </v-row>
      </v-col>
      <vue-recaptcha 
      ref="invisibleRecaptcha"
      sitekey="6LfHk8gbAAAAABabtN3PLTUvVV8WUHn9ipRVswGE" 
      :loadRecaptchaScript="true"
      @verify="onVerify"
      @expired="onExpired"
      size="invisible"
      >
      </vue-recaptcha>
      <v-btn outlined @click="submit" :disabled="!valid || !email && !password">Login</v-btn>
    </v-form>
  </v-container>
  </v-card>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import reCaptchaService from '@/services/recaptchaService';


export default {
  components: { VueRecaptcha },
  data: () => ({
    valid: true,
    recaptcha: false,
    password: '',
    email: '',
    emailRules: [
      v => !!v || 'Email is required.',
      v => /.+@.+\..+/.test(v) || 'You have entered an invalid email.',
    ],
  }),
  props: {
    next: {
      type: String,
      default: 'dashboard'
    }
  },
  methods: {
    login() {
      this.$store.dispatch('obtainToken', { email: this.email, password: this.password })
      .then((response) => {
        switch(response.status) {
          case (500):
            this.displayNotification('authentication', 'Login failed', 'Something went wrong on our end.' + '\n' + 'Hang tight we are working on it.', 'error')
            break;
          case (404):
            this.displayNotification('authentication', 'Login failed', 'Something went wrong on our end.' + '\n' + 'Hang tight we are working on it.',  'error')
            break;  
          case (401):
            this.displayNotification('authentication', 'Login failed', 'Incorrect Username/Password.' + '\n' + 'Hang tight we are working on it.',  'error')
            break;  
          case (200):
            this.$store.dispatch('getUserFromToken', response.data.access)
                .then((user) => {
                  if(user.is_verified){
                    this.$store.commit('updateSessionUser', user)
                    this.$store.commit('updateSessionUserPlayerProfile', user.player)
                      this.displayNotification('authentication', 'Login successful', `Welcome back.`, 'success')
                      this.$emit("closeDialog")
                      this.openDashboard();
                  }
                  else{
                    this.displayNotification('authentication', 'Email Not Verified', `Please check your inbox for your verification email` + '\n' + 'and follow the instructions to activate your account.', 'error')
                  }
                })
              break;
          default:
            this.displayNotification('authentication', 'Login failed', response.data.detail, 'error')
            break;
            }
        })  
      },
    openDashboard() {
      this.$router.replace(this.next)
    },
    displayNotification(group, title, text, type) {
      this.$notify({
                  group: group,
                  title: title,
                  text: text,
                  type: type
                })
    },

    submit: function () {
      this.$refs.invisibleRecaptcha.execute()
    },
    resetRecaptcha() {
      this.$refs.invisibleRecaptcha.reset() // Direct call reset method
    },
    onVerify: async function (response) {
      let data = {"response": response}
      let r = await reCaptchaService.checkReCaptcha(data);
      if(r.data.success==true){
        this.login()
        this.resetRecaptcha()
      }
      else{
        this.displayNotification('authentication', 'Login failed', 'Our system has flagged you as a robot.' + '\n' + 'If you believe this is an error, please refresh your page and try again.',  'error')
        this.$emit("closeDialog")
        this.resetRecaptcha()
      }
    },
    onExpired: function () {
      console.log('Expired')
    },
  }
}
</script>