var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('v-layout',{staticClass:"pl-6 ml-2 mb-2 pb-2",attrs:{"row":"","no-gutters":""}},[_c('div',{staticClass:"invert--text friendly-text"},[_c('h1',[_vm._v("My Characters")]),_c('h4',[_vm._v("Manage my current characters.")])])]),_c('v-card',{staticClass:"rounded-xl px-6 pb-6 mx-6 mb-6",attrs:{"color":"primary","height":"100%"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"290px"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-card',{staticClass:"rounded-xl",attrs:{"color":"primary"}},[_c('v-card-title',[_vm._v("Downloading")]),_c('v-card-subtitle',[_c('br'),_vm._v("Your file is downloading. This may take a moment...")]),_c('v-card-text',{staticClass:"d-flex flex-row flex-nowrap justify-end"},[_c('v-btn',{attrs:{"color":"primary darken-2"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex pa-2 flex-row flex-wrap justify-flex-start",attrs:{"cols":"12"}},[_vm._l((this.MyCharactersList),function(character){return _c('v-card',{key:character,class:'pa-2 ma-4 ' +(_vm.$vuetify.breakpoint.smAndDown?'ml-0 ':'') + 'rounded-xl flex-grow-0 flex-shrink-0',attrs:{"width":_vm.$vuetify.breakpoint.smAndDown?'100%':_vm.$vuetify.breakpoint.mdOnly?'45%':'30%',"color":"tertiary","tile":""}},[_c('v-card-title',[_c('p',[_vm._v(_vm._s(character.cardnumber))]),_vm._v(" "),_c('v-spacer'),_c('p',[_vm._v(_vm._s(character.name))])],1),_c('v-card-subtitle',[_vm._v(_vm._s(character.race)),_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(character.build_spent)+"/"+_vm._s(character.build_total))])]),_c('v-card-text',[_c('v-row',[(!character.is_approved)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"px-2",attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-off")])]}}],null,true)},[_c('span',[_vm._v("Your character is pending approval by a Card Marshal.")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"px-2",attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-check")])]}}],null,true)},[_c('span',[_vm._v("Your character has been approved.")])]),(character.requires_director_approval  && !character.is_approved)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"px-2",attrs:{"color":"yellow"}},'v-icon',attrs,false),on),[_vm._v(" mdi-crown")])]}}],null,true)},[_c('span',[_vm._v("Your character requires Director level approval. Please be patient, there is only one James.")])]):_vm._e(),(character.is_retired)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"px-2",attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-fish")])]}}],null,true)},[_c('span',[_vm._v("This character is currently retired.")])]):_vm._e()],1),_c('v-row',{staticClass:"d-flex flex-row justify-end"},[_c('v-speed-dial',{attrs:{"bottom":true,"right":true,"left":false,"top":false,"direction":"left","open-on-hover":!_vm.$vuetify.breakpoint.mobile,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary darken-2","dark":_vm.$vuetify.theme.dark,"fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab[_vm.MyCharactersList.indexOf(character)])?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]},proxy:true}],null,true),model:{value:(_vm.fab[_vm.MyCharactersList.indexOf(character)]),callback:function ($$v) {_vm.$set(_vm.fab, _vm.MyCharactersList.indexOf(character), $$v)},expression:"fab[MyCharactersList.indexOf(character)]"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!character.is_approved),expression:"!character.is_approved"}],attrs:{"fab":"","dark":_vm.$vuetify.theme.dark,"small":"","color":"primary darken-2"},on:{"click":function($event){return _vm.editCharacter(character.cardnumber)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Click this button to edit your character card. Only avaiable before approval.")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":_vm.$vuetify.theme.dark,"small":"","color":"primary darken-2"},on:{"click":function($event){return _vm.downloadCharacter(character.cardnumber)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Click this button to download your character card as a Watermarked PDF.")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":_vm.$vuetify.theme.dark,"small":"","color":"primary darken-2"},on:{"click":function($event){return _vm.characterWizard(character.cardnumber)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pb-2"},[_vm._v("mdi-wizard-hat")])],1)]}}],null,true)},[_c('span',[_vm._v("Click this button to view your character card in the wizard. "),_c('br'),_vm._v("You cannot submit, but can tweak your card however you wish.")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":_vm.$vuetify.theme.dark,"small":"","color":"primary darken-2"},on:{"click":function($event){return _vm.viewCharacter(character.cardnumber)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Click this button to view your character.")])])],1)],1)],1)],1)}),(_vm.CharactersRemaining>0)?_c('v-card',{class:'pa-2 ma-4 ' +(_vm.$vuetify.breakpoint.smAndDown?'ml-0 ':'') + 'rounded-xl flex-grow-0 flex-shrink-0',attrs:{"width":_vm.$vuetify.breakpoint.smAndDown?'100%':_vm.$vuetify.breakpoint.mdOnly?'45%':'30%',"color":"tertiary","tile":""}},[_c('v-card-title',[_vm._v("Create New Character")]),_c('v-subheader',[_vm._v("You can make up to "+_vm._s(_vm.CharactersRemaining)+" more new characters.")]),_c('v-card-text',{staticClass:"d-flex flex-row justify-end"},[_c('v-speed-dial',{attrs:{"bottom":true,"right":true,"left":false,"top":false,"direction":"left","open-on-hover":!_vm.$vuetify.breakpoint.mobile,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary darken-2","dark":_vm.$vuetify.theme.dark,"fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fabNewCharacter)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-plus ")])],1)]},proxy:true}],null,false,3673023005),model:{value:(_vm.fabNewCharacter),callback:function ($$v) {_vm.fabNewCharacter=$$v},expression:"fabNewCharacter"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary darken-2",attrs:{"small":"","fab":"","dark":_vm.$vuetify.theme.dark,"disabled":_vm.CharactersRemaining<1},on:{"click":function($event){return _vm.makeNewCharacter()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-plus")])],1)]}}],null,false,1715136590)},[_c('span',[_vm._v("Create a brand new character.")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary darken-2",attrs:{"small":"","fab":"","dark":_vm.$vuetify.theme.dark,"disabled":_vm.CharactersRemaining<1},on:{"click":function($event){return _vm.makeExistingCharacter()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-star")])],1)]}}],null,false,3070173935)},[_c('span',[_vm._v("Create a custom character. Only use this when instructed to.")])])],1)],1)],1):_vm._e()],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }