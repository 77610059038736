<template>
<v-layout column>
  <v-container
    class="primary"
    darken-1
  >
    <h1 class="display-3">You have been securely logged out</h1>
    <h3 class="subtitle">Please wait while we redirect you to the LARPHive homepage.</h3>
  </v-container>

</v-layout>
</template>

<script>
export default {
  
}
</script>