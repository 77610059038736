<template>
  <v-app
    class="darken"
    :style="{ background: `url(${backgroundUrl}) rgba(0, 0, 0, ${opacity})`, backgroundRepeat: 'repeat', backgroundBlendMode: 'multiply' }"
  >
  <Navbar />
  <v-banner 
      color="primary"
      class="darken-1 friendly-text"
      single-line
      v-if="!banner"
    >

    Join our Discord!
      <template v-slot:actions>
        <v-btn
          text
          href="https://discord.gg/vNCfXkgBr5"
          target="_blank"
          @click="banner = !banner"
        >
          <font-awesome-icon :icon="['fab', 'discord']" />
          &nbsp;Join
        </v-btn>

        <v-btn
          icon
          @click="banner = !banner"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-banner> 
    <Registration />
  <v-layout>
      <GlobalNavigation />
      <router-view :key="$route.fullPath"/>
    </v-layout>
    <notifications group="authentication" position="bottom right" style="z-index: 99999;"/>
    <cookie-law 
      theme="dark-lime"
      message="LARPHive uses cookies to remember who you are &amp; offer the best possible experience. You can learn more about how we use cookies in our Privacy Policy."
    ></cookie-law>    
    
    <Footer />
  </v-app>
</template>

<script>

import Navbar from '@/components/home/Navbar';
import Footer from '@/components/home/Footer';
import GlobalNavigation from '@/components/home/GlobalNavigation';
import Registration from '@/components/registration/Registration';
import CookieLaw from 'vue-cookie-law';

export default {
 name: 'App',
  components: {
    Navbar,
    Footer,
    GlobalNavigation,
    Registration, 
    CookieLaw
  },
  data () {
    return {
    }
  },
  mounted(){
    this.$vuetify.theme.dark = !(JSON.parse(this.$store.state.darkMode) === 'light')

  },
  computed: {
    opacity(){
      return this.$vuetify.theme.dark ? 0 : 0.5;
    },
    backgroundUrl(){
      const steel = 'https://i.pinimg.com/originals/09/ea/b5/09eab523bc1a97acc375054953b13f8a.jpg'
      //const steel = 'https://i.pinimg.com/564x/d2/02/3d/d2023dd0140ba4597a7d0c0a5c77e0a6.jpg'
      const parchment = 'https://cdn.pixabay.com/photo/2015/12/03/08/50/paper-1074131_1280.jpg'
      return this.$vuetify.theme.dark ? parchment : steel;
    },
    banner: {
      get() {
        return this.$store.state.closedBanner
      },
      set(newVal) {
        return this.$store.commit('updateBanner', newVal)
      }
    }
  },
  watch:{
    $route (){
      if("logout" in this.$route.query){
        this.$router.replace("/")
        this.$notify({group: "authentication",
                  title: "Logged Out",
                  text: "You have been securely logged out.",
                  type: "success",
                  duration: 5
                  })
        this.$store.commit('updateDrawer', false)
      }
    }
} 
}
</script>

<style lang="scss">

  $border_radius_root: 4px;

  .v-text-field--outlined > .v-input__control > .v-input__slot {
    background: var(--v-tertiary-base)
  }
  .v-tabs{
    border-top-right-radius: $border_radius_root * 6;
    border-top-left-radius: $border_radius_root * 6;
  }

  .v-tabs-items{
    border-bottom-right-radius: $border_radius_root * 6;
    border-bottom-left-radius: $border_radius_root * 6;
  }

  
  .v-dialog{
    border-bottom-right-radius: $border_radius_root * 6;
    border-bottom-left-radius: $border_radius_root * 6;
    border-top-right-radius: $border_radius_root * 6;
    border-top-left-radius: $border_radius_root * 6;
  }
  
  .vc-container{
    border-radius: 0 !important;

    /* 
    * The following implementation is designed to allow the active $vuetify.theme
    * colors to work with the V-Calendar component from https://vcalendar.io/
    * following the CSS implementation from https://github.com/nathanreyes/v-calendar/blob/master/src/styles/base.css
    */

    --primary-100: var(--v-primary-lighten5);
    --primary-200: var(--v-primary-lighten4);
    --primary-300: var(--v-primary-lighten3);
    --primary-400: var(--v-primary-lighten2);
    --primary-500: var(--v-primary-lighten1);
    --primary-600: var(--v-primary-base);
    --primary-700: var(--v-primary-darken1);
    --primary-800: var(--v-primary-darken2);
    --primary-900: var(--v-primary-darken3);

    &.vc-primary {
    --accent-100: var(--primary-100);
    --accent-200: var(--primary-200);
    --accent-300: var(--primary-300);
    --accent-400: var(--primary-400);
    --accent-500: var(--primary-500);
    --accent-600: var(--primary-600);
    --accent-700: var(--primary-700);
    --accent-800: var(--primary-800);
    --accent-900: var(--primary-900);
    }

    --secondary-100: var(--v-secondary-lighten5);
    --secondary-200: var(--v-secondary-lighten4);
    --secondary-300: var(--v-secondary-lighten3);
    --secondary-400: var(--v-secondary-lighten2);
    --secondary-500: var(--v-secondary-lighten1);
    --secondary-600: var(--v-secondary-base);
    --secondary-700: var(--v-secondary-darken1);
    --secondary-800: var(--v-secondary-darken2);
    --secondary-900: var(--v-secondary-darken3);

    &.vc-secondary {
    --accent-100: var(--secondary-100);
    --accent-200: var(--secondary-200);
    --accent-300: var(--secondary-300);
    --accent-400: var(--secondary-400);
    --accent-500: var(--secondary-500);
    --accent-600: var(--secondary-600);
    --accent-700: var(--secondary-700);
    --accent-800: var(--secondary-800);
    --accent-900: var(--secondary-900);
    }
  }

  .Cookie {
    background: var(--v-tertiary-base) !important;
  }

  .Cookie .Cookie__button {
    background: var(--v-secondary-base) !important;
  }

  .v-window__container{
    background: var(--v-primary-base) !important;
  }
  @font-face{
            font-family: friendly;
            src: url("assets/ModernAntiqua-Regular.ttf") format("truetype");
            }
  .friendly-text {
    font-family: friendly !important;
  }
</style>