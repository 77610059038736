<template>
  <v-layout column>
    <v-layout row no-gutters class="pl-6 ml-2 mb-2 pb-2">
      <div class="invert--text friendly-text">
        <h1>Character Check In</h1>
        <h4>Search for Characters to Check In.</h4>
      </div>
    </v-layout>
    <v-card 
      color="primary" 
      height="100%"
      class="rounded-xl pa-6 mx-6 mb-6">
        <v-container fluid>
          <v-dialog
                    v-model="dialog"
                    width="500"
                  >
                      <v-overlay :value="waiting">
                        <v-progress-circular
                          indeterminate
                          size="128"
                        ></v-progress-circular>
                      </v-overlay>
                      <v-card>
                      <v-card-title class="invert--text friendly-text primary">
                        Character Check In for {{selectedCharacter.cardnumber}}
                      </v-card-title>

                      <v-card-text>
                        <p>
                          Do you need to print a card for this character?
                        </p>
                        <p
                          v-if="!selectedCharacter.is_approved"
                        >
                          Warning: This Character has not yet been approved.
                          <br/>
                          This character requires {{selectedCharacter.requires_director_approval ? "Director": "Regular"}} level approval.
                          <br/>
                          Are you sure you wish to proceed?
                        </p>
                        <p
                          v-show="selectedCharacter.is_retired"
                        >
                          Warning: This Character has been retired.
                          <br/>
                          Shouldn't you be out fishing off the Kormyrian coast?
                          <br/>
                          Are you sure you wish to proceed?
                        </p>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          color="primary"
                          text
                          @click="dialog = false"
                        >
                          Close
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="checkInCharacter()"
                          >
                          Check In
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="downloadCharacter()"
                        >
                          Print
                        </v-btn>
                      </v-card-actions>
                    </v-card>
          </v-dialog>

            <v-row>
              <v-col 
                cols="12">
                <v-data-table
                  :headers="headers"
                  :items="this.CharactersList"
                  :items-per-page="10"
                  disable-sort
                  class="tertiary elevation-2"
                  no-data-text="No Characters Exists with the given Parameters"
                >
                <template v-slot:top>
                  <v-container class="d-flex flex-column flex-wrap">
                  <div class="d-flex flex-row flex-wrap justify-space-between">
                    <v-select
                    class="flex-grow-0 flex-shrink-1"
                    :items="attributeList"
                    v-model="attribute"
                    label="Search By"
                    color="contrast"
                    item-color="contrast"
                    >
                  </v-select>
                  <v-autocomplete
                    class="flex-grow-0 flex-shrink-1"
                    :items="eventsList"
                    v-model="checkInEvent"
                    item-text="name"
                    item-value="id"
                    label="Event"
                    color="contrast"
                    item-color="contrast"
                    >
                  </v-autocomplete>
                  </div>
                  <v-text-field
                  class="flex-grow-0 flex-shrink-0"
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  label="Enter search here"
                  color="contrast"
                  clearable
                  v-on:keyup.enter="getCheckInCharacters()"
                  ></v-text-field>
                   <v-btn
                    class="flex-grow-0 flex-shrink-0"
                    color="primary"
                    @click="getCheckInCharacters()"
                    >Search
                  </v-btn>
                  </v-container>
                </template>
                <template v-slot:item.buttons="{ item }">
                  <v-btn
                    color="secondary"
                    @click="viewCharacter(item.cardnumber)"
                    >View</v-btn>
                      <v-btn
                      color="accent"
                      :disabled="checkInEvent==null"
                      @click="setCharacter(item)"
                      >Check In</v-btn>
                </template>
                </v-data-table>
                </v-col>
            </v-row>
      </v-container>
    </v-card>
  </v-layout>
</template>

<script>
  import userService from '@/services/userService';
  import staffService from '@/services/staffService';

  export default {
    name: 'CharacterApproval',
    data: () => ({
        CharactersList: [],
        search: '',
        attribute: "Card Number Prefix",
        attributeList: ["Card Number", "Card Number Prefix", "Character Name", "Email", "Player Name"],
        headers: [
        {
          text: 'Character Card Number',
          align: 'start',
          value: 'cardnumber',
        },
        { text: 'Name', value: 'name', filterable:false},
        { text: 'Email', value: 'player.email', filterable:false },
        { text: 'Player', value: 'player.name', filterable:false },
        { text: '', value: 'buttons', filterable:false }
        ],

        eventsList: [],
        checkInEvent: null,
        selectedCharacter: Object,
        dialog: false,
        waiting: false,

    }),
    methods: {
        async refreshUser() {
          const user = JSON.parse(this.$store.state.authenticatedUser)

          if(!user.id) {
            this.$router.replace('logout')
          }

          const response = await userService.fetchUser(user.id, this.$store.state.token)
          if(response.status != 200) {
            this.$router.replace('logout')
          } else {
            const refreshedUser = response.data
            this.$store.commit('updateSessionUser', refreshedUser)
          }
        },
        async getCheckInCharacters() {
          const data = {
            "search": this.search,
            "attribute": this.attribute
          }
          var response = await staffService.getCheckInCharacters(data);
          if(response.status != 200) {
              console.log("Failed Call");
          }
          else{
            this.CharactersList = response.data.sort(function(a,b){
              var keyA = a.cardnumber.slice(-1)
              var keyB = b.cardnumber.slice(-1)
              if(keyA > keyB) return 1
              else if(keyB > keyA) return -1
              else return 0
            });
            this.dialog = false
          }
        },

        async getEvents() {
          var response = await staffService.getEventsList();
          if(response.status != 200) {
              console.log("Failed Call");
          }
          else{
            this.eventsList = response.data.filter(e=> !["Before Times", "Character Creation"].includes(e.name))
            //let today = new Date()
            //this.checkInEvent = this.eventsList.find(e => new Date(e.date).getUTCMonth() == today.getUTCMonth() && new Date(e.date).getFullYear() == today.getFullYear()).id
          }
        },

        viewCharacter(cardNumber){
          let routeData = this.$router.resolve({name: 'CharacterView', params: {cardNumber: cardNumber}});
          window.open(routeData.href, '_blank');
        },

        async checkInCharacter(){
          let data = {
            character: this.selectedCharacter,
            event: this.checkInEvent
          }
          var response = await staffService.submitCharacterCheckIn(data);
          if(response.status != 200) {
              console.log("Failed Call");
              this.$notify({
                    group: 'authentication',
                    title: `Failed to check in character ${this.selectedCharacter.cardnumber}`,
                    text: `Please refresh your page and try again.  If this issues persists, contact support.`,
                    type: 'error'
                })
          }
          else{
            this.CharactersList = this.CharactersList.filter(c => c != this.selectedCharacter)
            this.$notify({
                    group: 'authentication',
                    title: `Approved`,
                    text: `Character ${this.selectedCharacter.cardnumber} has been successfully checked in`,
                    type: 'success'
                })
          }
        },

        setCharacter(character){
          this.selectedCharacter = character
          this.dialog = true
        },

        async downloadCharacter() {
            let cardNumber = this.selectedCharacter.cardnumber
            this.waiting = true
            await staffService.printCard(cardNumber)
                          .then(response => {
                            let blob = new Blob([response.data], {type: 'application/pdf'});
                            let link = document.createElement('a');
                            let objectURL = window.URL.createObjectURL(blob);
                            link.href = objectURL;
                            link.target = '_blank';
                            link.media = cardNumber+".pdf";
                            (document.body || document.documentElement).appendChild(link);
                            link.click();
                            setTimeout(()=>{
                                window.URL.revokeObjectURL(objectURL);
                                link.remove();
                              }, 100);
                            this.waiting = false
                            },this)
        },

    },
    computed: {
    },
    mounted: function(){
        this.refreshUser()
        this.getEvents()
    },
    watch: {

    }
  }
</script>