<template>
<v-layout>
  <v-layout column>
    <Account v-if="active == 'account'" />
    <Support v-if="active == 'support'" />
    <Account v-if="active === undefined" />
  </v-layout>
</v-layout>
</template>

<script>
import Account from '@/components/dashboard/Account';
import Support from '@/components/dashboard/Support';


export default {
  name: 'Dashboard',
  components: {
    Account,
    Support,
      
  },
  data() {
    return {
      active: 'account',
      mobile: false,
    }
  },
  mounted() {
    this.$eventHub.$on('changeDashboardComponent', data => {
      this.active = data
    })
    
    this.$eventHub.$on('sidebar', data => { // eslint-disable-line no-unused-vars
      this.drawer = !this.drawer
    })
  },

  methods: {
  }
}
</script>