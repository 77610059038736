<template>
  <v-layout column>
    <v-layout row no-gutters class="pl-6 ml-2 mb-2 pb-2">
      <div class="invert--text friendly-text">
        <h1>Role Delegation</h1>
        <h4>Please select roles to delegate to individuals.</h4>
      </div>
    </v-layout>

    <v-card 
      color="primary" 
      height="100%"
      class="rounded-xl pa-6 mx-6 mb-6">
        <v-container fluid>
            <v-row>
              <v-col 
                cols="12">
                <v-data-table
                  :headers="headers"
                  :items="this.UsersList"
                  :items-per-page="10"
                  :search="search"
                  class="tertiary elevation-2"
                  no-data-text="No Players"
                >
                <template v-slot:top>
                  <v-container class="d-flex flex-column flex-wrap">
                    <v-text-field
                      outlined
                      color="contrast"
                      v-model="search"
                      prepend-inner-icon="mdi-magnify"
                      label="Search"
                      clearable
                    ></v-text-field>
                  <div class="d-flex flex-row flex-wrap justify-space-between">
                  </div>
                  </v-container>

                </template>
                <template v-slot:item.player.is_marshal="{ item }">
                  <v-simple-checkbox
                    disabled
                    color="contrast"
                    v-model="item.player.is_marshal"
                    >
                  </v-simple-checkbox>
                </template>
                <template v-slot:item.player.is_staff="{ item }">
                  <v-simple-checkbox
                    disabled
                    color="contrast"
                    v-model="item.player.is_staff"
                    >
                  </v-simple-checkbox>
                </template>
                <template v-slot:item.player.is_director="{ item }">
                  <v-simple-checkbox
                    disabled
                    color="contrast"
                    v-model="item.player.is_director"
                    >
                  </v-simple-checkbox>
                </template>
                <template v-slot:item.buttons="{ item }">
                  {{item.id}}
                </template>
                </v-data-table>
                </v-col>
            </v-row>
      </v-container>
    </v-card>
  </v-layout>
</template>

<script>
  import userService from '@/services/userService';
  import staffService from '@/services/staffService';

  export default {
    name: 'RoleDelegation',
    data: () => ({
        UsersList: [],
        search: '',
        headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
          sortable: true,
        },
        { text: 'Email', value: 'email', filterable:true, sortable: false},
        { text: 'Prefix', value: 'player.cardnumber_prefix', filterable:true, sortable: false},
        { text: 'Marshal', value: 'player.is_marshal', sortable: false},
        { text: 'Staff', value: 'player.is_staff', sortable: false},
        { text: 'Director', value: 'player.is_director', sortable: false},
        { text: '', value: 'buttons', filterable:false }
      ],
    }),
    methods: {

        async refreshUser() {
          const user = JSON.parse(this.$store.state.authenticatedUser)

          if(!user.id) {
            this.$router.replace('logout')
          }

          const response = await userService.fetchUser(user.id, this.$store.state.token)
          if(response.status != 200) {
            this.$router.replace('logout')
          } else {
            const refreshedUser = response.data
            this.$store.commit('updateSessionUser', refreshedUser)
          }
        },
        async getUsers() {
          var response = await staffService.getUsers(this.selectedEvent);
          if(response.status != 200) {
              console.log("Failed Call");
          }
          else{
            this.UsersList = response.data.sort(function(a,b){
              var keyA = a.name
              var keyB = b.name
              if(keyA > keyB) return 1
              else if(keyB > keyA) return -1
              else return 0
            });
            console.log(this.UsersList)
          }
        },

        

    },
    computed: {
    },
    mounted: function(){
        this.refreshUser()
        this.getUsers()
    },
    watch: {

    }
  }
</script>