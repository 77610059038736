import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';

// import vuex store
import store from '@/store'

//import api
import api from "./services/api"

// import font-awesome icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTimes, faClock, faRocket, faCircle, faBell, faCreditCard, faGifts, faGift, faUserNinja, faWallet } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faGithub, faTwitch, faVuejs, faDiscord } from '@fortawesome/free-brands-svg-icons';

library.add(faVuejs, faGithub, faTwitter, faTwitch, faDiscord, faTimes, faClock, faRocket, faCircle, faBell, faCreditCard, faGift, faGifts, faUserNinja, faWallet);
Vue.component('font-awesome-icon', FontAwesomeIcon)

// vue icons
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'

// use axios
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(axios, VueAxios)

// vue-notifications
import Notifications from 'vue-notification'

import './registerServiceWorker'
Vue.use(Notifications)

// create global event bus
Vue.prototype.$eventHub = new Vue();

Vue.config.productionTip = false

Vue.prototype.$http = api;
api.defaults.timeout = 10000;

//Interceptors
import interceptorSetup from './services/interceptors';
interceptorSetup()

//Improved Calendars
import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});


new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

