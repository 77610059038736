<template>
<v-layout>
  <v-container>
    <v-overlay :value="pageLoaded">
      <v-progress-circular
        indeterminate
        size="128"
      ></v-progress-circular>
      <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="128"
      ></v-progress-circular>
    </v-overlay>
    </v-overlay>
    <v-dialog
      v-model="dialog"
      width="500"
    >
        <v-card>
        <v-card-title class="invert--text friendly-text primary">
          Confirm Operation
        </v-card-title>

        <v-card-text>
          <p
            v-if="operation=='clear'"
          >
            Warning: This will clear all skills and classes selected for the current character.
            <br/>
            Are you sure you wish to proceed?
          </p>
          <p
            v-else-if="operation=='reset'"
          >
            Warning: This will clear all skills and classes selected for the current character, and will restore the builder to the originally loaded values.
            <br/>
            Are you sure you wish to proceed?
          </p>
          <p 
            v-else
            >
            Warning: You should not be seeing this text.
            <br/>
            If you are, please submit a support ticket with a screenshot and we will work to resolve this issue.
            <br/>
            Thanks.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="clearOrReset()"
            >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <h1 class="friendly-text invert--text" align="center">Knight Realms - Character Card Updater</h1>
    <h2 v-show="!this.mobileLandscape" class="friendly-text invert--text" align="center"><br>Please Turn Your Mobile Device To Landscape Mode To Utilize The Card Builder.</h2>
    <v-container 
      v-show="this.mobileLandscape"
    >
        <v-card
            v-if="!pageLoaded"
            color="primary"
        >   
            <v-card-title class="contrast--text">Build Update</v-card-title>
            <!-- Add Additional Functionality like Event Selection -->
            <v-container>
              <v-col class="float-left" cols="3">
                <v-autocomplete
                v-model="selectedEvent"
                label="Event"
                :items="BuildHistory"
                item-text="name"
                color="contrast"
                item-color="contrast"
                required
                :dark="$vuetify.theme.dark"
                outlined
                >
              </v-autocomplete>
              </v-col>
              <v-row class="d-flex flex-row flex-nowrap justify-space-between">
                <v-text-field
                  disabled
                  shaped
                  v-model="currentlySelectedEvent.base"
                  type="number"
                  label="Current"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
                <v-text-field
                  disabled
                  shaped
                  v-model="currentlySelectedEvent.bought"
                  type="number"
                  label="Current"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
                <v-text-field
                  disabled
                  shaped
                  v-model="currentlySelectedEvent.npc_shift"
                  type="number"
                  label="Current"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
                <v-text-field
                  disabled
                  shaped
                  v-model="currentlySelectedEvent.lifestyle"
                  type="number"
                  label="Current"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
                <v-text-field
                  disabled
                  shaped
                  v-model="currentlySelectedEvent.roleplay_points"
                  type="number"
                  label="Current"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
                <v-text-field
                  disabled
                  shaped
                  v-model="currentlySelectedEvent.marshal"
                  type="number"
                  label="Current"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
                <v-text-field
                  disabled
                  shaped
                  v-model="currentlySelectedEvent.death"
                  type="number"
                  label="Current"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
                <v-text-field
                  disabled
                  shaped
                  v-model="currentlySelectedEvent.other"
                  type="number"
                  label="Current"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
              </v-row>
              <v-row class="d-flex flex-row flex-nowrap justify-space-between">
                <v-text-field
                  shaped
                  v-model="eventUpdate.base"
                  type="number"
                  label="Base"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
                <v-text-field
                  shaped
                  v-model="eventUpdate.bought"
                  type="number"
                  label="Bought"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
                <v-text-field
                  shaped
                  v-model="eventUpdate.npc_shift"
                  type="number"
                  label="NPC Shift"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
                <v-text-field
                  shaped
                  v-model="eventUpdate.lifestyle"
                  type="number"
                  label="Lifestyle"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
                <v-text-field
                  shaped
                  v-model="eventUpdate.roleplay_points"
                  type="number"
                  label="Roleplay"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
                <v-text-field
                  shaped
                  v-model="eventUpdate.marshal"
                  type="number"
                  label="Marshal"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
                <v-text-field
                  shaped
                  v-model="eventUpdate.death"
                  type="number"
                  label="Death"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
                <v-text-field
                  shaped
                  v-model="eventUpdate.other"
                  type="number"
                  label="Other"
                  color="contrast"
                  :dark="$vuetify.theme.dark"
                  outlined>
                </v-text-field>
              </v-row>
              <v-row>
                <v-col class="pa-4 d-flex flex-column flex-nowrap justify-start">
                  <v-text-field
                    class="flex-grow-0 flex-shrink-0"
                    disabled
                    shaped
                    v-model="ServicePointsTotal"
                    type="number"
                    label="Current Service Point Total"
                    color="contrast"
                    :dark="$vuetify.theme.dark"
                    outlined>
                  </v-text-field>
                  <v-text-field
                    class="flex-grow-0 flex-shrink-0"
                    disabled
                    shaped
                    v-model="currentlySelectedEvent.service_points"
                    type="number"
                    label="Current Event Service Points"
                    color="contrast"
                    :dark="$vuetify.theme.dark"
                    outlined>
                  </v-text-field>
                  <v-text-field
                    class="flex-grow-0 flex-shrink-0"
                    shaped
                    v-model="eventUpdate.service_points"
                    type="number"
                    label="New Event Service Points"
                    color="contrast"
                    :dark="$vuetify.theme.dark"
                    outlined>
                  </v-text-field>
                </v-col>
                <v-col class="pa-4 d-flex flex-column flex-nowrap justify-start">
                  <v-text-field
                    class="flex-grow-0 flex-shrink-0"
                    disabled
                    shaped
                    v-model="currentlySelectedEvent.gold"
                    type="number"
                    label="Current Gold Total"
                    color="contrast"
                    :dark="$vuetify.theme.dark"
                    outlined>
                  </v-text-field>
                  <v-text-field
                    class="flex-grow-0 flex-shrink-0"
                    shaped
                    v-model="eventUpdate.change_gold"
                    type="number"
                    label="Add/Remove Gold"
                    color="contrast"
                    :dark="$vuetify.theme.dark"
                    outlined>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-checkbox
                    v-model="increment_event"
                    label="Add To Events Attended"
                    color="contrast"
                    ></v-checkbox>
                    <div>
                      <v-checkbox
                        v-model="modifyEventsAttended"
                        label="Modify Events Attended"
                        color="contrast"
                        ></v-checkbox>
                      <v-text-field
                        class="flex-grow-0 flex-shrink-0"
                        shaped
                        v-model="eventsAttended"
                        type="number"
                        label="Events Attended"
                        color="contrast"
                        :dark="$vuetify.theme.dark"
                        :disabled="!modifyEventsAttended"
                        outlined>
                      </v-text-field>
                    </div>
                </v-col>
              </v-row>
            </v-container>
            <!--Begin Character Name -->
            <v-card-title class="contrast--text">Character Info Update</v-card-title>
            <v-container>
                <v-form 
                class="pa-4"
                    >
                    <v-row align="center">
                        <v-col cols="6">
                        <v-text-field 
                            v-model="name" 
                            label="Character Name"
                            :dark="$vuetify.theme.dark"
                            required
                            outlined
                            color="contrast"
                            shaped
                        ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-row
                            justify="end"
                          >
                            <v-checkbox
                              v-model="is_retired"
                              label="Retired?"
                              color="contrast"
                            ></v-checkbox>
                          </v-row>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="6">
                        <v-text-field 
                            v-model="vanity_title" 
                            label="Vanity Title"
                            :dark="$vuetify.theme.dark"
                            required
                            outlined
                            color="contrast"
                            shaped
                        ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-col cols="3">
                        <v-combobox
                        v-model="race"
                        label="Race"
                        :items="race_list"
                        item-text="name"
                        required
                        :dark="$vuetify.theme.dark"
                        dense
                        outlined
                        color="contrast"
                        ></v-combobox>
                        <v-combobox
                        v-model="religion"
                        label="Religion - Optional"
                        :items="religion_list"
                        required
                        clearable
                        :dark="$vuetify.theme.dark"
                        dense
                        outlined
                        color="contrast"
                        ></v-combobox>
                        </v-col>
                        <v-col cols="4">
                          <v-row align="center">
                            <v-col>
                            <v-row>
                                <v-autocomplete
                                v-model="background1"
                                :items="shown_backgrounds1"
                                item-value="id"
                                :item-text="item => item.name +' - '+ item.skill.name"
                                label="Background Skill #1"
                                color="contrast"
                                item-color="contrast"
                                required
                                :dark="$vuetify.theme.dark"
                                outlined
                                ></v-autocomplete>
                            </v-row>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col>
                            <v-row>
                                <v-autocomplete
                                v-model="background2"
                                :items="shown_backgrounds2"
                                item-value="id"
                                :item-text="item => item.name +' - '+ item.skill.name"
                                label="Background Skill #2"
                                color="contrast"
                                item-color="contrast"
                                required
                                :dark="$vuetify.theme.dark"
                                outlined
                                ></v-autocomplete>
                            </v-row>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col>
                            <v-row>
                              <v-autocomplete
                              v-model="cultural_literacy"
                              :items="shown_literacies"
                              item-value="abilities_id"
                              item-text="name"
                              label="Free Regional Literacy"
                              required
                              :dark="$vuetify.theme.dark"
                              outlined
                              color="contrast"
                              item-color="contrast"
                              ></v-autocomplete>
                            </v-row>
                          </v-col>
                        </v-row>
                        </v-col>
                    </v-row>
                    <br>
                    <v-textarea
                      v-model="characterMemo"
                      disabled
                      outlined
                      color="contrast"
                      shaped
                      label="Character Memo"
                      counter-value
                      >
                      </v-textarea>
                </v-form>
            </v-container>
            <!--Begin Class Skill Form -->
            <v-container>
                <v-form 
                v-if="!pageLoaded"
                ref="ClassSkillForm"
                class="pa-4"
                >
                <v-row class="mb-5 d-flex justify-space-between">
                    <div>
                    <v-btn
                        class="ml-5"
                        v-on:click="dialog=true; operation='clear';"
                        >
                        Clear
                    </v-btn>
                    <v-btn
                        class="ml-5"
                        v-on:click="dialog=true; operation='reset';"
                        >
                        Reset to Original
                    </v-btn>
                    </div>
                <div
                    class="mr-5"
                    >
                    <v-icon
                    v-if="!pageLoaded"
                    large
                    >mdi-heart</v-icon>
                    &nbsp; Body: {{this.getBodyTotal()}}
                </div>
                </v-row>
                <v-row class="d-flex flex-row flex-wrap justify-end">
                  <v-checkbox
                    class="pa-1"
                    color="contrast"
                    label="Chaos"
                    v-model="is_chaos"
                    disabled
                    @click="getAlignments()"
                    ></v-checkbox>
                    <v-checkbox
                    class="pa-1"
                    color="contrast"
                    label="Order"
                    v-model="is_order"
                    disabled
                    @click="getAlignments()"
                    ></v-checkbox>
                    <v-checkbox
                    class="pa-1"
                    color="contrast"
                    label="Light"
                    v-model="is_light"
                    disabled
                    @click="getAlignments()"
                    ></v-checkbox>
                    <v-checkbox
                    class="pa-1"
                    color="contrast"
                    label="Dark"
                    v-model="is_dark"
                    disabled
                    @click="getAlignments()"
                    ></v-checkbox>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-row align="center">
                      <v-col cols="9">
                        <v-autocomplete
                          clearable
                          @change="classUpdate(1)"
                          v-model="classes[1]"
                          label="Lower List #1"
                          :items="lower_list"
                          :key="counter"
                          item-value="id"
                          item-text='name'
                          required
                          :dark="$vuetify.theme.dark"
                          outlined
                          color="contrast"
                          item-color="contrast"
                          ></v-autocomplete>
                      </v-col>
                      <v-col cols="3" align-self="start">
                        <v-switch
                          color="contrast"
                          hint="Stamina"
                          persistent-hint
                          :disabled="classes[1]==null"
                          v-model="class_stamina[1]"
                        >
                        </v-switch>
                      </v-col>
                    <v-autocomplete
                    clearable
                    v-if="alignmentHasSelection(classes[1])"
                    v-model="list_alignments[1]"
                    @change="updateSpecificAlignment()"
                    label="Source"
                    :items="alignment_by_class.filter(row => row['class_field']==classes[1])"
                    item-value="id"
                    item-text="entity"
                    required
                    :dark="$vuetify.theme.dark"
                    outlined
                    color="contrast"
                    item-color="contrast"
                    ></v-autocomplete>
                    
                    </v-row>
                    <v-row align="center">
                    <v-col cols="9">
                        <v-autocomplete
                          clearable
                          @change="classUpdate(2)"
                          v-model="classes[2]"
                          label="Lower List #2"
                          :items="lower_list"
                          :key="counter"
                          item-value="id"
                          item-text='name'
                          required
                          :dark="$vuetify.theme.dark"
                          outlined
                          color="contrast"
                          item-color="contrast"
                          ></v-autocomplete>
                      </v-col>
                      <v-col cols="3" align-self="start">
                        <v-switch
                          color="contrast"
                          hint="Stamina"
                          persistent-hint
                          :disabled="classes[2]==null"
                          v-model="class_stamina[2]"
                        >
                        </v-switch>
                      </v-col>
                    <v-autocomplete
                    clearable
                    v-if="alignmentHasSelection(classes[2])"
                    v-model="list_alignments[2]"
                    @change="updateSpecificAlignment()"
                    label="Source"
                    :items="alignment_by_class.filter(row => row['class_field']==classes[2])"
                    item-value="id"
                    item-text="entity"
                    required
                    :dark="$vuetify.theme.dark"
                    outlined
                    color="contrast"
                    item-color="contrast"
                    ></v-autocomplete>

                    </v-row>
                    <v-row align="center">
                    <v-col cols="9">
                        <v-autocomplete
                          clearable
                          @change="classUpdate(3)"
                          v-model="classes[3]"
                          label="Lower List #3"
                          :items="lower_list"
                          :key="counter"
                          item-value="id"
                          item-text='name'
                          required
                          :dark="$vuetify.theme.dark"
                          outlined
                          color="contrast"
                          item-color="contrast"
                          ></v-autocomplete>
                      </v-col>
                      <v-col cols="3" align-self="start">
                        <v-switch
                          color="contrast"
                          hint="Stamina"
                          persistent-hint
                          :disabled="classes[3]==null"
                          v-model="class_stamina[3]"
                        >
                        </v-switch>
                      </v-col>
                    <v-autocomplete
                    clearable
                    v-if="alignmentHasSelection(classes[3])"
                    v-model="list_alignments[3]"
                    @change="updateSpecificAlignment()"
                    label="Source"
                    :items="alignment_by_class.filter(row => row['class_field']==classes[3])"
                    item-value="id"
                    item-text="entity"
                    required
                    :dark="$vuetify.theme.dark"
                    outlined
                    color="contrast"
                    item-color="contrast"
                    ></v-autocomplete>

                    </v-row>
                    <v-row align="center">
                    <v-col cols="9">
                        <v-autocomplete
                          clearable
                          @change="classUpdate(4)"
                          v-model="classes[4]"
                          label="Lower List #4"
                          :items="lower_list"
                          :key="counter"
                          item-value="id"
                          item-text='name'
                          required
                          :dark="$vuetify.theme.dark"
                          outlined
                          color="contrast"
                          item-color="contrast"
                          ></v-autocomplete>
                      </v-col>
                      <v-col cols="3" align-self="start">
                        <v-switch
                          color="contrast"
                          hint="Stamina"
                          persistent-hint
                          :disabled="classes[4]==null"
                          v-model="class_stamina[4]"
                        >
                        </v-switch>
                      </v-col>
                    <v-autocomplete
                    clearable
                    v-if="alignmentHasSelection(classes[4])"
                    v-model="list_alignments[4]"
                    @change="updateSpecificAlignment()"
                    label="Source"
                    :items="alignment_by_class.filter(row => row['class_field']==classes[4])"
                    item-value="id"
                    item-text="entity"
                    required
                    :dark="$vuetify.theme.dark"
                    outlined
                    color="contrast"
                    item-color="contrast"
                    ></v-autocomplete>
                    </v-row>
                    <v-row align="center">
                    <v-autocomplete
                    clearable
                    @change="classUpdate(5)"
                    v-model="classes[5]"
                    label="Master List #1"
                    :items="master_list"
                    :key="counter"
                    item-value="id"
                    item-text='name'
                    :dark="$vuetify.theme.dark"
                    outlined
                    color="contrast"
                    item-color="contrast"
                    ></v-autocomplete>
                    </v-row>
                    <v-row align="center">
                    <v-autocomplete
                    clearable
                    @change="classUpdate(6)"
                    v-model="classes[6]"
                    label="Master List #2"
                    :items="master_list"
                    :key="counter"
                    item-value="id"
                    item-text='name'
                    :dark="$vuetify.theme.dark"
                    outlined
                    color="contrast"
                    item-color="contrast"
                    ></v-autocomplete>
                    </v-row>
                    <v-row align="center">
                    <v-autocomplete
                    clearable
                    @change="classUpdate(7)"
                    v-model="classes[7]"
                    label="Master List #3"
                    :items="master_list"
                    :key="counter"
                    item-value="id"
                    item-text='name'
                    :dark="$vuetify.theme.dark"
                    outlined
                    color="contrast"
                    item-color="contrast"
                    ></v-autocomplete>
                    </v-row>
                    <v-row align="center">
                    <v-autocomplete
                    clearable
                    @change="classUpdate(8)"
                    v-model="classes[8]"
                    label="Exalted List #1"
                    :items="exalted_list"
                    :key="counter"
                    item-value="id"
                    item-text='name'
                    :dark="$vuetify.theme.dark"
                    outlined
                    color="contrast"
                    item-color="contrast"
                    ></v-autocomplete>
                    </v-row>
                    <v-row align="center">
                    <v-autocomplete
                    clearable
                    @change="classUpdate(9)"
                    v-model="classes[9]"
                    label="Legendary List #1 (LEAVE BLANK IF NOT APPLICABLE)"
                    :items="legendary_list"
                    :key="counter"
                    item-value="id"
                    item-text='name'
                    :dark="$vuetify.theme.dark"
                    outlined
                    color="contrast"
                    item-color="contrast"
                    ></v-autocomplete>
                    </v-row><v-row align="center">
                    <v-autocomplete
                    clearable
                    @change="classUpdate(10)"
                    v-model="classes[10]"
                    label="Exalted List #2"
                    :items="exalted_list"
                    :key="counter"
                    item-value="id"
                    item-text='name'
                    :dark="$vuetify.theme.dark"
                    outlined
                    color="contrast"
                    item-color="contrast"
                    ></v-autocomplete>
                    </v-row>
                    <v-row align="center">
                    <v-autocomplete
                    clearable
                    @change="classUpdate(11)"
                    v-model="classes[11]"
                    label="Legendary List #2 (LEAVE BLANK IF NOT APPLICABLE)"
                    :items="legendary_list"
                    :key="counter"
                    item-value="id"
                    item-text='name'
                    :dark="$vuetify.theme.dark"
                    outlined
                    color="contrast"
                    item-color="contrast"
                    ></v-autocomplete>
                    </v-row>
                    </v-col>
                    <v-col cols="9">
                    <v-row class="mb-5">
                        <v-col cols="5">
                        <v-row class="ml-5 mb-1">
                        <v-chip
                        :class='{ "success" : this.$store.state.character.buildTotal - this.buildSpent > 0 , "error": this.$store.state.character.buildTotal - this.buildSpent < 0, "warning": this.$store.state.character.buildTotal - this.buildSpent == 0 }'
                        >
                            Build Remaining: {{this.getBuildTotal() - this.buildSpent}}
                        </v-chip>
                        </v-row>
                        <v-row class="ml-5">
                        <v-chip
                        :class='{ "success" : this.$store.state.character.buildTotal - this.buildSpent > 0 , "error": this.$store.state.character.buildTotal - this.buildSpent < 0, "warning": this.$store.state.character.buildTotal - this.buildSpent == 0}'
                        >
                            Build Spent: {{this.buildSpent}}
                        </v-chip>
                        </v-row>
                        </v-col>
                        <v-col>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        :dark="$vuetify.theme.dark"
                        dense
                        outlined
                        color="contrast"
                        shaped
                        hide-details
                        width=20vh
                    ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-expansion-panels
                        :multiple="true"
                        focusable
                        inset>
                        <!-- Expansion Panel for Common and Lower List Skills-->
                        <v-expansion-panel>
                        <v-expansion-panel-header class="tertiary">Common/Lower List Skills</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <!-- Base Skills Table-->
                            <v-data-table
                            :headers="headers"
                            :items="shown_skills"
                            :key="counter"
                            hide-default-footer
                            :items-per-page="count"
                            :search="search"
                            fixed-header
                            no-data-text="Please Select Your Class(es)"
                            >
                            <template v-slot:item.quantity="{ item }">
                            {{getKnownQuantity(item)}}
                            </template>
                            <template v-slot:item.purchased="{ item }">
                            <v-icon
                            :disabled="getKnownQuantity(item)<1"
                            medium 
                            @click="decrementSkill(item)"
                            >
                                mdi-minus-circle
                            </v-icon>
                            <v-icon
                            :disabled="!allowNextPurchaseMarshal(item)"
                            medium 
                            @click="incrementSkill(item)"
                            >
                                mdi-plus-circle
                            </v-icon>
                            </template>
                            <template v-slot:item.nextPurchase="{item}">
                            {{getNthCost(item.abilities_id, getTotalPurchased(item.abilities_id)+1, item.cost, getModifier(item.abilities_id, classes), item.rank, item.ability_type)}}
                            </template>
                            <template v-slot:item.total="{item}">
                            {{getTotalCost(getTotalPurchased(item.abilities_id), item.cost, getModifier(item.abilities_id, classes), item.abilities_id, item.rank, item.ability_type)}}
                            </template>
                            <template v-slot:item.skillfullness="{item}">
                              <v-switch
                                v-if="getKnownQuantity(item)>0"
                                :disabled="item.ability_type!=3"
                                v-model="$store.state.character.skills[item.abilities_id].skillfullness"
                                @click="toggleSkillfullness()"
                                >
                              </v-switch>
                            </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Lower List 1 Selections -->
                        <v-expansion-panel
                        v-if="classes[1]!=null && weapon_prof_dict[1].length>1"
                        >
                        <v-expansion-panel-header class="tertiary">Lower List 1 Proficiencies - {{class_list.find(c => c.id == classes[1]).name}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-autocomplete
                            @change="weaponProfUpdate()"
                            v-model="selected_weapon_prof[0]"
                            label="Proficiency #1"
                            :items="weapon_prof_dict[1]"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            outlined
                            color="contrast"
                            item-color="contrast"
                            clearable
                            ></v-autocomplete>
                            <v-autocomplete
                            @change="weaponProfUpdate()"
                            v-model="selected_weapon_prof[1]"
                            label="Proficiency #2"
                            :items="weapon_prof_dict[1]"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            outlined
                            color="contrast"
                            item-color="contrast"
                            clearable
                            ></v-autocomplete>
                            <v-autocomplete
                            @change="weaponProfUpdate()"
                            v-model="selected_weapon_prof[2]"
                            label="Proficiency #3"
                            :items="weapon_prof_dict[1]"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            outlined
                            color="contrast"
                            item-color="contrast"
                            clearable
                            ></v-autocomplete>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Lower List 2 Selections -->
                        <v-expansion-panel
                        v-if="classes[2]!=null && weapon_prof_dict[2].length>1"
                        >
                        <v-expansion-panel-header class="tertiary">Lower List 2 Proficiencies - {{class_list.find(c => c.id == classes[2]).name}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-autocomplete
                            @change="weaponProfUpdate()"
                            v-model="selected_weapon_prof[3]"
                            label="Proficiency #1"
                            :items="weapon_prof_dict[2]"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                            <v-autocomplete
                            @change="weaponProfUpdate()"
                            v-model="selected_weapon_prof[4]"
                            label="Proficiency #2"
                            :items="weapon_prof_dict[2]"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            outlined
                            color="contrast"
                            item-color="contrast"
                            clearable
                            ></v-autocomplete>
                            <v-autocomplete
                            @change="weaponProfUpdate()"
                            v-model="selected_weapon_prof[5]"
                            label="Proficiency #3"
                            :items="weapon_prof_dict[2]"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlinede
                            clearable
                            ></v-autocomplete>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Lower List 3 Selections -->
                        <v-expansion-panel
                        v-if="classes[3]!=null && weapon_prof_dict[3].length>1"
                        >
                        <v-expansion-panel-header class="tertiary">Lower List 3 Proficiencies - {{class_list.find(c => c.id == classes[3]).name}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-autocomplete
                            @change="weaponProfUpdate()"
                            v-model="selected_weapon_prof[6]"
                            label="Proficiency #1"
                            :items="weapon_prof_dict[3]"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                            <v-autocomplete
                            @change="weaponProfUpdate()"
                            v-model="selected_weapon_prof[7]"
                            label="Proficiency #2"
                            :items="weapon_prof_dict[3]"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                            <v-autocomplete
                            @change="weaponProfUpdate()"
                            v-model="selected_weapon_prof[8]"
                            label="Proficiency #3"
                            :items="weapon_prof_dict[3]"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Lower List 4 Selections -->
                        <v-expansion-panel
                        v-if="classes[4]!=null && weapon_prof_dict[4].length>1"
                        >
                        <v-expansion-panel-header class="tertiary">Lower List 4 Proficiencies - {{class_list.find(c => c.id == classes[4]).name}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-autocomplete
                            @change="weaponProfUpdate()"
                            v-model="selected_weapon_prof[9]"
                            label="Proficiency #1"
                            :items="weapon_prof_dict[4]"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                            <v-autocomplete
                            @change="weaponProfUpdate()"
                            v-model="selected_weapon_prof[10]"
                            label="Proficiency #2"
                            :items="weapon_prof_dict[4]"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                            <v-autocomplete
                            @change="weaponProfUpdate()"
                            v-model="selected_weapon_prof[11]"
                            label="Proficiency #3"
                            :items="weapon_prof_dict[4]"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Character Rank 0 Spells -->
                        <v-expansion-panel 
                        v-if="shown_spells.filter(s=>s.rank==0).length>0">
                        <v-expansion-panel-header class="tertiary">Rank 0 Spells Selections</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Total Build Spent:  {{getTotalCost(getTotalPurchased(spell_purchase[0].abilities_id), spell_purchase[0].cost, getModifier(spell_purchase[0].abilities_id, classes), spell_purchase[0].abilities_id, 0, 13)}}
                            <br>
                            Next Purchase Cost: {{getNthCost(spell_purchase[0].abilities_id, getTotalPurchased(spell_purchase[0].abilities_id)+1, spell_purchase[0].cost, getModifier(spell_purchase[0].abilities_id, classes), 0, 13)}}
                            <v-container fluid>
                            <v-checkbox v-for="spell in shown_spells.filter(s=>s.rank==0)" 
                                v-model="known_spells_dict[0]" 
                                :label="spell.name" 
                                :key="spell.abilities_id"
                                :value="spell.abilities_id" 
                                @change="check($event, spell)">
                            </v-checkbox>
                            </v-container>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Character Rank 1 Spells -->
                        <v-expansion-panel
                        v-if="shown_spells.filter(s=>s.rank==1).length>0">
                        <v-expansion-panel-header class="tertiary">Rank 1 Spells Selections</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Total Build Spent:  {{getTotalCost(getTotalPurchased(spell_purchase[1].abilities_id), spell_purchase[1].cost, getModifier(spell_purchase[1].abilities_id, classes), spell_purchase[1].abilities_id, 1, 13)}}
                            <br>
                            Next Purchase Cost: {{getNthCost(spell_purchase[1].abilities_id, getTotalPurchased(spell_purchase[1].abilities_id)+1, spell_purchase[1].cost, getModifier(spell_purchase[1].abilities_id, classes), 1, 13)}}
                            <v-container fluid>
                            <v-checkbox v-for="spell in shown_spells.filter(s=>s.rank==1)" 
                                v-model="known_spells_dict[1]" 
                                :label="spell.name" 
                                :key="spell.abilities_id"
                                :value="spell.abilities_id" 
                                @change="check($event, spell)">
                            </v-checkbox>
                            </v-container>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Character Rank 2 Spells -->
                        <v-expansion-panel
                        v-if="shown_spells.filter(s=>s.rank==2).length>0">
                        <v-expansion-panel-header class="tertiary">Rank 2 Spells Selections</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Total Build Spent:  {{getTotalCost(getTotalPurchased(spell_purchase[2].abilities_id), spell_purchase[2].cost, getModifier(spell_purchase[2].abilities_id, classes), spell_purchase[2].abilities_id, 2, 13)}}
                            <br>
                            Next Purchase Cost: {{getNthCost(spell_purchase[2].abilities_id, getTotalPurchased(spell_purchase[2].abilities_id)+1, spell_purchase[2].cost, getModifier(spell_purchase[2].abilities_id, classes), 2, 13)}}
                            <v-container fluid>
                            <v-checkbox v-for="spell in shown_spells.filter(s=>s.rank==2)" 
                                v-model="known_spells_dict[2]" 
                                :label="spell.name" 
                                :key="spell.abilities_id"
                                :value="spell.abilities_id" 
                                @change="check($event, spell)">
                            </v-checkbox>
                            </v-container>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Character Rank 3 Spells -->
                        <v-expansion-panel
                        v-if="shown_spells.filter(s=>s.rank==3).length>0">
                        <v-expansion-panel-header class="tertiary">Rank 3 Spells Selections</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Total Build Spent:  {{getTotalCost(getTotalPurchased(spell_purchase[3].abilities_id), spell_purchase[3].cost, getModifier(spell_purchase[3].abilities_id, classes), spell_purchase[3].abilities_id, 3, 13)}}
                            <br>
                            Next Purchase Cost: {{getNthCost(spell_purchase[3].abilities_id, getTotalPurchased(spell_purchase[3].abilities_id)+1, spell_purchase[3].cost, getModifier(spell_purchase[3].abilities_id, classes), 3, 13)}}
                            <v-container fluid>
                            <v-checkbox v-for="spell in shown_spells.filter(s=>s.rank==3)" 
                                v-model="known_spells_dict[3]" 
                                :label="spell.name" 
                                :key="spell.abilities_id"
                                :value="spell.abilities_id" 
                                @change="check($event, spell)">
                            </v-checkbox>
                            </v-container>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Character Rank 4 Spells -->
                        <v-expansion-panel
                        v-if="shown_spells.filter(s=>s.rank==4).length>0">
                        <v-expansion-panel-header class="tertiary">Rank 4 Spells Selections</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Total Build Spent:  {{getTotalCost(getTotalPurchased(spell_purchase[4].abilities_id), spell_purchase[4].cost, getModifier(spell_purchase[4].abilities_id, classes), spell_purchase[4].abilities_id, 4, 13)}}
                            <br>
                            Next Purchase Cost: {{getNthCost(spell_purchase[4].abilities_id, getTotalPurchased(spell_purchase[4].abilities_id)+1, spell_purchase[4].cost, getModifier(spell_purchase[4].abilities_id, classes), 4, 13)}}
                            <v-container fluid>
                            <!-- p>{{ known_spells_dict[4] }}</!-->
                            <v-checkbox v-for="spell in shown_spells.filter(s=>s.rank==4)" 
                                v-model="known_spells_dict[4]" 
                                :label="spell.name" 
                                :key="spell.abilities_id"
                                :value="spell.abilities_id" 
                                @change="check($event, spell)">
                            </v-checkbox>
                            </v-container>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Character Rank 0 Talents -->
                        <v-expansion-panel 
                        v-if="shown_talents.filter(s=>s.rank==0).length>0">
                        <v-expansion-panel-header class="tertiary">Rank 0 Talents Selections</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Total Build Spent:  {{getTotalCost(getTotalPurchased(talent_purchase[0].abilities_id), talent_purchase[0].cost, getModifier(talent_purchase[0].abilities_id, classes), talent_purchase[0].abilities_id, 0, 14)}}
                            <br>
                            Next Purchase Cost: {{getNthCost(talent_purchase[0].abilities_id, getTotalPurchased(talent_purchase[0].abilities_id)+1, talent_purchase[0].cost, getModifier(talent_purchase[0].abilities_id, classes), 0, 14)}}
                            <v-container fluid>
                            <v-checkbox v-for="talent in shown_talents.filter(t=>t.rank==0)" 
                                v-model="known_talents_dict[0]" 
                                :label="talent.name" 
                                :key="talent.abilities_id"
                                :value="talent.abilities_id" 
                                @change="check($event, talent)">
                            </v-checkbox>
                            </v-container>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Character Rank 1 Talents -->
                        <v-expansion-panel
                        v-if="shown_talents.filter(s=>s.rank==1).length>0">
                        <v-expansion-panel-header class="tertiary">Rank 1 Talents Selections</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Total Build Spent:  {{getTotalCost(getTotalPurchased(talent_purchase[1].abilities_id), talent_purchase[1].cost, getModifier(talent_purchase[1].abilities_id, classes), talent_purchase[1].abilities_id, 1, 14)}}
                            <br>
                            Next Purchase Cost: {{getNthCost(talent_purchase[1].abilities_id, getTotalPurchased(talent_purchase[1].abilities_id)+1, talent_purchase[1].cost, getModifier(talent_purchase[1].abilities_id, classes), 1, 14)}}
                            <v-container fluid>
                            <v-checkbox v-for="talent in shown_talents.filter(t=>t.rank==1)" 
                                v-model="known_talents_dict[1]" 
                                :label="talent.name" 
                                :key="talent.abilities_id"
                                :value="talent.abilities_id" 
                                @change="check($event, talent)">
                            </v-checkbox>
                            </v-container>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Character Rank 2 Talents -->
                        <v-expansion-panel
                        v-if="shown_talents.filter(s=>s.rank==2).length>0">
                        <v-expansion-panel-header class="tertiary">Rank 2 Talents Selections</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Total Build Spent:  {{getTotalCost(getTotalPurchased(talent_purchase[2].abilities_id), talent_purchase[2].cost, getModifier(talent_purchase[2].abilities_id, classes), talent_purchase[2].abilities_id, 2, 14)}}
                            <br>
                            Next Purchase Cost: {{getNthCost(talent_purchase[2].abilities_id, getTotalPurchased(talent_purchase[2].abilities_id)+1, talent_purchase[2].cost, getModifier(talent_purchase[2].abilities_id, classes), 2, 14)}}
                            <v-container fluid>
                            <v-checkbox v-for="talent in shown_talents.filter(t=>t.rank==2)" 
                                v-model="known_talents_dict[2]" 
                                :label="talent.name" 
                                :key="talent.abilities_id"
                                :value="talent.abilities_id" 
                                @change="check($event, talent)">
                            </v-checkbox>
                            </v-container>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Character Rank 3 Talents -->
                        <v-expansion-panel
                        v-if="shown_talents.filter(s=>s.rank==3).length>0">
                        <v-expansion-panel-header class="tertiary">Rank 3 Talents Selections</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Total Build Spent:  {{getTotalCost(getTotalPurchased(talent_purchase[3].abilities_id), talent_purchase[3].cost, getModifier(talent_purchase[3].abilities_id, classes), talent_purchase[3].abilities_id, 3, 14)}}
                            <br>
                            Next Purchase Cost: {{getNthCost(talent_purchase[3].abilities_id, getTotalPurchased(talent_purchase[3].abilities_id)+1, talent_purchase[3].cost, getModifier(talent_purchase[3].abilities_id, classes), 3, 14)}}
                            <v-container fluid>
                            <v-checkbox v-for="talent in shown_talents.filter(t=>t.rank==3)" 
                                v-model="known_talents_dict[3]" 
                                :label="talent.name" 
                                :key="talent.abilities_id"
                                :value="talent.abilities_id" 
                                @change="check($event, talent)">
                            </v-checkbox>
                            </v-container>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Character Rank 4 Talents -->
                        <v-expansion-panel
                        v-if="shown_talents.filter(s=>s.rank==4).length>0">
                        <v-expansion-panel-header class="tertiary">Rank 4 Talents Selections</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Total Build Spent:  {{getTotalCost(getTotalPurchased(talent_purchase[4].abilities_id), talent_purchase[4].cost, getModifier(talent_purchase[4].abilities_id, classes), talent_purchase[4].abilities_id, 4, 14)}}
                            <br>
                            Next Purchase Cost: {{getNthCost(talent_purchase[4].abilities_id, getTotalPurchased(talent_purchase[4].abilities_id)+1, talent_purchase[4].cost, getModifier(talent_purchase[4].abilities_id, classes), 4, 14)}}
                            <v-container fluid>
                            <v-checkbox v-for="talent in shown_talents.filter(t=>t.rank==4)" 
                                v-model="known_talents_dict[4]" 
                                :label="talent.name" 
                                :key="talent.abilities_id"
                                :value="talent.abilities_id" 
                                @change="check($event, talent)">
                            </v-checkbox>
                            </v-container>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Master List 1 Selections -->
                        <v-expansion-panel
                        v-if="classes[5]!=null"
                        >
                        <v-expansion-panel-header class="tertiary">Master List 1 Selections</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-autocomplete
                            @change="masterSkillUpdate()"
                            v-model="selected_master_skills[0]"
                            label="Skill #1"
                            :items="master_skills_list_dict[1].filter(s=>!([selected_master_skills[1], selected_master_skills[2]].includes(s.abilities_id)))"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                            <v-autocomplete
                            @change="masterSkillUpdate()"
                            v-model="selected_master_skills[1]"
                            label="Skill #2"
                            :items="master_skills_list_dict[1].filter(s=>!([selected_master_skills[0], selected_master_skills[2]].includes(s.abilities_id)))"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                            <v-autocomplete
                            @change="masterSkillUpdate()"
                            v-model="selected_master_skills[2]"
                            label="Skill #3"
                            :items="master_skills_list_dict[1].filter(s=>!([selected_master_skills[0], selected_master_skills[1]].includes(s.abilities_id)))"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Master List 2 Selections -->
                        <v-expansion-panel
                        v-if="classes[6]!=null"
                        >
                        <v-expansion-panel-header class="tertiary">Master List 2 Selections</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-autocomplete
                            @change="masterSkillUpdate()"
                            v-model="selected_master_skills[3]"
                            label="Skill #1"
                            :items="master_skills_list_dict[2].filter(s=>!([selected_master_skills[4], selected_master_skills[5]].includes(s.abilities_id)))"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                            <v-autocomplete
                            @change="masterSkillUpdate()"
                            v-model="selected_master_skills[4]"
                            label="Skill #2"
                            :items="master_skills_list_dict[2].filter(s=>!([selected_master_skills[3], selected_master_skills[5]].includes(s.abilities_id)))"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                            <v-autocomplete
                            @change="masterSkillUpdate()"
                            v-model="selected_master_skills[5]"
                            label="Skill #3"
                            :items="master_skills_list_dict[2].filter(s=>!([selected_master_skills[3], selected_master_skills[4]].includes(s.abilities_id)))"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Master List 3 Selections -->
                        <v-expansion-panel
                        v-if="classes[7]!=null"
                        >
                        <v-expansion-panel-header class="tertiary">Master List 3 Selections</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-autocomplete
                            @change="masterSkillUpdate()"
                            v-model="selected_master_skills[6]"
                            label="Skill #1"
                            :items="master_skills_list_dict[3].filter(s=>!([selected_master_skills[7], selected_master_skills[8]].includes(s.abilities_id)))"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                            <v-autocomplete
                            @change="masterSkillUpdate()"
                            v-model="selected_master_skills[7]"
                            label="Skill #2"
                            :items="master_skills_list_dict[3].filter(s=>!([selected_master_skills[6], selected_master_skills[8]].includes(s.abilities_id)))"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                            <v-autocomplete
                            @change="masterSkillUpdate()"
                            v-model="selected_master_skills[8]"
                            label="Skill #3"
                            :items="master_skills_list_dict[3].filter(s=>!([selected_master_skills[6], selected_master_skills[7]].includes(s.abilities_id)))"
                            item-value="abilities_id"
                            item-text='name'
                            :dark="$vuetify.theme.dark"
                            color="contrast"
                            item-color="contrast"
                            outlined
                            clearable
                            ></v-autocomplete>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Master List Skills-->
                        <v-expansion-panel
                        v-if="!classes.slice(5,8).every(element=>element===null)">
                        <v-expansion-panel-header class="tertiary">Master List Skills</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <!-- Master Skills Table -->
                            <v-data-table
                            :headers="headers"
                            :items="shown_master_skills"
                            :key="counter"
                            hide-default-footer
                            :items-per-page="count"
                            :search="search"
                            fixed-header
                            no-data-text="Please Select Your Class(es)"
                            >
                            <template v-slot:item.quantity="{ item }">
                            {{getKnownQuantity(item)}}
                            </template>
                            <template v-slot:item.purchased="{ item }">
                            <v-icon
                            medium 
                            @click="decrementSkill(item)"
                            :disabled="getKnownQuantity(item)<1"
                            >
                                mdi-minus-circle
                            </v-icon>
                            <v-icon
                            medium 
                            @click="incrementSkill(item)"
                            :disabled="!allowNextPurchaseMarshal(item)"
                            >
                                mdi-plus-circle
                            </v-icon>
                            </template>
                            <template v-slot:item.nextPurchase="{item}">
                            {{getNthCost(item.abilities_id, getTotalPurchased(item.abilities_id)+1, item.cost, getModifier(item.abilities_id, classes), item.rank, item.ability_type)}}
                            </template>
                            <template v-slot:item.total="{item}">
                            {{getTotalCost(getTotalPurchased(item.abilities_id), item.cost, getModifier(item.abilities_id, classes), item.abilities_id, item.rank, item.ability_type)}}
                            </template>
                            <template v-slot:item.skillfullness="{item}">
                              <v-switch
                                v-if="getKnownQuantity(item)>0"
                                :disabled="item.ability_type!=3"
                                v-model="$store.state.character.skills[item.abilities_id].skillfullness"
                                @click="toggleSkillfullness()"
                                >
                              </v-switch>
                            </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Expansion Panel for Exalted List Skills-->
                        <v-expansion-panel
                        v-if="!classes.slice(8,10).every(element=>element===null)">
                        <v-expansion-panel-header class="tertiary">Exalted List Skills</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <!-- Exalted Skills Table -->
                            <v-data-table
                            :headers="headers"
                            :items="shown_exalted_skills"
                            :key="counter"
                            hide-default-footer
                            :items-per-page="count"
                            :search="search"
                            fixed-header
                            no-data-text="Please Select Your Class(es)"
                            >
                            <template v-slot:item.quantity="{ item }">
                            {{getKnownQuantity(item)}}
                            </template>
                            <template v-slot:item.purchased="{ item }">
                            <v-icon
                            medium 
                            @click="decrementSkill(item)"
                            :disabled="getKnownQuantity(item)<1"
                            >
                                mdi-minus-circle
                            </v-icon>
                            <v-icon
                            medium 
                            @click="incrementSkill(item)"
                            :disabled="!allowNextPurchaseMarshal(item)"
                            >
                                mdi-plus-circle
                            </v-icon>
                            </template>
                            <template v-slot:item.nextPurchase="{item}">
                            {{getNthCost(item.abilities_id, getTotalPurchased(item.abilities_id)+1, item.cost, getModifier(item.abilities_id, classes), item.rank, item.ability_type)}}
                            </template>
                            <template v-slot:item.total="{item}">
                            {{getTotalCost(getTotalPurchased(item.abilities_id), item.cost, getModifier(item.abilities_id, classes), item.abilities_id, item.rank, item.ability_type)}}
                            </template>
                            <template v-slot:item.skillfullness="{item}">
                              <v-switch
                                v-if="getKnownQuantity(item)>0"
                                :disabled="item.ability_type!=3"
                                v-model="$store.state.character.skills[item.abilities_id].skillfullness"
                                @click="toggleSkillfullness()"
                                >
                              </v-switch>
                            </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    </v-col>
                </v-row>
                </v-form>
            </v-container>
            <v-card-actions class="d-flex flex-row justify-end">
              <v-btn
                large
                @click="submitUpdateCharacter()"
                >
                Submit
              </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
  </v-container>
</v-layout>
</template>

<script>

import cardService from '@/services/cardService';
import staffService from '@/services/staffService';

export default {
  name: 'UpdateCharacterCards',
  components: {
  },
  data: () => ({
    class_list: Array,
    skills: Array,
    skills_by_class: Array,
    background_list: Array,
    race_list: Array,
    religion_list: Array,
    alignment_by_class: Array,
    mobileLandscape: Boolean,
    originalCharacter: Object,
    classes_loaded: false,
    skills_loaded: false,
    skills_by_class_loaded: false,
    alignments_by_class_loaded: false,
    backgrounds_loaded: false,
    religions_loaded: false,
    races_loaded: false,
    character_card_loaded: false,
    character_attendance_loaded: false,
    build_history_loaded: false,
    service_points_loaded: false,
    //split variables
    CharacterAttendance: Array,
    BuildHistory: Array,
    ServicePoints: Array,
    ServicePointsTotal: 0,
    shown_backgrounds1: Array,
    shown_backgrounds2: Array,
    shown_literacies: Array,
    
    overlay: false,
    characterSubmitStatus: null,
    dialog: false,
    operation: '',
    count: 1000,
    counter: 0,
    search: '',
    headers: [
        {
          text: 'Skill Name',
          align: 'start',
          value: 'name',
          sortable: false,
          filterable: true
        },
        { 
          text: 'Type',
          value: 'type',
          sortable: false,
          filterable: true
        
        },
        { text: 'Quantity', value: 'quantity', sortable: false,},
        { text: 'Purchase', value: 'purchased', sortable: false, },
        { text: 'Next Purchase', value: 'nextPurchase', sortable: false,},
        { text: 'Total Spent', value: 'total', sortable: false,},
        { text: 'Skillfullness', value: 'skillfullness', sortable: false}
        
      ],
    lower_list: Array,
    master_list: Array,
    exalted_list: Array,
    legendary_list: Array,

    //classes: [1, null, null, null, null, null, null, null, null, null],
    shown_skills: [],
    shown_spells: [],
    shown_talents: [],
    shown_weapon_prof: [],
    shown_master_skills: [],
    shown_exalted_skills: [],
    hidden_skills: [],
    master_skills_list_dict: {1: [], 2: [], 3:[]},
    weapon_prof_dict: {1:[], 2:[], 3:[], 4:[]},
    list_alignments: [null, null, null, null, null, null, null, null, null, null, null, null],
    spells_purchased_dict: {0: 0, 1:0, 2:0, 3:0, 4:0},
    known_spells_dict: {0:[], 1:[], 2:[], 3:[], 4:[]},
    talents_purchased_dict: {0: 0, 1:0, 2:0, 3:0, 4:0},
    known_talents_dict: {0:[], 1:[], 2:[], 3:[], 4:[]},
    spell_purchase: {0:null, 1:null, 2:null, 3:null, 4:null},
    talent_purchase: {0:null, 1:null, 2:null, 3:null, 4:null},
    paragonCount: 0,
    character_fully_loaded: false,
    selectedEvent: Object,
    currentlySelectedEvent: {
      base: Number,
      bought: Number,
      lifestyle: Number,
      roleplay_points: Number,
      marshal: Number,
      npc_shift: Number,
      death: Number,
      other: Number,
      gold: Number
    },
    eventUpdate: {
      base: Number,
      bought: Number,
      lifestyle: Number,
      roleplay_points: Number,
      marshal: Number,
      npc_shift: Number,
      death: Number,
      other: Number,
      gold: Number,
      change_gold: Number
    },
    is_light: false,
    is_dark: false,
    is_order: false,
    is_chaos: false,
    increment_event: false,
    modifyEventsAttended: false,
  }),
  props: {
    cardNumber: String
    },
  watch: {
    originalCharacter: function(character){
        this.name = character.name
        this.vanity_title = character.vanity_title
        this.buildTotal = character.build_total.toString()
        this.eventsAttended = character.past_event_count.toString()
        this.race = character.race
        this.religion = character.religion=="" ? null : character.religion
        this.is_retired = character.is_retired

        this.background1 = this.background1==null? character.background_1.id: this.background1
        this.background2 = this.background2==null? character.background_2.id: this.background2
        if(this.cultural_literacy==null){
          if(this.originalCharacter.literacy != null){
            this.cultural_literacy = this.originalCharacter.literacy.abilities_id
          }
        }
        this.setSpellTalentConstants()
        this.populateOriginalCharacter()
        this.character_fully_loaded = true
    },

    selectedEvent: function(event){
      if(typeof(event)==='string'){
        this.currentlySelectedEvent = this.BuildHistory.find(e=> e.name == event)
      }
      else{
        this.currentlySelectedEvent = event
      }
      this.currentlySelectedEvent.service_points = this.ServicePoints.find(e=>e.event.id == this.currentlySelectedEvent.event).points
      this.eventUpdate= {
            base: this.currentlySelectedEvent.base,
            bought: this.currentlySelectedEvent.bought,
            lifestyle: this.currentlySelectedEvent.lifestyle,
            roleplay_points: this.currentlySelectedEvent.roleplay_points,
            marshal: this.currentlySelectedEvent.marshal,
            npc_shift: this.currentlySelectedEvent.npc_shift,
            death: this.currentlySelectedEvent.death,
            other: this.currentlySelectedEvent.other,
            gold: this.currentlySelectedEvent.gold,
            change_gold: 0,
            service_points: this.currentlySelectedEvent.service_points
          }
    }
  },
  computed: {
    buildTotal: {
            get: function(){
                var t = this.$store.state.character.buildTotal
                return (isNaN(t) ? 0 : t)
            },
            set: function(value){
                this.$store.commit("updateBuildTotal", parseInt(value.replace(/\D/g, '').substring(0, 5)));
            }
        },
    eventsAttended: {
            get: function(){
                var t = this.$store.state.character.eventsAttended
                return (isNaN(t) ? 0 : t)
            },
            set: function(value){
                this.$store.commit("updateEventsAttended", parseInt(value.replace(/\D/g, '').substring(0, 5)));
            }
        },
    race: {
            get: function(){
                return this.$store.state.character.race;
            },
            set: function(value){
                this.$store.state.character.race = value;
                this.$store.state.character.directorApprovalRequired = (!this.race_list.includes(this.race) || !(this.religion == null || this.religion == '' || this.religion_list.includes(this.religion)))
            }
        },
    religion: {
            get: function(){
                return this.$store.state.character.religion;
            },
            set: function(value){
                this.$store.state.character.religion = value;
                this.$store.state.character.directorApprovalRequired = (!this.race_list.includes(this.race) || !(this.religion == null || this.religion == '' || this.religion_list.includes(this.religion)))
            }
        },
    name: {
            get: function(){
                return this.$store.state.character.name;
            },
            set: function(value){
                this.$store.state.character.name = value;
            }
        },
    vanity_title: {
            get: function(){
                return this.$store.state.character.vanity_title;
            },
            set: function(value){
                this.$store.state.character.vanity_title = value;
            }
        },
    background1: {
            get: function(){
                return this.$store.state.character.background[0];
            },
            set: function(value){
                this.$store.state.character.background.splice(0, 1, value)
                //this.$store.state.character.background[0] = value;
                this.shown_backgrounds2 = this.background_list.filter(b => b.id != value)
            }
        },
    background2: {
            get: function(){
                return this.$store.state.character.background[1];
            },
            set: function(value){
                this.$store.state.character.background.splice(1, 1, value)
                //this.$store.state.character.background[1] = value;
                this.shown_backgrounds1 = this.background_list.filter(b => b.id != value)
            }
        },
    cultural_literacy: {
            get: function(){
              return this.$store.state.character.literacy
            },
            set: function(value){
              this.$store.state.character.literacy = value
            }
      },
    selected_master_skills:{
      get(){
        return this.$store.state.character.selectedMasterSkills;
      },
      set(value){
        this.$store.commit('updateSelectedMasterSkills', value)
      }
    },
    selected_weapon_prof:{
      get(){
        return this.$store.state.character.selectedWeaponProf;
      },
      set(value){
        this.$store.commit('updateSelectedWeaponProf', value)
      }
    },
    list_entities_ids: {
      get(){
        return this.$store.state.character.entityList
      },
      set(value){
        this.$store.commit('updateEntityList', value)
      }
    },
    class_stamina: {
      get(){
        return this.$store.state.character.classStamina
      },
      set(value){
        this.$store.commit('updateStaminaList', value)
      }
    },
    buildSpent: {
      get(){
        return this.$store.state.character.buildSpent
      },
      set(value){
        this.$store.commit('updateBuildSpent', value)
      }
    },
    classes: {
      get(){
        return this.$store.state.character.classes
      },
      set(value){
        this.$store.commit('updateClasses', value)
      }
    },

    loggedIn: {
      get() {
        return JSON.parse(this.$store.state.isLoggedIn)
      }
    },
    pageLoaded(){
        return !this.classes_loaded || !this.skills_loaded || !this.skills_by_class_loaded || !this.alignments_by_class_loaded || !this.backgrounds_loaded || !this.religions_loaded || !this.races_loaded || !this.character_card_loaded || !this.character_attendance_loaded || !this.build_history_loaded || !this.service_points_loaded || !this.character_fully_loaded
    },
    characterMemo: {
      get(){
        return this.$store.state.character.characterMemo
      },
      set(value){
        this.$store.state.character.characterMemo = value
      }
    },
    is_retired: {
      get(){
        return this.$store.state.character.is_retired
      },
      set(value){
        this.$store.state.character.is_retired = value
      }
    }
  },
  mounted: function(){
    this.fetchFormData();
    //check to see if character has already been approved or not
    this.mobileLandscape =  (window.screen.orientation.type === "landscape-primary" ? true : false)
    window.addEventListener(
      "orientationchange",
      this.handleOrientationChange
    );
  },
  methods: {

    handleOrientationChange() {
      const orientation = window.screen.orientation.type
      if (orientation === "portrait-primary") {
        this.mobileLandscape = false
      } else if (orientation === "landscape-primary") {
        this.mobileLandscape = true
      }
    },  

    fetchFormData(){
      this.fetchClassesData()
      this.fetchSkillsData()
      this.fetchSkillsByClassData()
      this.fetchAlignmentsByClassData()
      this.fetchBackgrounds()
      this.fetchReligions()
      this.fetchRaces()
      this.updatePlayerProfile()
      this.fetchCharacterCard()
    },
    async fetchClassesData(){
      var response = await cardService.getClasses(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.class_list = response.data.sort(function (a,b){return a.name<b.name ? -1 : 1});
        this.setClassLists() 
        this.classes_loaded = true
      }
    },
    async fetchSkillsData(){
      var response = await cardService.getSkills(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.skills = response.data;
        this.skills.forEach(s => s.type = this.abilityTypeString(s.ability_type), this)
        this.skills.filter(a=> [1, 11].includes(a.ability_type)).forEach(s => s.name = s.name.match(/\(([^)]+)\)/)[1])
        this.shown_literacies = this.skills.filter(a=> a.ability_type == 1)
        this.skills_loaded = true
      }
    },
    async fetchSkillsByClassData(){
      var response = await cardService.getSkillsByClass(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.skills_by_class = response.data;
        this.skills_by_class_loaded = true
      }
    },
    async fetchAlignmentsByClassData(){
      var response = await cardService.getAlignmentByClass(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.alignment_by_class = response.data.sort(function (a,b){return a.entity<b.entity ? -1 : 1});
        this.alignments_by_class_loaded = true;
      }
    },
    async fetchBackgrounds(){
      var response = await cardService.getBackgrounds(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.background_list = response.data.sort(function (a,b){return a.name<b.name ? -1 : 1});
        this.backgrounds_loaded = true
      }
    },
    async fetchRaces(){
      var response = await cardService.getRaces(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.race_list = response.data.map(r=>r["name"]).sort();
        this.races_loaded = true;
      }
    },
    async fetchReligions(){
      var response = await cardService.getReligions(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.religion_list = response.data.map(r=>r["name"]).sort();
        this.religions_loaded = true;
      }
    },
    async fetchCharacterCard(){
      var response = await cardService.getCharacterCard(this.cardNumber, this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.originalCharacter = response.data;
        this.fetchPlayerServicePoints()
        this.fetchCharacterAttendance()
        this.character_card_loaded = true;
        //Now that this is loaded call the related pieces
      }
    },
    async fetchCharacterAttendance(){
      var response = await cardService.getCharacterAttendance(this.cardNumber, this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.CharacterAttendance = response.data.sort((a, b) => b.event.id > a.event.id && 1 || -1)
        this.character_attendance_loaded = true;
        this.fetchCharacterBuildPoints()
      }
    },
    async fetchPlayerServicePoints(){
      var response = await cardService.getPlayerServicePoints(this.originalCharacter.player);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.ServicePoints = response.data.sort((a, b) => b.event.id > a.event.id && 1 || -1);
        this.ServicePoints.forEach(s => this.ServicePointsTotal+= s.points, this)
        this.service_points_loaded = true;
      }
    },
    async fetchCharacterBuildPoints(){
      var response = await cardService.getCharacterBuildPoints(this.cardNumber);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.BuildHistory = response.data.sort((a,b) => b.event > a.event && 1 || -1)
        this.BuildHistory.forEach(b=> b.name = this.CharacterAttendance.find(a=> a.event.id == b.event).event.name, this)
        this.selectedEvent = this.BuildHistory[0]
        this.build_history_loaded= true;
      }
    },
    async updatePlayerProfile(){
      this.$store.dispatch('getUserFromToken', this.$store.state.token)
                .then((user) => {
                  if(user.is_verified){
                    this.$store.commit('updateSessionUserPlayerProfile', user.player)
                  }
                })
    },
    setSpellTalentConstants(){
      for(let rank = 0; rank <=4; rank++){
        this.spell_purchase[rank] = this.skills.find(s=>s.rank==rank && s.ability_type==13)
        this.talent_purchase[rank] = this.skills.find(s=>s.rank==rank && s.ability_type==14)
      }
    },

    alignmentHasSelection(class_id){
      const alignmentList = this.alignment_by_class.filter(row => row["class_field"]==class_id)
      
      return alignmentList.length>2
    },

    getAlignment(class_id){
      const alignmentList = this.alignment_by_class.filter(row => row["class_field"]==class_id)
      
      let dedup = (items) => items.filter((v,i) => items.indexOf(v) === i)

      return alignmentList.length>1?dedup(alignmentList.map(a=>a.alignment)):(alignmentList.length==1?alignmentList[0].alignment:null)

    },

    updateAlignment(index){
      var alignment = this.getAlignment(this.classes[index])
      this.list_alignments[index] = alignment
      this.updateSpecificAlignment()
    },

    updateSpecificAlignment(){
      const abc = this.alignment_by_class

      var alignmentsByID = this.list_alignments.map(id => abc.filter(row =>row["id"]==id).map(c=>c["alignment"])[0])
      var alignmentsByName = this.list_alignments.filter(name => ["Light", "Dark", "Order", "Chaos"].includes(name))
      
      this.$store.commit('updateAlignments', alignmentsByID.concat(alignmentsByName))

      this.list_entities_ids = this.list_alignments.map(value => ["Light", "Dark", "Order", "Chaos", null].includes(value)? null : abc.filter(row=>row["id"]==value).map(c=>c["entity"])[0])
      this.setLocalAlignments()
    },

    updateGold(){
      let newGold = Number(this.eventUpdate.change_gold)
      newGold = isNaN(newGold)? 0 : newGold
      this.eventUpdate.gold = Number(this.currentlySelectedEvent.gold) + newGold
    },

    clearAlignments(){
      this.$store.commit('updateAlignments', [])
      this.is_light = false
      this.is_dark = false
      this.is_order = false
      this.is_chaos = false
    },

    getClassList(ability_id){
      var classList = this.skills_by_class.filter(c => c.ability==ability_id)
      return classList.map(c => c.class_field)
    },

    getModifier(skill_id, classesKnown){
      //check to see if skills is currently known and has skillfullness
      var mod = 0;
      if(Object.prototype.hasOwnProperty.call(this.$store.state.character.skills, skill_id)){
        mod += (this.$store.state.character.skills[skill_id].skillfullness? 1: 0)
      }
      var s_id = parseInt(skill_id)
      if(this.selected_master_skills.includes(s_id)){
        mod+=this.selected_master_skills.filter(s => s===s_id).length
      }
      else if(this.selected_weapon_prof.includes(s_id)){
        //break selected weapon profs into 4 distinct groups by lower list selections to account for cascading/scaling costs
        let ll1 = this.selected_weapon_prof.slice(0,3)
        let ll2 = this.selected_weapon_prof.slice(3,6)
        let ll3 = this.selected_weapon_prof.slice(6,9)
        let ll4 = this.selected_weapon_prof.slice(9,12)

        mod += ll1.includes(s_id) ? 1 : 0
        mod += ll2.includes(s_id) ? 1 : 0
        mod += ll3.includes(s_id) ? 1 : 0
        mod += ll4.includes(s_id) ? 1 : 0
      }
      else{
        var cList = this.getClassList(skill_id);
        classesKnown.forEach(function(c)
          {
            if (cList.includes(c)){mod+=1}
          })
      }
      return mod;
    },

    updateShownSkills(){
      const classes = this.classes
      var shown = this.skills_by_class.filter(function (item) {
        return classes.slice(0,5).includes(item.class_field);        
      }).map(a => a.ability)

      let dedup = (items) => items.filter((v,i) => items.indexOf(v) === i)

      shown = dedup(shown)
      this.shown_skills = this.skills.filter(function (s){
        return shown.includes(s.abilities_id) && s.ability_type!=4 && s.ability_type!=5 && s.ability_type!=13 && s.ability_type !=14
        }).sort(function (a,b){return a.name<b.name ? -1 : 1})

      this.shown_spells= this.skills.filter(function (s){
        return shown.includes(s.abilities_id) && s.ability_type==4
        }).sort(function (a,b){return a.name<b.name ? -1 : 1})
      
      this.shown_talents = this.skills.filter(function (s){
        return shown.includes(s.abilities_id) && s.ability_type==5
        }).sort(function (a,b){return a.name<b.name ? -1 : 1})

      this.hidden_skills = this.skills.filter(function (s){
        return shown.includes(s.abilities_id) && (s.ability_type==13 || s.ability_type ==14)
        }).sort(function (a,b){return a.name<b.name ? -1 : 1})
    },

    updateShownMasterSkills(){
      const classes = this.classes
      const selectedSkills = this.selected_master_skills

      let dedup = (items) => items.filter((v,i) => items.indexOf(v) === i)

      for(var mList=1; mList <=3; mList++){
        var master_list = this.skills_by_class.filter(function (item) {
              return classes.slice(4+mList,5+mList).includes(item.class_field);        
            }).map(a => a.ability)

        this.master_skills_list_dict[mList]= this.skills.filter(function (s){
              return master_list.includes(s.abilities_id) && s.ability_type!=4 &&s.ability_type!=5
              }).sort(function (a,b){return a.name<b.name ? -1 : 1})
      }
      var shown = this.skills_by_class.filter(function (item) {
        return classes.slice(5,8).includes(item.class_field);        
      }).map(a => a.ability)


      shown = dedup(shown)
      shown = shown.filter(s => selectedSkills.includes(s))
      this.shown_master_skills = this.skills.filter(function (s){
        return shown.includes(s.abilities_id) && s.ability_type!=4 &&s.ability_type!=5
        }).sort(function (a,b){return a.name<b.name ? -1 : 1})
      
    },

    updateShownExaltedSkills(){
      const classes = this.classes
      var shown = this.skills_by_class.filter(function (item) {
        return classes.slice(8,12).includes(item.class_field);        
      }).map(a => a.ability)

      let dedup = (items) => items.filter((v,i) => items.indexOf(v) === i)

      shown = dedup(shown)
      this.shown_exalted_skills = this.skills.filter(function (s){
        return shown.includes(s.abilities_id)
        }).sort(function (a,b){return a.name<b.name ? -1 : 1})
    },

    updateShownWeaponProfs(){
      const classes = this.classes
      const selectedSkills = this.selected_weapon_prof

      let dedup = (items) => items.filter((v,i) => items.indexOf(v) === i)

      for(var lList=1; lList <=4; lList++){
        var lower_list = this.skills_by_class.filter(function (item) {
              return classes.slice(lList,1+lList).includes(item.class_field);        
            }).map(a => a.ability)

        this.weapon_prof_dict[lList]= this.skills.filter(function (s){
              return lower_list.includes(s.abilities_id) && s.ability_type==15
              }).sort(function (a,b){return a.name<b.name ? -1 : 1})
      }
      var shown = this.skills_by_class.filter(function (item) {
        return classes.slice(1,5).includes(item.class_field);        
      }).map(a => a.ability)


      shown = dedup(shown)
      shown = shown.filter(s => selectedSkills.includes(s))
      this.shown_weapon_prof = this.skills.filter(function (s){
        return shown.includes(s.abilities_id) && s.ability_type==15
        }).sort(function (a,b){return a.name<b.name ? -1 : 1})
    },

    setClassLists(){
      this.lower_list = this.class_list.filter(function(c){return c.class_type!='master' && c.class_type!='exalted' && c.class_type!='legendary' && c.class_type!='common/prestige points'})
      this.master_list = this.class_list.filter(function(c){return c.class_type=='master'})
      this.exalted_list = this.class_list.filter(function(c){return c.class_type=='exalted'})
      this.legendary_list = this.class_list.filter(function(c){return c.class_type=='legendary'})
    },
    clearSkillsClasses(){
      this.classes = [null, null, null, null, null, null, null, null, null, null, null, null];
      this.updateShownSkills();
      this.updateShownMasterSkills();
      this.updateShownExaltedSkills();
      this.removeUnmatchedSkills();
      this.classes = [1, null, null, null, null, null, null, null, null, null, null, null]
      this.list_alignments = [null, null, null, null, null, null, null, null, null, null, null, null];
      this.list_entities_ids = [null, null, null, null, null, null, null, null, null, null, null, null];
      this.class_stamina = [null, null, null, null, null, null, null, null, null, null, null, null];
      this.classUpdate();
      this.clearAlignments();
      this.shown_skills=[];
      this.shown_master_skills=[];
      this.shown_spells=[];
      this.shown_talents=[];
      this.shown_weapon_prof=[];
      this.hidden_skills=[];
      this.spells_purchased_dict={0: 0, 1:0, 2:0, 3:0, 4:0};
      this.known_spells_dict={0:[], 1:[], 2:[], 3:[], 4:[]};
      this.talents_purchased_dict={0: 0, 1:0, 2:0, 3:0, 4:0};
      this.known_talents_dict={0:[], 1:[], 2:[], 3:[], 4:[]};
      this.weapon_prof_dict={1:[], 2:[], 3:[], 4:[]};
      this.selected_weapon_prof=[null, null, null, null, null, null, null, null, null, null, null, null];
      this.counter+=1;
      this.paragonCount=0;
    },

    masterSkillUpdate(){
      this.counter+=1;
      this.updateShownMasterSkills()
      this.removeUnmatchedSkills()
      this.recalculateBuildSpent()
    },

    weaponProfUpdate(){
      this.counter+=1;
      this.updateShownWeaponProfs()
      this.removeUnmatchedSkills()
      //Check to See if we know more instances of a weapon prof than we are allowed to and decrement
      let dedup = (items) => items.filter((v,i) => items.indexOf(v) === i)

      let profsKnown = dedup(this.selected_weapon_prof).filter(s => s!=null)
      let profSkills = this.skills.filter(s => s.ability_type==15)
      //Check if there are no more profs selected, if so clear all (fixes corner case where it cannot delete if there is nothing left)
      if(profsKnown.length==0){
        let shownProfs = profSkills.filter(s=>this.shown_skills.includes(s))
        shownProfs.forEach(function(prof){
          let kq = this.getKnownQuantity(prof)
          while(kq>0){
            this.decrementSkill(prof)
            kq -= 1
          }
        }, this)
      }
      else{
        profsKnown.forEach(function(prof_id){
          let prof = profSkills.find(s=>s.abilities_id==prof_id)
          let knownQuantity = this.getKnownQuantity(prof)
          let maxQuantity = this.selected_weapon_prof.filter(s => s==prof_id).length
          for(let x = knownQuantity; x>maxQuantity; x--){
            this.decrementSkill(prof)
          }
        },this)

        let shownProfs = profSkills.filter(s=>this.shown_skills.includes(s))
        let profsToBeRemoved = shownProfs.filter(s=> !profsKnown.includes(s))
        profsToBeRemoved.forEach(function(prof){
          let knownQuantity = this.getKnownQuantity(prof)
          let maxQuantity = this.selected_weapon_prof.filter(s => s==prof.abilities_id).length
          for(let x = knownQuantity; x>maxQuantity; x--){
            this.decrementSkill(prof)
          }
        },this)
      }
      this.recalculateBuildSpent()
    },

    spellTalentCountUpdate(){
      this.counter+=1;
      //Check to See if we know more instances of a spell or talent than we are allowed to and decrement

      //this.updateShownSkills();
      //this.removeUnmatchedSpellsTalents();

      let spellLevelsShown = this.hidden_skills.filter(s=>s.ability_type == 13)
      let talentLevelsShown = this.hidden_skills.filter(s=>s.ability_type == 14)
      
      spellLevelsShown.forEach(function(spellLevel){
        let maxSpellCount = this.shown_spells.filter(s=>s.rank==spellLevel.rank).length
        let currentKnownCount = this.known_spells_dict[spellLevel.rank].length
        let kq = this.getKnownQuantity(spellLevel)
        while(kq>maxSpellCount || kq>currentKnownCount){
          this.decrementSkill(spellLevel)
          kq -= 1
        }
      }, this)

      talentLevelsShown.forEach(function(talentLevel){
        let maxTalentCount = this.shown_talents.filter(s=>s.rank==talentLevel.rank).length
        let currentKnownCount = this.known_talents_dict[talentLevel.rank].length
        let kq = this.getKnownQuantity(talentLevel)
        while(kq>maxTalentCount || kq>currentKnownCount){
          this.decrementSkill(talentLevel)
          kq -= 1
        }
      }, this)
    },

    classUpdate: function(value) {
      switch(value){
        case 1:
          this.selected_weapon_prof[0]=null
          this.selected_weapon_prof[1]=null
          this.selected_weapon_prof[2]=null
          this.updateAlignment(value)
          this.weaponProfUpdate()
          if(this.weapon_prof_dict[1].length == 1){
            this.selected_weapon_prof[0]=this.weapon_prof_dict[1][0].abilities_id
            this.selected_weapon_prof[1]=this.weapon_prof_dict[1][0].abilities_id
            this.selected_weapon_prof[2]=this.weapon_prof_dict[1][0].abilities_id
          }
          break
        case 2:
          this.selected_weapon_prof[3]=null
          this.selected_weapon_prof[4]=null
          this.selected_weapon_prof[5]=null
          this.updateAlignment(value)
          this.weaponProfUpdate()
          if(this.weapon_prof_dict[2].length == 1){
            this.selected_weapon_prof[3]=this.weapon_prof_dict[2][0].abilities_id
            this.selected_weapon_prof[4]=this.weapon_prof_dict[2][0].abilities_id
            this.selected_weapon_prof[5]=this.weapon_prof_dict[2][0].abilities_id
          }
          break
        case 3:
          this.selected_weapon_prof[6]=null
          this.selected_weapon_prof[7]=null
          this.selected_weapon_prof[8]=null
          this.updateAlignment(value)
          this.weaponProfUpdate()
          if(this.weapon_prof_dict[3].length == 1){
            this.selected_weapon_prof[6]=this.weapon_prof_dict[3][0].abilities_id
            this.selected_weapon_prof[7]=this.weapon_prof_dict[3][0].abilities_id
            this.selected_weapon_prof[8]=this.weapon_prof_dict[3][0].abilities_id
          }
          break
        case 4:
          this.selected_weapon_prof[9]=null
          this.selected_weapon_prof[10]=null
          this.selected_weapon_prof[11]=null
          this.updateAlignment(value)
          this.weaponProfUpdate()
          if(this.weapon_prof_dict[4].length == 1){
            this.selected_weapon_prof[9]=this.weapon_prof_dict[4][0].abilities_id
            this.selected_weapon_prof[10]=this.weapon_prof_dict[4][0].abilities_id
            this.selected_weapon_prof[11]=this.weapon_prof_dict[4][0].abilities_id
          }
          break

        case 5:
          this.selected_master_skills[0]=null
          this.selected_master_skills[1]=null
          this.selected_master_skills[2]=null
          break

        case 6:
          this.selected_master_skills[3]=null
          this.selected_master_skills[4]=null
          this.selected_master_skills[5]=null
          break
        
        case 7:
          this.selected_master_skills[6]=null
          this.selected_master_skills[7]=null
          this.selected_master_skills[8]=null
          break

        default:
          break

      }
      //update ops counter
      this.counter+=1;
      this.$store.commit('updateClasses', this.classes)
      this.updateShownSkills()
      this.updateShownMasterSkills()
      this.updateShownExaltedSkills()
      this.updateShownWeaponProfs()
      this.removeUnmatchedSkills()
      this.spellTalentCountUpdate()
      this.recalculateBuildSpent()
    },
    getKnownQuantity(skill){
      var q = this.$store.state.character.skills[skill.abilities_id]
      return (q==null ? 0 : q.quantity)
    },

    getNthCost(ability_id, n, BaseCost, modifier, ability_rank, ability_type_id=0){
      var n_cost = BaseCost * 2**(Math.ceil(n/modifier)-1);
      switch (ability_type_id){
        case 1:
        case 2:
        case 4:
        case 5:
        case 10:
        case 12:
        case 16:
        case 18:
          return (n>1 ? "N/A": n_cost);
        case 19:
          return (n>3 ? "N/A": n_cost);
        case 9:
          n_cost = BaseCost * 2**(Math.ceil(n/modifier)-2);
          return (n>1 ? n_cost<100?n_cost:100 : 0 )
        case 13:
          return (n>this.shown_spells.filter(s=>s.rank==ability_rank).length ? "N/A": (n_cost<100)? n_cost: 100)
        case 14:
          return (n>this.shown_talents.filter(s=>s.rank==ability_rank).length ? "N/A": (n_cost<100)? n_cost: 100)
        case 15:
          var level_count = {1: 0, 2: 0, 3: 0}
          var weapon_prof_dict = {
            1: this.selected_weapon_prof.slice(0,3),
            2: this.selected_weapon_prof.slice(3,6),
            3: this.selected_weapon_prof.slice(6,9),
            4: this.selected_weapon_prof.slice(9,12)
          }
          for(let level = 1; level<=3; level++){
            Object.values(weapon_prof_dict).forEach(
              function(p){
                if (p.filter(s=> s==ability_id).length>=level){
                  level_count[level] +=1
                }
              }, this)
            } 
          if(n> level_count[1]+level_count[2]+level_count[3]){
            return "N/A"
          }
          else if(n>level_count[1]+level_count[2]){
            return BaseCost*4
          }
          else if (n>level_count[1]){
            return BaseCost*2
          }
          else{
            return BaseCost
          }
        default:
          return (n_cost<100 ? n_cost : 100);
      }
    },

    toggleSkillfullness(){
      this.recalculateBuildSpent()
      this.counter+=1;
    },

    incrementSkill: function(skill) {
      //update ops counter
      this.counter+=1
      var q = this.getKnownQuantity(skill)
      if ((skill.ability_type == 1 || skill.ability_type == 2 || skill.ability_type == 10) && q==1){
        //Do Nothing
      }
      //Case for Spells and Talents
      else if (((skill.ability_type == 13 && q>=this.shown_spells.filter(s=>s.rank == skill.rank).length)|| (skill.ability_type == 14 && q>=this.shown_talents.filter(s=>s.rank == skill.rank).length))){
        //Do Nothing
      }
      //Case for Weapon Prof Skills
      else if(skill.ability_type == 15 && q>=this.selected_weapon_prof.filter(s => s == skill.abilities_id).length){
        //Do Nothing
      }
      /*
      else if (this.buildSpent + this.getNthCost(skill.abilities_id, q+1, skill.cost, this.getModifier(skill.abilities_id, this.classes), skill.rank, skill.ability_type) > this.getBuildTotal()){
        //Do Nothing
      }
      else{
        if (!Object.prototype.hasOwnProperty.call(this.$store.state.character.skills, skill.abilities_id) && this.getNthCost(skill.abilities_id, q+1, skill.cost, this.getModifier(skill.abilities_id, this.classes), skill.rank, skill.ability_type)+this.buildSpent > this.getBuildTotal()){
          //Do Nothing
        }
        else{
        */
          var cost = this.getNthCost(skill.abilities_id, q+1, skill.cost, this.getModifier(skill.abilities_id, this.classes), skill.rank, skill.ability_type);

          this.$store.commit('updateSkill', [skill.abilities_id, "+", cost])
          this.buildSpent += cost
          //Check to see if incrementing Spells or Talents
          if(skill.ability_type == 13){
            this.spells_purchased_dict[skill.rank]+=1
          }
          else if(skill.ability_type == 14){
            this.talents_purchased_dict[skill.rank]+=1
          }
          else if(skill.ability_type == 10){
            this.paragonCount+=1
          }
          else if(skill.ability_type == 12){
            this.setLocalAlignments()
          }
        //}
      //}
    },
    decrementSkill: function(skill) {
      //update ops counter
      this.counter+=1;
      var cost = this.getNthCost(skill.abilities_id, this.getKnownQuantity(skill), skill.cost, this.getModifier(skill.abilities_id, this.classes), skill.rank, skill.ability_type)
      if(Object.prototype.hasOwnProperty.call(this.$store.state.character.skills, skill.abilities_id)){
        this.buildSpent -= cost
      }
      this.$store.commit('updateSkill', [skill.abilities_id, "-", cost])
      if(skill.ability_type == 10){
            this.paragonCount-=1
          }
      else if(skill.ability_type == 12){
            this.setLocalAlignments()
          }
    },

    getTotalPurchased(id){
      var t = this.$store.state.character.skills[id]
      return (t==null ? 0 : t.quantity)
    },

    getTotalCost(n, BaseCost, modifier, ability_id, ability_rank, ability_type){
      return (modifier==0? 0 :(n>0 ? this.getTotalCost(n-1, BaseCost, modifier, ability_id, ability_rank, ability_type) + this.getNthCost(ability_id, n, BaseCost, modifier, ability_rank, ability_type) : 0));
    },

    getBuildTotal(){
      var t = this.$store.state.character.buildTotal
      let currentEventBuildTotal = 0
      currentEventBuildTotal += (isNaN(parseInt(this.currentlySelectedEvent.base))? 0 : parseInt(this.currentlySelectedEvent.base))
      currentEventBuildTotal += (isNaN(parseInt(this.currentlySelectedEvent.bought))? 0 : parseInt(this.currentlySelectedEvent.bought))
      currentEventBuildTotal += (isNaN(parseInt(this.currentlySelectedEvent.npc_shift))? 0 : parseInt(this.currentlySelectedEvent.npc_shift))
      currentEventBuildTotal += (isNaN(parseInt(this.currentlySelectedEvent.marshal))? 0 : parseInt(this.currentlySelectedEvent.marshal))
      currentEventBuildTotal += (isNaN(parseInt(this.currentlySelectedEvent.death))? 0 : parseInt(this.currentlySelectedEvent.death))
      currentEventBuildTotal += (isNaN(parseInt(this.currentlySelectedEvent.lifestyle))? 0 : parseInt(this.currentlySelectedEvent.lifestyle))
      currentEventBuildTotal += (isNaN(parseInt(this.currentlySelectedEvent.other))? 0 : parseInt(this.currentlySelectedEvent.other))

      let newEventBuildTotal = 0      
      newEventBuildTotal += (isNaN(parseInt(this.eventUpdate.base)) ? 0 : parseInt(this.eventUpdate.base))
      newEventBuildTotal += (isNaN(parseInt(this.eventUpdate.bought)) ? 0 : parseInt(this.eventUpdate.bought))
      newEventBuildTotal += (isNaN(parseInt(this.eventUpdate.npc_shift)) ? 0 : parseInt(this.eventUpdate.npc_shift))
      newEventBuildTotal += (isNaN(parseInt(this.eventUpdate.marshal)) ? 0 : parseInt(this.eventUpdate.marshal))
      newEventBuildTotal += (isNaN(parseInt(this.eventUpdate.death)) ? 0 : parseInt(this.eventUpdate.death))
      newEventBuildTotal += (isNaN(parseInt(this.eventUpdate.lifestyle)) ? 0 : parseInt(this.eventUpdate.lifestyle))
      newEventBuildTotal += (isNaN(parseInt(this.eventUpdate.other)) ? 0 : parseInt(this.eventUpdate.other))

      return (isNaN(t+newEventBuildTotal-currentEventBuildTotal) ? 0 : t+newEventBuildTotal-currentEventBuildTotal)
    },

    removeUnmatchedSkills(){
      const t = this.$store.state.character.skills;
      Object.keys(t).forEach(function(s){
        if((this.shown_skills.concat(
          this.shown_master_skills).concat(
            this.shown_exalted_skills).concat(
            this.shown_spells).concat(
              this.shown_talents).concat(
                this.hidden_skills)).findIndex(ability=>{return ability.abilities_id==s})!= -1){/*Do Nothing*/}
        else{
          this.$store.commit('updateSkill', [s, "x"])
          if(this.skills.find(a=>a.abilities_id == s).ability_type == 10){
            this.paragonCount -=1
          }
        }
      }, this)
      this.removeUnmatchedSpellsTalents()
      this.fixParagons()
    },

    removeUnmatchedSpellsTalents(){
      //Start with Spells
      const ksd = this.known_spells_dict
      this.known_spells_dict[0] = this.shown_spells.filter(a=>ksd[0].includes(a.abilities_id)).map(a=>a.abilities_id) 
      this.known_spells_dict[1] = this.shown_spells.filter(a=>ksd[1].includes(a.abilities_id)).map(a=>a.abilities_id) 
      this.known_spells_dict[2] = this.shown_spells.filter(a=>ksd[2].includes(a.abilities_id)).map(a=>a.abilities_id) 
      this.known_spells_dict[3] = this.shown_spells.filter(a=>ksd[3].includes(a.abilities_id)).map(a=>a.abilities_id)
      this.known_spells_dict[4] = this.shown_spells.filter(a=>ksd[4].includes(a.abilities_id)).map(a=>a.abilities_id)

      //Move onto Talents
      const ktd = this.known_talents_dict
      this.known_talents_dict[0] = this.shown_talents.filter(a=>ktd[0].includes(a.abilities_id)).map(a=>a.abilities_id) 
      this.known_talents_dict[1] = this.shown_talents.filter(a=>ktd[1].includes(a.abilities_id)).map(a=>a.abilities_id) 
      this.known_talents_dict[2] = this.shown_talents.filter(a=>ktd[2].includes(a.abilities_id)).map(a=>a.abilities_id) 
      this.known_talents_dict[3] = this.shown_talents.filter(a=>ktd[3].includes(a.abilities_id)).map(a=>a.abilities_id)
      this.known_talents_dict[4] = this.shown_talents.filter(a=>ktd[4].includes(a.abilities_id)).map(a=>a.abilities_id)

      //Handle Spell and Talent Purchases
      //If undefined, skill does not currently exist, set purchased count to zero
      let skillCheck = this.hidden_skills.find(skill => skill.rank==0 && skill.ability_type==13)
      this.spells_purchased_dict[0] = (skillCheck == undefined ? 0 : this.known_spells_dict[0].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==1 && skill.ability_type==13)
      this.spells_purchased_dict[1] = (skillCheck == undefined ? 0 : this.known_spells_dict[1].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==2 && skill.ability_type==13)
      this.spells_purchased_dict[2] = (skillCheck == undefined ? 0 : this.known_spells_dict[2].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==3 && skill.ability_type==13)
      this.spells_purchased_dict[3] = (skillCheck == undefined ? 0 : this.known_spells_dict[3].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==4 && skill.ability_type==13)
      this.spells_purchased_dict[4] = (skillCheck == undefined ? 0 : this.known_spells_dict[4].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==0 && skill.ability_type==14)
      this.talents_purchased_dict[0] = (skillCheck == undefined ? 0 : this.known_talents_dict[0].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==1 && skill.ability_type==14)
      this.talents_purchased_dict[1] = (skillCheck == undefined ? 0 : this.known_talents_dict[1].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==2 && skill.ability_type==14)
      this.talents_purchased_dict[2] = (skillCheck == undefined ? 0 : this.known_talents_dict[2].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==3 && skill.ability_type==14)
      this.talents_purchased_dict[3] = (skillCheck == undefined ? 0 : this.known_talents_dict[3].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==4 && skill.ability_type==14)
      this.talents_purchased_dict[4] = (skillCheck == undefined ? 0 : this.known_talents_dict[4].length)
    },

    getKnownParagonsByClass(){
      let t = this.$store.state.character.skills;
      const paragonsKnown = Object.keys(t).filter(function(s){
        try{
          return this.shown_skills.find(a=>a.abilities_id ==s).ability_type == 10
        }catch(e){
          //This Catch is required to catch the skills that don't show up in shown_skills
          //but are still known by the character like Spells, Talents, Hidden, and Master Skills
          return false
        }
        }, this)
      let paragonsByClass = {}
      this.classes.slice(1,5).forEach(c=>paragonsByClass[c]?paragonsByClass[c]["quantity"]+=1: paragonsByClass[c]={quantity:1, skills:[]})
      paragonsKnown.forEach(
        function(p){
          try{
            paragonsByClass[this.skills_by_class.find(
            abc=>abc.ability==p).class_field]["skills"].push(p)
          }catch(e){
            //Catches case where class is no longer known, as this is invoked prior to the unlearn
            return
          }
        }
        ,this)
      return paragonsByClass
    },

    fixParagons(){
      let paragonsByClass = this.getKnownParagonsByClass()
      Object.keys(paragonsByClass).forEach(function(k){
        let difference = paragonsByClass[k]["skills"].length - paragonsByClass[k]["quantity"]
        while(difference>0){
          let s = paragonsByClass[k]["skills"].pop()
          this.$store.commit('updateSkill', [s, "x"])
          this.paragonCount -=1
          difference--
        }
      }, this)
    },

    recalculateBuildSpent(){
      var newSpent = 0;
      var t = this.$store.state.character.skills;
      Object.keys(t).forEach(function(s){
        var cost = this.getTotalCost(this.getTotalPurchased(s), this.skills.find(ability => {return ability.abilities_id == s}).cost, this.getModifier(s, this.classes), s, this.skills.find(ability => ability.abilities_id == s).rank, this.skills.find(ability => ability.abilities_id == s).ability_type);
        newSpent += cost;
        this.$store.commit('updateSkill', [s, "u", cost])
      }, this)
      this.buildSpent = newSpent;
    },

    check(e, skill){
      //E == false when array is empty
      if(e == false){
        //Remove Spell/Talent from known skills
        this.$store.commit('updateSkill', [skill.abilities_id, "-", 0])
        if(skill.ability_type == 4){
            this.decrementSkill(this.spell_purchase[skill.rank])
          }
          else if(skill.ability_type == 5){
            this.decrementSkill(this.talent_purchase[skill.rank])
          }
      }
      //2 possible cases to check if E != false
      else{
        if(e.includes(skill.abilities_id)){
          //Add Spell/Talent to known skills
          this.$store.commit('updateSkill', [skill.abilities_id, "+", 0])
          //Increment Spell/Talent Purchased at Rank with the following check
          if(skill.ability_type == 4){
            this.incrementSkill(this.spell_purchase[skill.rank])
          }
          else if(skill.ability_type == 5){
            this.incrementSkill(this.talent_purchase[skill.rank])
          }
        }
        else{
          //Removed Spell/Talent from known skills
          this.$store.commit('updateSkill', [skill.abilities_id, "-", 0])
          if(skill.ability_type == 4){
            this.decrementSkill(this.spell_purchase[skill.rank])
          }
          else if(skill.ability_type == 5){
            this.decrementSkill(this.talent_purchase[skill.rank])
          }
        }
      }
    },

    getBodyTotal(){
      let bodyTotal = 0
      this.classes.filter(c=> c!=null).forEach((c, index) => bodyTotal+=(this.class_list.find(cl=> cl.id == c).body * (this.class_stamina[index]==true?2:1)), this)
      return bodyTotal;
    },

    abilityTypeString(id){
      switch(id){
        case 1: return "Literacy"
        case 2: return "Passive"
        case 3: return "Periodic"
        case 4: return "Spell"
        case 5: return "Talent"
        case 6: return "Defense"
        case 7: return "Attack"
        case 8: return "Background"
        case 9: return "Attribute"
        case 10: return "Paragon"
        case 11: return "Lore"
        case 12: return "Hidden"
        case 13: return "Spell Purchase"
        case 14: return "Talent Purchase"
        case 15: return "Damage Proficiency"
        case 16: return "Prestige Skill"
        case 17: return "Prestige"
        case 18: return "Exalted"
        case 19: return "Finesse"
        case 20: return "Exalted Slot"
        case 21: return "Exalted Spell Purchase"
        case 22: return "Exalted Talent Purchase"
      }
    },

    populateOriginalCharacter(){
      var knownClasses = this.classes
      var list_alignments = this.list_alignments
      const alignment_by_class = this.alignment_by_class
      this.originalCharacter.known_classes.forEach(kc => {
        knownClasses[kc.class_index] = kc.class_field.id
        this.class_stamina[kc.class_index] = kc.stamina
        //the following expression finds the abilties by class ID for a particular entity name for
        //a particular class
        //alignment_by_class.filter(row=> (row['class_field']==kc.class_field.id)&&row['entity']==kc.entity)[0].id
        if(kc.entity!=""){
          try{
            list_alignments[kc.class_index] = (kc.entity == "" ? null : alignment_by_class.find(row=> (row['class_field']==kc.class_field.id)&&row['entity']==kc.entity).id)
          }catch(e){
            list_alignments[kc.class_index] = (kc.entity == "" ? null : alignment_by_class.find(row=> (row['class_field']==kc.class_field.id).id))
          }
        }
        else{
          this.updateAlignment(kc.class_index)
        }
      }, this);
      this.setLocalAlignments()

        //Populate the Known Skills
      var knownSkills = {}
      this.originalCharacter.abilities.forEach(ka => {
          knownSkills[ka.ability.abilities_id] = {quantity: ka.quantity, cost: ka.build_spent, skillfullness: ka.skillfullness}
          //Populate Purchased Spells
          if(ka.ability.ability_type == 13){
            this.spells_purchased_dict[ka.ability.rank] = ka.quantity
          }
          //Populate Purchased Talents
          else if(ka.ability.ability_type == 14){
            this.talents_purchased_dict[ka.ability.rank] = ka.quantity
          }
          //Populate Known Spellts
          else if(ka.ability.ability_type == 4 && ka.ability.rank != 5){
            this.known_spells_dict[ka.ability.rank].push(ka.ability.abilities_id)
          }
          //Populate Known Talents
          else if(ka.ability.ability_type == 5 && ka.ability.rank != 5){
            this.known_talents_dict[ka.ability.rank].push(ka.ability.abilities_id)
          }
          //Increment Paragon Count
          else if(ka.ability.ability_type == 10){
            this.paragonCount+=1
          }
        },this);
        this.$store.state.character.skills = knownSkills

        //Populate the Selected Master List Skills
        const masterSkills = this.originalCharacter.master_list_skills[0]
        this.selected_master_skills = [masterSkills.master_skill_1, masterSkills.master_skill_2, masterSkills.master_skill_3, masterSkills.master_skill_4, masterSkills.master_skill_5, masterSkills.master_skill_6, masterSkills.master_skill_7, masterSkills.master_skill_8, masterSkills.master_skill_9]
        
        //Populate the Selected Weapon Prof Skills
        const weaponProfs = this.originalCharacter.weapon_proficiency_skills[0]        
        this.$store.commit('updateSelectedWeaponProf', [weaponProfs.weapon_proficiency_skill_1, weaponProfs.weapon_proficiency_skill_2, weaponProfs.weapon_proficiency_skill_3, weaponProfs.weapon_proficiency_skill_4, weaponProfs.weapon_proficiency_skill_5, weaponProfs.weapon_proficiency_skill_6, weaponProfs.weapon_proficiency_skill_7, weaponProfs.weapon_proficiency_skill_8, weaponProfs.weapon_proficiency_skill_9, weaponProfs.weapon_proficiency_skill_10, weaponProfs.weapon_proficiency_skill_11, weaponProfs.weapon_proficiency_skill_12])

        this.characterMemo = this.originalCharacter.character_memo
        //Refresh Everything
        this.classUpdate()
        //we can update a single alignment to update all alignments
        this.updateSpecificAlignment()
        this.updateShownSkills();
        this.updateShownMasterSkills();
        this.removeUnmatchedSkills();
    },

    resetToOriginalCharacter(){
      this.clearSkillsClasses()
      this.populateOriginalCharacter()
    },

    clearOrReset(){
      if(this.operation=='clear'){
        this.clearSkillsClasses()
      }
      else if(this.operation=='reset'){
        this.resetToOriginalCharacter()
      }
      this.dialog = false
    },

    allowNextPurchase(item){
      let cost = this.getNthCost(item.abilities_id, this.getTotalPurchased(item.abilities_id)+1, item.cost, this.getModifier(item.abilities_id, this.classes), item.rank, item.ability_type)
      let buildRemaining = this.getBuildTotal()-this.buildSpent
      let moreParagonsThanClasses = this.paragonCount>=this.classes.slice(1,5).filter(c=> c!=null).length      
      if (cost == "N/A"){
        return false
      }
      else if(item.ability_type == 1 && this.$store.state.character.literacy==item.abilities_id){
        return false
      }
      else if (item.ability_type == 10 && moreParagonsThanClasses){
        return false
      }
      else if (item.ability_type == 10){
        let paragonsByClass = this.getKnownParagonsByClass()
        let item_class = this.skills_by_class.find(abc=>abc.ability == item.abilities_id).class_field
        if(paragonsByClass[item_class]["skills"].length >= paragonsByClass[item_class]["quantity"]){
          return false
        }
      }
      return cost > buildRemaining ? false : true 
    },

    allowNextPurchaseMarshal(item){
      let cost = this.getNthCost(item.abilities_id, this.getTotalPurchased(item.abilities_id)+1, item.cost, this.getModifier(item.abilities_id, this.classes), item.rank, item.ability_type)
      //let buildRemaining = this.getBuildTotal()-this.buildSpent
      //let moreParagonsThanClasses = this.paragonCount>=this.classes.slice(1,5).filter(c=> c!=null).length      
      if (cost == "N/A"){
        return false
      }
      else if(item.ability_type == 1 && this.$store.state.character.literacy==item.abilities_id){
        return false
      }
      else {
        return true
      }
      /*
      else if (item.ability_type == 10 && moreParagonsThanClasses){
        return false
      }
      else if (item.ability_type == 10){
        let paragonsByClass = this.getKnownParagonsByClass()
        let item_class = this.skills_by_class.find(abc=>abc.ability == item.abilities_id).class_field
        if(paragonsByClass[item_class]["skills"].length >= paragonsByClass[item_class]["quantity"]){
          return false
        }
      }
      return cost > buildRemaining ? false : true 
      */
    },

    getAlignments(){
      let dedup = (items) => items.filter((v,i) => items.indexOf(v) === i)
      return (dedup(this.$store.state.character.alignments.filter(a=>a!=null&&a!="")))
    },

    setLocalAlignments(){
      let alignmentList = this.getAlignments()
      const t = this.$store.state.character.skills;
      let setLight=false
      let setDark=false
      let setOrder=false
      let setChaos=false
      Object.keys(t).forEach(function(s){
        let skill = this.skills.find(skill => skill.abilities_id == s)
        if(skill.name == "Devotion (Light)"){
          setLight = true
        }
        else if(skill.name == "Devotion (Dark)"){
          setDark = true
        }
        else if(skill.name == "Devotion (Order)"){
          setOrder = true
        }
        else if(skill.name == "Devotion (Chaos)"){
          setChaos = true
        }
      }, this)
      this.is_light = (alignmentList.includes("Light") || setLight)
      this.is_dark = (alignmentList.includes("Dark") || setDark)
      this.is_order = (alignmentList.includes("Order") || setOrder)
      this.is_chaos = (alignmentList.includes("Chaos") || setChaos)
    },

    async submitUpdateCharacter(timeoutCheck=false){
      let zip = (a, b, c) => a.map((k, i) => [k, b[i], c[i]]);
      let arraysEqual = function(a, b) {
          if (a === b) return true;
          if (a == null || b == null) return false;
          if (a.length !== b.length) return false;

          for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
          }
          return true;
        };

      var skillChange = {}
      var originalClasses = [null, null, null, null, null, null, null, null, null, null, null, null]
      this.originalCharacter.known_classes.forEach(kc => originalClasses[kc.class_index] = [kc.class_field.id, (kc.entity==""?null:kc.entity), kc.stamina])
      originalClasses.forEach(function(oc, index) {
                                this[index] = oc == null ? [null, null, null]: oc
                              }, originalClasses)
      let currentClasses = zip(this.$store.state.character.classes, this.$store.state.character.entityList, this.class_stamina)

      let changedClasses = []
      currentClasses.forEach(function(value, index){
        let c = {class_index: index, class_id: value[0], entity:value[1], stamina: value[2]}
        if(originalClasses[c.class_index][0] != c.class_id || originalClasses[c.class_index][1]!=c.entity || originalClasses[c.class_index][2]!=c.stamina){
          changedClasses.push(c)
        }
      })      
      var newSkills = this.$store.state.character.skills
      var currentSkills = {}
      this.originalCharacter.abilities.forEach(c=> currentSkills[c.ability.abilities_id.toString()] = {quantity: c.quantity, cost: c.build_spent, skillfullness: c.skillfullness})
      
      let new_ids = Object.keys(newSkills)
      let current_ids = Object.keys(currentSkills)

      //find removed skills
      //difference = A.filter(x => !B.includes(x));
      //intersection = A.filter(x => B.includes(x));
      //symmetrical_difference = A.filter(x => !B.includes(x)).concat(B.filter(x => !A.includes(x)))

      let added_skills = new_ids.filter(x => !current_ids.includes(x))
      let removed_skills = current_ids.filter(x => !new_ids.includes(x))
      let same_skills = current_ids.filter(x => new_ids.includes(x))

      //add skills
      added_skills.forEach(skill_id => skillChange[skill_id] = {
          quantity: newSkills[skill_id].quantity, 
          cost: newSkills[skill_id].cost,
          skillfullness: newSkills[skill_id].skillfullness,
          operation: "add"
          })
      //remove skills
      removed_skills.forEach(skill_id => skillChange[skill_id] = {
        quantity: 0,
        cost: 0,
        skillfullness: false,
        operation: "remove"
        })
      //update changed skills
      same_skills.forEach(function(skill_id){
        let operation = (newSkills[skill_id].quantity!=currentSkills[skill_id].quantity || newSkills[skill_id].cost!=currentSkills[skill_id].cost || newSkills[skill_id].skillfullness!=currentSkills[skill_id].skillfullness )? "update" : ""
        if (operation=="update"){
          skillChange[skill_id] = 
          {
            quantity: newSkills[skill_id].quantity,
            cost: newSkills[skill_id].cost,
            skillfullness: newSkills[skill_id].skillfullness,
            operation: operation
          }
        }
      })

      //check to see if we have any skills changed

      //check for changes to known master skills
      let current_master_skills = Object.values(this.originalCharacter.master_list_skills[0]).slice(2)
      let new_master_skills = this.$store.state.character.selectedMasterSkills

      //check for changes to selected weapon profs
      let current_weapon_profs = Object.values(this.originalCharacter.weapon_proficiency_skills[0]).slice(2)
      let new_weapon_profs = this.$store.state.character.selectedWeaponProf

      //update gold prior to building payload
      this.updateGold()
      //building the update payload
      let data = {
        cardNumber: this.originalCharacter.cardnumber,
        past_event_count: this.eventsAttended,
        character: this.originalCharacter.id,
        player: this.originalCharacter.player,
        classes: changedClasses,
        skills: skillChange,
        event: this.currentlySelectedEvent.event,
        selectedMasterSkills: (!arraysEqual(current_master_skills, new_master_skills))?new_master_skills: null,
        selectedWeaponProf: (!arraysEqual(current_weapon_profs, new_weapon_profs))?new_weapon_profs: null,
        eventInfo: this.eventUpdate,
        alignments:{
          light: this.is_light,
          dark: this.is_dark,
          order: this.is_order,
          chaos: this.is_chaos
        },
        increment_event: this.increment_event
      }
      this.overlay = true
      var that = this
      if(timeoutCheck==false){
        setTimeout(function(){
            if(that.overlay==true){
            that.overlay=false; 
            console.log("Emergency Timeout Used"); 
            //that.submitUpdateCharacter(timeoutCheck=true)
          }
          }, 15000)
      }
      //Create a fake delay for character submission to improve UX
      setTimeout(() => {
           if(timeoutCheck==false){
            this.overlay = false
          }
        }, 3500)

      var response = await staffService.updateCharacter(data, this.$store.state.token);
      if(response.status == 404) {  
        if(timeoutCheck==true){
          this.characterSubmitStatus = 200
          this.$notify({
                    group: 'authentication',
                    title: `Submitted`,
                    text: `Your character update has been successfully submitted.`,
                    type: 'success'
                })
          this.$router.push({name: "CharacterView", params: {cardNumber: this.cardNumber}})
        }
        else{
          this.characterSubmitStatus = 404
          this.$notify({
                    group: 'authentication',
                    title: `Error`,
                    text: `A character with this card number does not exist.`,
                    type: 'error'
                })
        }
      }
      else if(response.status != 200){
        this.characterSubmitStatus = 500
        this.$notify({
                    group: 'authentication',
                    title: `Error`,
                    text: `There appears to be an issue on the backend.  Please try reloading and submitting again.`,
                    type: 'error'
                })
      }
      else{
        this.characterSubmitStatus = 200
        this.$notify({
                    group: 'authentication',
                    title: `Submitted`,
                    text: `Your character update has been successfully submitted.`,
                    type: 'success'
                })
        this.$router.push({name: "CharacterView", params: {cardNumber: this.cardNumber}})
      }
    }
  }
}

</script>

<style scoped>
.v-stepper__content {
  padding: 0 !important;
}
/*
.v-list, .v-input__slot{
  background: #722220 !important
}
*/
</style>