<template>
  <v-layout column>
    <v-layout row no-gutters class="pl-6 ml-2 mb-2 pb-2">
      <div class="invert--text friendly-text">
        <h1>Character Submissions</h1>
        <h4>Manage characters awaiting approval.</h4>
      </div>
    </v-layout>

    <v-card 
      color="primary" 
      height="100%"
      class="rounded-xl pa-6 mx-6 mb-6">
        <v-container fluid>
            <v-row>
              <v-col 
                cols="12">
                <v-data-table
                  :headers="headers"
                  :items="this.CharactersList"
                  :items-per-page="10"
                  :search="search"
                  class="tertiary elevation-2"
                  no-data-text="No Characters Waiting for Approval"
                >
                <template v-slot:top>
                  <v-text-field
                  outlined
                  color="contrast"
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  label="Search"
                  clearable
                ></v-text-field>
                </template>
                 <template v-slot:item.last_updated_date="{ item }">
                  {{new Date(item.last_updated_date).toLocaleString()}}
                </template>
                <template v-slot:item.buttons="{ item }">
                  <v-btn
                    color="secondary"
                    @click="viewCharacter(item.cardnumber)"
                    >View</v-btn>
                  <v-btn
                    color="accent"
                    :disabled="!allowApproval(item)"
                    @click="approveCharacter(item.cardnumber)"
                    >Approve</v-btn>
                </template>
                </v-data-table>
                </v-col>
            </v-row>
      </v-container>
    </v-card>
  </v-layout>
</template>

<script>
  import userService from '@/services/userService';
  import staffService from '@/services/staffService';

  export default {
    name: 'CharacterApproval',
    data: () => ({
        CharactersList: [],
        search: '',
        headers: [
        {
          text: 'Character Card Number',
          align: 'start',
          value: 'cardnumber',
          sortable: true,
        },
        {text: 'Last Updated', value: 'last_updated_date', filterable: false, sortable: true},
        { text: 'Name', value: 'name', filterable:true, sortable: false},
        { text: 'Race', value: 'race', filterable:false, sortable: false},
        { text: 'Faith', value: 'religion', filterable:false, sortable: false },
        { text: '', value: 'buttons', filterable:false }
      ],
    }),
    methods: {

        async refreshUser() {
          const user = JSON.parse(this.$store.state.authenticatedUser)

          if(!user.id) {
            this.$router.replace('logout')
          }

          const response = await userService.fetchUser(user.id, this.$store.state.token)
          if(response.status != 200) {
            this.$router.replace('logout')
          } else {
            const refreshedUser = response.data
            this.$store.commit('updateSessionUser', refreshedUser)
          }
        },
        async getUnapprovedCharacters() {
          var response = await staffService.getUnapprovedCharacters();
          if(response.status != 200) {
              console.log("Failed Call");
          }
          else{
            this.CharactersList = response.data.sort(function(a,b){
              var keyA = a.cardnumber.slice(-1)
              var keyB = b.cardnumber.slice(-1)
              if(keyA > keyB) return 1
              else if(keyB > keyA) return -1
              else return 0
            });
          }
        },

        viewCharacter(cardNumber){
          let routeData = this.$router.resolve({name: 'CharacterView', params: {cardNumber: cardNumber}});
          window.open(routeData.href, '_blank');
        },

        async approveCharacter(cardNumber){
          var response = await staffService.approveCharacter(cardNumber);
          if(response.status != 200) {
              console.log("Failed Call");
              this.$notify({
                    group: 'authentication',
                    title: `Failed to approve character ${cardNumber}`,
                    text: `Please refresh your page and try again.  If this issues persists, contact support.`,
                    type: 'error'
                })
          }
          else{
            this.CharactersList = this.CharactersList.filter(c => c.cardnumber != cardNumber)
            this.$notify({
                    group: 'authentication',
                    title: `Approved`,
                    text: `Character ${cardNumber} has been successfully approved`,
                    type: 'success'
                })
          }
        },

        allowApproval(item){
          return item.requires_director_approval ? (this.user.player.is_staff || this.user.player.is_director || this.user.player.is_admin) : true
        }
        

    },
    computed: {
      user: {
        get() {
          return JSON.parse(this.$store.state.authenticatedUser)
        }
      },
    },
    mounted: function(){
        this.refreshUser()
        this.getUnapprovedCharacters()
    },
    watch: {

    }
  }
</script>