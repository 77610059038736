<template>
  <div style="padding:2em;">
    <v-main 
    >
      <About />
    </v-main>
    <v-snackbar
      v-model="alert"
      :color="alert.color"
      :timeout="alertTimeout"  
    >
      {{ alert.message }}
      <v-btn
        :dark="$vuetify.theme.dark"
        text
        @click="alert = false">
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import About from '@/components/home/About';

export default {
  name: 'Home',
  components: {
    About,
  },
  data() {
    return {
      user: false,
      alert: false,
      alertTimeout: 4000
    }
  }
}
</script>