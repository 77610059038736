import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/services/api'
import jwt_decode from 'jwt-decode'
//import { decode } from 'punycode'
import userService from '@/services/userService'


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
      token: localStorage.getItem('t'),
      refreshToken: localStorage.getItem('r'),
      authenticatedUser: localStorage.getItem('u'),
      userPlayerProfile: localStorage.getItem('profile'),
      globalDrawer: localStorage.getItem('drawer'),
      isLoggedIn: localStorage.getItem('isLoggedIn'),
      closedBanner: localStorage.getItem('closedBanner'),
      darkMode: localStorage.getItem('darkMode'),
      endpoints: {
        obtainToken: "auth/obtain_token/",
        refreshToken: "auth/refresh_token/",
      },
      character: {
        name: "",
        vanity_title: "",
        race: "",
        religion: "",
        classes: [1, null, null, null, null, null, null, null, null, null, null, null],
        entityList: [null, null, null, null, null, null, null, null, null, null, null, null],
        selectedMasterSkills: [null, null, null, null, null, null, null, null, null],
        selectedWeaponProf: [null, null, null, null, null, null, null, null, null, null, null, null],
        buildTotal: 0,
        buildSpent: 0,
        eventsAttended: 0,
        backstory: "",
        alignments: [],
        directorApprovalRequired: false,
        background: [null, null],
        literacy: null,
        skills: {},
        classStamina: [null, null, null, null, null, null, null, null, null, null, null, null],
        characterMemo: "",
        is_retired: false
      }
    },
    mutations: {
        updateSkill(state, [id, operation, cost=0]){
          switch(operation){
            case "+":
              if (Object.prototype.hasOwnProperty.call(state.character.skills, id)){
                state.character.skills[id].quantity += 1;
                state.character.skills[id].cost += cost;
              }
              else {
                state.character.skills[id] = {quantity: 1, cost: cost, skillfullness: 0};
              }
              break
            case "-":
              if (state.character.skills[id].quantity>0){
                state.character.skills[id].quantity -= 1;
                state.character.skills[id].cost -= cost;
              }
              if (state.character.skills[id].quantity==0){
                delete state.character.skills[id]
                break
              }
              return state.character.skills[id]
            case "x":
              if (Object.prototype.hasOwnProperty.call(state.character.skills, id)){
                delete state.character.skills[id]
              }
              break
            case "u":
              if (Object.prototype.hasOwnProperty.call(state.character.skills, id)){
                state.character.skills[id].cost = cost
              }
              break
            default:
              break
          }
        },

        updateBuildSpent(state, buildSpent){
          state.character.buildSpent = buildSpent
        },

        updateEntityList(state, entityList){
          state.character.entityList = entityList
        },

        updateStaminaList(state, staminaList){
          state.character.classStamina = staminaList
        },

        updateSelectedMasterSkills(state, selectedMasterSkills){
          state.character.selectedMasterSkills = selectedMasterSkills
        },

        updateSelectedWeaponProf(state, selectedWeaponProf){
          state.character.selectedWeaponProf = selectedWeaponProf
        },

        updateClasses(state, classes){
          state.character.classes = classes
        },

        updateAlignments(state, alignments){
          state.character.alignments = alignments
        },
        
        updateEventsAttended(state, eventTotal){
          state.character.eventsAttended = eventTotal
          const buildTotal = state.character.buildTotal

          if (eventTotal <= 3 && buildTotal <= 75){
            state.character.classes = state.character.classes.fill(null, 2)
          }
          else if (eventTotal <= 6 && buildTotal <= 100){
            state.character.classes.fill(null, 3)
          }
          else if (eventTotal <= 12 && buildTotal <= 130){
            state.character.classes.fill(null, 4)
          }
          else if (eventTotal <= 12 && buildTotal <= 150) {
            state.character.classes.fill(null, 5)
          }
          else if (eventTotal <= 18 && buildTotal <= 200) {
            state.character.classes.fill(null, 6)
          }
          else if (eventTotal <= 24 && buildTotal <= 250) {
            state.character.classes.fill(null, 7)
          }
        },

        updateBuildTotal(state, buildTotal){
          state.character.buildTotal = buildTotal
          const eventTotal = state.character.eventsAttended

          if (eventTotal <= 3 && buildTotal <= 75){
            state.character.classes = state.character.classes.fill(null, 2)
          }
          else if (eventTotal <= 6 && buildTotal <= 100){
            state.character.classes.fill(null, 3)
          }
          else if (eventTotal <= 12 && buildTotal <= 130){
            state.character.classes.fill(null, 4)
          }
          else if (eventTotal <= 12 && buildTotal <= 150) {
            state.character.classes.fill(null, 5)
          }
          else if (eventTotal <= 18 && buildTotal <= 200) {
            state.character.classes.fill(null, 6)
          }
          else if (eventTotal <= 24 && buildTotal <= 250) {
            state.character.classes.fill(null, 7)
          }
        },

        updateDarkMode(state, darkMode){
          localStorage.setItem('darkMode', darkMode)
          state.darkMode = darkMode
        },

        updateBanner(state, closed) {
          localStorage.setItem('closedBanner', closed)
          state.closedBanner = closed
        },

        updateDrawer(state, active) {
          localStorage.setItem('globalDrawer', active)
          state.globalDrawer = active
        },

        updateLoggedInState(state, loggedIn){
          localStorage.setItem('isLoggedIn', loggedIn)
          state.isLoggedIn = loggedIn
        },

        updateSessionUser(state, user) {
          localStorage.setItem('u', JSON.stringify(user))
          state.authenticatedUser = JSON.stringify(user)
        },

        updateSessionUserPlayerProfile(state, profile) {
          localStorage.setItem('profile', JSON.stringify(profile))
          state.userPlayerProfile = JSON.stringify(profile)
        },

        updateToken(state, tokens) {
          localStorage.setItem('t', tokens.access)
          state.token = tokens.access
    
          if(tokens.refresh) {
            localStorage.setItem('r', tokens.refresh)
            state.refreshToken = tokens.refresh
          }
        },

        revokeToken(state) {
          localStorage.removeItem('t')
          localStorage.removeItem('r')
          state.token = null
          state.refreshToken = null
        },
        
        revokeUser(state) {
          localStorage.removeItem('u')
          state.authenticatedUser = null
        },

        CLEARCHARACTER(state){
          Vue.set(state, "character", 
          {
            name: "",
            race: "",
            religion: "",
            classes: [1, null, null, null, null, null, null, null, null, null, null, null],
            entityList: [null, null, null, null, null, null, null, null, null, null, null, null],
            selectedMasterSkills: [null, null, null, null, null, null, null, null, null],
            selectedWeaponProf: [null, null, null, null, null, null, null, null, null, null, null, null],
            buildTotal: 0,
            buildSpent: 0,
            eventsAttended: 0,
            backstory: "",
            alignments: [],
            directorApprovalRequired: false,
            background: [null, null],
            literacy: null,
            skills: {},
            classStamina: [null, null, null, null, null, null, null, null, null, null, null, null],
            characterMemo: "",
            is_retired: false
          })
        }
      },
      actions: {
        clearCharacter(){
          this.commit('CLEARCHARACTER')
        },
        logout() {
          this.commit('revokeUser')
          this.commit('revokeToken')
          this.commit('updateLoggedInState', false)

        },
        refreshToken() {
          //eslint-disable-next-line no-console
          console.log("Refresh token provided")
          const data = {
            "refresh": this.state.refreshToken
          }
    
          return api.post(this.state.endpoints.refreshToken, data)
          .then((response => {
            this.commit('updateToken', response.data)
          }))
        },
        obtainToken(state, payload) {
          const data = {
            email: payload.email,
            password: payload.password
          }
    
          return api.post(this.state.endpoints.obtainToken, data)
          .then((response => {
            this.commit('updateToken', response.data)        
            this.commit('updateLoggedInState', true)
            return response
          }))
          .catch((e) => {
            return e.response
            })
        },
        async getUserFromToken(state, token) {
          const decodedToken = jwt_decode(token)
          if(decodedToken.user_id) {
            const response = await userService.fetchUser(decodedToken.user_id, token)
            return response.data
          } else { return null }
        },
        inspectToken() {
          const token = this.state.token
          if(token) {
            const decoded = jwt_decode(token)
            const exp = decoded.exp
            const orig_iat = decoded.orig_iat
            if(exp - (Date.now()/1000) < 1800 && (Date.now()/1000) - orig_iat < 628200) {
              this.dispatch('refreshToken')
            }
            else if (exp - (Date.now()/1000) < 1800) {
              // do not refresh
              // eslinet-disable-next-line no-console
              console.log("User needs to login again")
            }
            else {
              // user needs to log in again
              // eslint-disable-next-line no-console
              console.log("User needs to login again")
            }
          }
        }
      }
})

