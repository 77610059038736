<template>
  <v-layout column>
    <v-layout row no-gutters class="pl-6 ml-2 mb-2 pb-2">
      <div class="invert--text friendly-text">
        <h1>My Characters</h1>
        <h4>Manage my current characters.</h4>
      </div>
    </v-layout>

    <v-card color="primary" 
      height="100%"
      class="rounded-xl px-6 pb-6 mx-6 mb-6">
        <v-container fluid>
          <v-dialog
            ref="dialog"
            v-model="modal"
            persistent
            width="290px"
            >
              <v-card
              class="rounded-xl"
              color="primary">
                <v-card-title>Downloading</v-card-title>
                <v-card-subtitle><br>Your file is downloading.  This may take a moment...</v-card-subtitle>
                <v-card-text
                  class="d-flex flex-row flex-nowrap justify-end"
                >
                  <v-btn
                    color="primary darken-2"
                    @click="modal = false"
                  >
                    Close
                  </v-btn>
                </v-card-text>
              </v-card>                                
            </v-dialog>  
            <v-row>
                <v-col cols="12" 
                class="d-flex pa-2 flex-row flex-wrap justify-flex-start" >
                    <v-card v-for="character in this.MyCharactersList"
                        :key="character"  
                        :width="$vuetify.breakpoint.smAndDown?'100%':$vuetify.breakpoint.mdOnly?'45%':'30%'" 
                        :class="'pa-2 ma-4 ' +($vuetify.breakpoint.smAndDown?'ml-0 ':'') + 'rounded-xl flex-grow-0 flex-shrink-0'"
                        color="tertiary"
                        tile
                        >
                        <v-card-title><p>{{character.cardnumber}}</p> <v-spacer></v-spacer>
                        <p>{{character.name}}</p>
                          </v-card-title>
                        <v-card-subtitle>{{character.race}}<span class="float-right">{{character.build_spent}}/{{character.build_total}}</span></v-card-subtitle>
                        <v-card-text>
                            <v-row>
                                <v-tooltip bottom v-if="!character.is_approved">
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-icon 
                                        class="px-2" 
                                        color="red" 
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        mdi-eye-off</v-icon>
                                  </template>
                                  <span>Your character is pending approval by a Card Marshal.</span>
                                </v-tooltip>
                                <v-tooltip bottom v-else>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-icon 
                                        class="px-2" 
                                        color="green" 
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        mdi-eye-check</v-icon>
                                  </template>
                                  <span>Your character has been approved.</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="character.requires_director_approval  && !character.is_approved">
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-icon 
                                        class="px-2" 
                                        color="yellow" 
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        mdi-crown</v-icon>
                                  </template>
                                  <span>Your character requires Director level approval.  Please be patient, there is only one James.</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="character.is_retired">
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-icon 
                                        class="px-2" 
                                        color="grey" 
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        mdi-fish</v-icon>
                                  </template>
                                  <span>This character is currently retired.</span>
                                </v-tooltip>
                            </v-row>
                            <v-row class="d-flex flex-row justify-end">
                              <v-speed-dial
                                v-model="fab[MyCharactersList.indexOf(character)]"
                                :bottom="true"
                                :right="true"
                                :left="false"
                                :top="false"
                                direction="left"
                                :open-on-hover="!$vuetify.breakpoint.mobile"
                                transition="scale-transition"
                              >
                                <template v-slot:activator>
                                  <v-btn
                                    v-model="fab"
                                    color="primary darken-2"
                                    :dark="$vuetify.theme.dark"
                                    fab
                                  >
                                    <v-icon v-if="fab[MyCharactersList.indexOf(character)]">
                                      mdi-close
                                    </v-icon>
                                    <v-icon v-else>
                                      mdi-dots-vertical
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      fab
                                      :dark="$vuetify.theme.dark"
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                      color="primary darken-2"
                                      v-show="!character.is_approved" 
                                      @click="editCharacter(character.cardnumber)" 
                                    >
                                      <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Click this button to edit your character card.  Only avaiable before approval.</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                      fab
                                      :dark="$vuetify.theme.dark"
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                      color="primary darken-2"
                                      @click="downloadCharacter(character.cardnumber)"
                                    >
                                      <v-icon>mdi-download</v-icon>
                                    </v-btn>
                                    </template>
                                    <span>Click this button to download your character card as a Watermarked PDF.</span>
                                  </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                  fab
                                  :dark="$vuetify.theme.dark"
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                  color="primary darken-2"
                                  @click="characterWizard(character.cardnumber)" 
                                >
                                  <v-icon class="pb-2">mdi-wizard-hat</v-icon>
                                </v-btn>
                                </template>
                                <span>Click this button to view your character card in the wizard.  <br>You cannot submit, but can tweak your card however you wish.</span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn 
                                  fab
                                  :dark="$vuetify.theme.dark"
                                  small
                                  @click="viewCharacter(character.cardnumber)" 
                                  v-bind="attrs"
                                  v-on="on"
                                  color="primary darken-2"
                                    >
                                    <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Click this button to view your character.</span>
                              </v-tooltip>
                            </v-speed-dial>
                            </v-row>
                        </v-card-text>
                      </v-card>
                    <v-card 
                      v-if="CharactersRemaining>0"
                      :width="$vuetify.breakpoint.smAndDown?'100%':$vuetify.breakpoint.mdOnly?'45%':'30%'" 
                      :class="'pa-2 ma-4 ' +($vuetify.breakpoint.smAndDown?'ml-0 ':'') + 'rounded-xl flex-grow-0 flex-shrink-0'"
                      color="tertiary"
                      tile>
                        <v-card-title>Create New Character</v-card-title>
                        <v-subheader>You can make up to {{CharactersRemaining}} more new characters.</v-subheader>
                        <v-card-text class="d-flex flex-row justify-end">
                            <v-speed-dial
                              v-model="fabNewCharacter"
                              :bottom="true"
                              :right="true"
                              :left="false"
                              :top="false"
                              direction="left"
                              :open-on-hover="!$vuetify.breakpoint.mobile"
                              transition="scale-transition"
                            >
                              <template v-slot:activator>
                                <v-btn
                                  v-model="fab"
                                  color="primary darken-2"
                                  :dark="$vuetify.theme.dark"
                                  fab
                                >
                                  <v-icon v-if="fabNewCharacter">
                                    mdi-close
                                  </v-icon>
                                  <v-icon v-else>
                                    mdi-plus
                                  </v-icon>
                                </v-btn>
                              </template>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn 
                                    small
                                    fab
                                    :dark="$vuetify.theme.dark"
                                    class="primary darken-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="makeNewCharacter()" 
                                    :disabled="CharactersRemaining<1"
                                    >
                                      <v-icon>mdi-account-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Create a brand new character.</span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    small
                                    fab
                                    :dark="$vuetify.theme.dark"
                                    class="primary darken-2"
                                    v-bind="attrs"
                                    v-on="on" 
                                    @click="makeExistingCharacter()" 
                                    :disabled="CharactersRemaining<1"
                                    >
                                    <v-icon>mdi-account-star</v-icon>
                                  </v-btn>
                                </template>
                                <span>Create a custom character.  Only use this when instructed to.</span>
                              </v-tooltip>
                            </v-speed-dial>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
      </v-container>
    </v-card>
  </v-layout>
</template>

<script>
  import userService from '@/services/userService';
  import playerService from '@/services/playerService';
  import cardService from '@/services/cardService';

  export default {
    name: 'MyCharacters',
    data: () => ({
        MyCharactersList: Array,
        CharactersRemaining: Number,
        fab: [false, false, false, false, false, false, false, false, false, false],
        fabNewCharacter: false,
        modal: false
    }),
    methods: {

        makeNewCharacter(){
          this.$router.push({"name":"cardbuilder"})
        },

        makeExistingCharacter(){
          this.$router.push({"name":"cardbuildercustom"})
        },

        editCharacter(cardNumber){
          this.$router.push({name: "cardedit", params: { cardNumber: cardNumber } })
        },

        characterWizard(cardNumber){
          this.$router.push({name: "cardeditnosubmission", params: { cardNumber: cardNumber } })
        },

        async downloadCharacter(cardNumber) {
            this.modal = true
            await cardService.printCard(cardNumber)
                          .then(response => {
                            let blob = new Blob([response.data], {type: 'application/pdf'});
                            let link = document.createElement('a');
                            let objectURL = window.URL.createObjectURL(blob);
                            link.href = objectURL;
                            link.target = '_self';
                            link.download = cardNumber+".pdf";
                            (document.body || document.documentElement).appendChild(link);
                            link.click();
                            setTimeout(()=>{
                                window.URL.revokeObjectURL(objectURL);
                                link.remove();
                              }, 100);
                            })
        },
        async refreshUser() {
          const user = JSON.parse(this.$store.state.authenticatedUser)

          if(!user.id) {
            this.$router.replace('logout')
          }

          const response = await userService.fetchUser(user.id, this.$store.state.token)
          if(response.status != 200) {
            this.$router.replace('logout')
          } else {
            const refreshedUser = response.data
            this.$store.commit('updateSessionUser', refreshedUser)
          }
        },
        async getMyCharacters() {
            var response = await playerService.getPlayerCharacters();
            if(response.status != 200) {
                console.log("Failed Call");
            }
            else{
                this.MyCharactersList = response.data.sort(function(a,b){
                  var keyA = a.cardnumber.slice(-1)
                  var keyB = b.cardnumber.slice(-1)
                  if(keyA > keyB) return 1
                  else if(keyB > keyA) return -1
                  else return 0
                });
                this.CharactersRemaining = 10 - this.MyCharactersList.length
            }
        },
        viewCharacter(cardNumber){
          let routeData = this.$router.resolve({name: 'CharacterView', params: {cardNumber: cardNumber}});
          window.open(routeData.href, '_blank');
        }
        

    },
    computed: {
    },
    mounted: function(){
        this.refreshUser()
        this.getMyCharacters()
    },
    watch: {

    }
  }
</script>

<style scoped>
 
  .v-card__text, .v-card__title {
    word-break: normal;
  }
</style>