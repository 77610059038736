import api from '@/services/api'
import store from '@/store'

export default {
    async getPlayerCharacters(){
      try{
          const token = store.state.token
          const player_id = JSON.parse(store.state.userPlayerProfile).id
          var baseURL
          baseURL = `/characters_by_player/${player_id}/`
          return await api.get(baseURL, {headers:{"Authorization": `Bearer ${token}`}})
      }catch (e) {
          if (e.response.status == 401) {
              // eslint-disable-next-line 
              console.error("Invalid token")
              // eslint-disable-next-line 
              console.log("Token:" + token);
              store.dispatch('refreshToken')
                .then(() => {
                  const token = store.state.token
                  this.getPlayerCharacters(token)
                })
            }
          // eslint-disable-next-line no-console
          console.error(e);
          return e.response
      }
    },

    getPlayerRemainingCharacterCount(){
      store.dispatch('getUserFromToken', store.state.token)
                  .then((user) => {
                    if(user.is_verified){
                      store.commit('updateSessionUserPlayerProfile', user.player)
                    }
                  })
      return JSON.parse(store.state.userPlayerProfile).card_suffix_list.length
    },

    async updatePlayer(playerId, data) {
      try {
        const token = store.state.token
        return await api.patch(`/players/${playerId}/`,
          data, {
            headers: {
              "Authorization": `Bearer ${token}`
            }
          }
        )
      } catch (e) {
        if (e.status == 401) {
          store.dispatch('refreshToken')
            .then(() => {
              const token = store.state.token
              this.updatePlayer(playerId, token, data)
            })
        }
      return e
      }
  },
  }