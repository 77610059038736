<template>
  <v-card
    color="primary"
  >
  <!--<v-overlay v-if="isNewCharacter==false" :value="(originalCharacter.cardnumber==Undefined)">
      <v-progress-circular
        indeterminate
        size="128"
      ></v-progress-circular>
    </v-overlay> -->
  <v-container>
    <v-form 
      ref="CharacterForm"
      class="pa-4"
    >
      <v-row align="center">
        <v-col cols="12">
          <v-text-field 
            v-model="name" 
            label="Character Name"
            :dark="$vuetify.theme.dark"
            required
            outlined
            color="contrast"
            shaped
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="6">
          <v-combobox
          v-model="race"
          label="Race"
          :items="race_list"
          item-text="name"
          required
          :dark="$vuetify.theme.dark"
          dense
          outlined
          color="contrast"
          item-color="contrast"
        ></v-combobox>
          <v-combobox
          v-model="religion"
          label="Religion - Optional"
          :items="religion_list"
          required
          clearable
          :dark="$vuetify.theme.dark"
          dense
          outlined
          color="contrast"
          item-color="contrast"
        ></v-combobox>
        </v-col>

        <v-col cols="3">
          <v-text-field 
            v-model="buildTotal" 
            label="Build Total"
            required
            :maxlength="5"
            :disabled="buildDisabled"
            outlined
            :dark="$vuetify.theme.dark"
            color="contrast"
            shaped
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field 
            v-model="eventsAttended" 
            label="Events Attended" 
            required
            :maxlength="5"
            :disabled="eventsDisabled"
            outlined
            color="contrast"
            :dark="$vuetify.theme.dark"
            shaped
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>

    </v-container>
  </v-card>
</template>

<script>



export default {
  components: {},
  data: () => ({
    overlay: true,
    buildDisabled: true,
    eventsDisabled: true,
  }),
  props: {
    race_list: Array,
    religion_list: Array,
    originalCharacter: Object,
    isNewCharacter: Boolean,
    isWizard: Boolean,
    startingBuild: Number,
  },
  watch: {
    originalCharacter: function(character){
      if(!this.isNewCharacter){
        this.name = character.name
        this.buildTotal = character.build_total.toString()
        this.eventsAttended = character.past_event_count.toString()
        this.race = character.race
        this.religion = character.religion=="" ? null : character.religion
      }
    }
  },
  mounted: function(){
    if(this.isWizard){
      this.buildDisabled = false
    }
    else if(this.isNewCharacter && this.startingBuild===undefined){
      this.buildDisabled = false
      this.eventsDisabled = false
    }
    if(this.startingBuild !== undefined){
      this.buildTotal = this.startingBuild.toString()
    }
  },
  computed: {
    buildTotal: {
            get: function(){
                var t = this.$store.state.character.buildTotal
                return (isNaN(t) ? 0 : t)
            },
            set: function(value){
                this.$store.commit("updateBuildTotal", parseInt(value.replace(/\D/g, '').substring(0, 5)));
            }
        },
    eventsAttended: {
            get: function(){
                var t = this.$store.state.character.eventsAttended
                return (isNaN(t) ? 0 : t)
            },
            set: function(value){
                this.$store.commit("updateEventsAttended", parseInt(value.replace(/\D/g, '').substring(0, 5)));
            }
        },
    race: {
            get: function(){
                return this.$store.state.character.race;
            },
            set: function(value){
                this.$store.state.character.race = value;
                this.$store.state.character.directorApprovalRequired = (!this.race_list.includes(this.race) || !(this.religion == null || this.religion == '' || this.religion_list.includes(this.religion)))
            }
        },
    religion: {
            get: function(){
                return this.$store.state.character.religion;
            },
            set: function(value){
                this.$store.state.character.religion = value;
                this.$store.state.character.directorApprovalRequired = (!this.race_list.includes(this.race) || !(this.religion == null || this.religion == '' || this.religion_list.includes(this.religion)))
            }
        },
    name: {
            get: function(){
                return this.$store.state.character.name;
            },
            set: function(value){
                this.$store.state.character.name = value;
            }
        }
  },

  methods: {
    openDashboard() {
      this.$router.replace(this.next)
    },
    displayNotification(group, title, text, type) {
      this.$notify({
                  group: group,
                  title: title,
                  text: text,
                  type: type
                })
    }
  }
}
</script>