<template>
  <v-layout column>
    <v-layout row  no-gutters class="pl-6 ml-2 mb-2 pb-2">
      <div class="invert--text friendly-text">
        <h1>Event Management</h1>
        <h4>Add and Update Events</h4>
      </div>
    </v-layout>

    <v-card
      color="primary"  
      height="100%"
      class="rounded-xl pa-6 mx-6 mb-6">
        <v-container fluid>
          <v-row>
            <v-col cols="4">
              <v-switch
              :label="eventsSwitch?'Create New Event':'Update Existing Event'"
              v-model="eventsSwitch"
              color="contrast"  
              >
            </v-switch>
            </v-col>
            <v-col cols="4">

            </v-col>
            <v-col cols="4">
            <v-select
            v-if="!eventsSwitch"
            :items="eventsList"
            item-text="name"
            item-value="id"
            color="contrast"
            item-color=contrast
            v-model="selectedEvent"
            label="Event to Update"
            shaped
            outlined
            :dark="$vuetify.theme.dark"
          >
          </v-select>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row row-wrap justify-space-between">
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateString"
                    :label="eventsSwitch ? 'Select a Date Range': 'Updated Event Date Range'"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    shaped
                    outlined
                    color="contrast"
                    :dark="$vuetify.theme.dark"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-spacer></v-spacer>
                <vc-date-picker
                  class="tertiary" 
                  :is-dark="$vuetify.theme.dark"
                  v-model="date" 
                  mode="date"
                  is-range
                  is-expanded
                  color="primary"
                  :attributes="eventsAttributeList" 
                  :timezone="timezone" />
                  <div class="d-flex flex-row row-nowrap" :style="{background: $vuetify.theme.themes[theme].background}">
                    <v-btn
                    color="primary"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="$refs.dialog.save(date)"
                  >
                    OK
                  </v-btn>
                  </div>
          </v-dialog>
          <v-select
            v-model="eventType"
            :items="eventTypeList"
            label="Event Type"
            shaped
            outlined
            :dark="$vuetify.theme.dark"
            item-text="name"
            item-value="value"
            color="contrast"
            item-color="contrast"
            >
          </v-select>    
          </v-row>
          
          <v-row class="d-flex flex-row row-wrap justify-space-between">
            <v-textarea
              shaped
              outlined
              label="Name"
              color="contrast"
              :dark="$vuetify.theme.dark"
              v-model="eventName">
            </v-textarea>
            <v-textarea
              shaped
              outlined
              label="Notes"
              color="contrast"
              :dark="$vuetify.theme.dark"
              v-model="eventNotes">
            </v-textarea>
          </v-row> 
          <v-row>
            <v-btn
              :disabled="disableSubmit()"
              @click="eventsSwitch ? createEvent() : updateEvent()"
              >
              {{eventsSwitch ? 'Create Event' : 'Update Event'}}
            </v-btn>
          </v-row>
      </v-container>
    </v-card>
  </v-layout>
</template>

<script>
  import userService from '@/services/userService';
  import staffService from '@/services/staffService';

  export default {
    name: 'Events',
    data: () => ({
      timezone: "",
      setDate: null,
      modal: false,
      eventsList: [],
      eventsAttributeList: [],
      eventName: '',
      eventNotes: '',
      eventID: Number,
      eventsSwitch: true,
      dateString: '',
      eventType: Number,
      event: null,
      eventTypeList: [
        {
          name: "Normal Event",
          value: 1
        },
        {
          name: "Special Event",
          value: 2
        }
      ]
    }),
    computed: {
      theme(){
        return (this.$vuetify.theme.dark) ? 'dark' : 'light'
      },

      date:{
        get: function(){
          return this.setDate
        },
        set: function(value){
          this.setDate=value
          this.dateString = [new Date(value.start).toISOString().slice(0,10), new Date(value.end).toISOString().slice(0,10)].join(" to ")
          //this.checkDate()
        }
      },
      selectedEvent: {
        get: function(){
          return this.event
        },
        set: function(value){
          this.event=value
          this.checkDate()
        },
      }
    },
    methods: {
        async refreshUser() {
          const user = JSON.parse(this.$store.state.authenticatedUser)

          if(!user.id) {
            this.$router.replace('logout')
          }

          const response = await userService.fetchUser(user.id, this.$store.state.token)
          if(response.status != 200) {
            this.$router.replace('logout')
          } else {
            const refreshedUser = response.data
            this.$store.commit('updateSessionUser', refreshedUser)
          }
        },

        async getEvents() {
          var response = await staffService.getEventsList();
          if(response.status != 200) {
              console.log("Failed Call");
          }
          else{
            this.eventsList = response.data.filter(e=> !["Before Times", "Character Creation"].includes(e.name))

            this.eventsAttributeList = response.data.filter(e=> !["Before Times", "Character Creation"].includes(e.name)).map(
            function(e){
              return {
                key: e.id,
                highlight: "secondary",
                dates: {
                  start: new Date(e.date), 
                  end: e.end_date==null? new Date(e.date): new Date(e.end_date)
                  },
                popover: {
                  label: e.name
                }
              }
            } , this)
          }
        },

        checkDate(){
          let event = this.eventsList.find(e=> e.id == this.selectedEvent)
          if(event != undefined){
            this.eventID = this.selectedEvent
            this.eventName = event.name
            this.eventNotes = event.notes
            this.eventType = event.event_type
            this.date = {start: event.date, end: event.end_date}
          }
          else{
            this.eventID = null
            this.eventName = ''
            this.eventNotes = ''
            this.newDate = null
          }
        },

        async createEvent(){
          let eventData = {
            date: new Date(this.date.start).toISOString(),
            end_date: new Date(this.date.end).toISOString(),
            name: this.eventName,
            notes: this.eventNotes,
            event_type: this.eventType
          }
          var response = await staffService.createNewEvent(eventData);
          if(response.status != 201) {
              console.log("Failed Call");
              this.$notify({
                    group: 'authentication',
                    title: `Failed to create event`,
                    text: `Please refresh your page and try again.  If this issues persists, contact support.`,
                    type: 'error'
                })
          }
          else{
            this.eventID = response.data.id
            this.eventsSwitch = false
            this.getEvents()
            this.$notify({
                    group: 'authentication',
                    title: `Event created`,
                    text: `New event ${this.eventName} has been successfully created`,
                    type: 'success'
                })
          }
        },

        async updateEvent(){
          let eventData = {
            date: new Date(this.date.start).toISOString(),
            end_date: new Date(this.date.end).toISOString(),
            name: this.eventName,
            notes: this.eventNotes,
            event_type: this.eventType
          }
          var response = await staffService.updateExistingEvent(eventData, this.eventID);
          if(response.status != 200) {
              console.log("Failed Call");
              this.$notify({
                    group: 'authentication',
                    title: `Failed to update event`,
                    text: `Please refresh your page and try again.  If this issues persists, contact support.`,
                    type: 'error'
                })
          }
          else{
            this.getEvents()
            this.date = {start: response.data.date, end: response.data.end_date}
            this.eventName = response.data.name
            this.eventNotes = response.data.notes
            this.eventID = response.data.id
            this.eventType = response.data.event_type
            this.newDate = null
            this.eventsSwitch = false

            this.$notify({
                    group: 'authentication',
                    title: `Event updated`,
                    text: `Existing event ${this.eventName} has been successfully updated`,
                    type: 'success'
                })
          }
        },

        disableSubmit(){
          return !(this.date!=null && this.eventName!='' && this.eventType!=null)
        },

        getEventColor(eventType){
          switch(eventType){
            case 1:
              return "blue";
            case 2:
              return "red";
            default:
              return "green";
          }
        }



    },
    mounted: function(){
        this.refreshUser()
        this.getEvents()
        this.date = null
        this.dateString = ''
    }
  }
</script>