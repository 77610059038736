<template>
<v-layout>
  <v-container>
    <h1 class="friendly-text invert--text" align="center">Knight Realms - Character Viewer</h1>
    <v-card class="tertiary rounded-xl">
      <v-card-title class="justify-center friendly-text font-weight-bold">
        <span v-if="Character.is_retired">RETIRED:&nbsp; </span>{{cardNumber}} - {{Character.vanity_title.length > 0 ? Character.vanity_title+" " : ""}}{{Character.name}}
      </v-card-title>
      <v-card-subtitle class="d-flex flex-column flex-nowrap justify-space-between text--primary" >
        <div class="d-flex flex-row flex-nowrap justify-space-between">
          <div class="d-flex flex-column flex-nowrap justify-start">
            <div class="mr-6"><strong>Build Total: </strong>{{Character.build_total}}</div>
            <div class="mr-6"><strong>Build Spent: </strong>{{Character.build_spent}}</div>
            <div class="mr-6"><strong>Roleplay Points: </strong>{{Character.role_play_points}}</div>            
            <div class="mr-6"><strong>Service Points: </strong>{{ServicePointsTotal}}</div>   
            <div class="mr-6"><strong>Events Attended: </strong>{{Character.past_event_count}}</div>            
          </div>
          <div class="d-flex flex-column flex-nowrap mr-5">
            <div>
              <v-icon large>mdi-heart</v-icon>
              &nbsp; <strong>Body: </strong>{{this.getBodyTotal()}}
            </div>
            <div>
              <v-icon large>mdi-sack</v-icon>
              &nbsp; <strong>Gold: </strong>{{Character.gold}} gp
            </div>
          </div>
        </div>

        <div class="d-flex flex-column flex-nowrap justify-start">
          <div class="mr-6"><strong>Race: </strong>{{Character.race}}</div>
          <div class="mr-6"><strong>Faith: </strong>{{Character.religion == null ? "None" : Character.religion}}</div>
        </div>
        <div class="d-flex flex-row flex-nowrap justify-start">
          <div><strong>Alignments: </strong>{{listAlignments()}}</div>
        </div>
      </v-card-subtitle>
      <v-card-text class="text--primary">
        <h2 class="friendly-text">Known Classes:</h2>
        <v-container class="d-flex flex-column flex-nowrap">
          <div class="d-flex flex-row flex-wrap justify-start">
            <div 
            v-for="kc in Character.known_classes.filter(kc=>[1,2,3,4].includes(kc.class_index))" 
            :key="kc"
            class="flex-grow-0 flex-shrink-0 mr-6"
            ><strong>{{class_names_list[kc.class_index]}}:</strong> {{classString(kc)}}</div>
          </div>
          <div v-if="Character.known_classes.filter(kc=>[5,6,7].includes(kc.class_index)).length > 0" class="d-flex flex-row flex-wrap justify-start">
            <div 
            v-for="kc in Character.known_classes.filter(kc=>[5,6,7].includes(kc.class_index))" 
            :key="kc"
            class="flex-grow-0 flex-shrink-0 mr-6"
            ><strong>{{class_names_list[kc.class_index]}}:</strong> {{classString(kc)}}</div>
          </div>
          <div v-if="Character.known_classes.filter(kc=>[8,9].includes(kc.class_index)).length > 0" class="d-flex flex-row flex-wrap justify-start">
            <div 
            v-for="kc in Character.known_classes.filter(kc=>[8,9].includes(kc.class_index))" 
            :key="kc"
            class="flex-grow-0 flex-shrink-0 mr-6"
            ><strong>{{class_names_list[kc.class_index]}}:</strong> {{classString(kc)}}<v-spacer></v-spacer></div>
          </div>
          <div v-if="Character.known_classes.filter(kc=>[10,11].includes(kc.class_index)).length > 0" class="d-flex flex-row flex-wrap justify-start">
            <div 
            v-for="kc in Character.known_classes.filter(kc=>[10,11].includes(kc.class_index))" 
            :key="kc"
            class="flex-grow-0 flex-shrink-0 mr-6"
            ><strong>{{class_names_list[kc.class_index]}}:</strong> {{classString(kc)}}<v-spacer></v-spacer></div>
          </div>
        </v-container>
        <h2 class="friendly-text">Background Skills:</h2>
        <v-container>
          <div v-for="background in [Character.background_1, Character.background_2]"
            :key="background"
            >{{background.name}}-{{background.skill.name}}
          </div>
        </v-container>

        <h2 
          class="friendly-text"
          v-if="Character.abilities.filter(s=>s.ability.ability_type==15).length > 0"
          >Proficiencies:</h2>
        <v-container>
          <div v-for="weaponProf in Character.abilities.filter(s=>s.ability.ability_type==15)"
          :key="weaponProf">
            {{weaponProf.ability.name}}: +{{weaponProf.quantity}}
          </div>
        </v-container>
        <v-spacer></v-spacer>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header color="primary" class="friendly-text"><strong>Skills</strong></v-expansion-panel-header>
            <v-expansion-panel-content color="primary">
              <v-data-table
                :headers="skills_headers"
                :items="Character.abilities.filter(s=>![4,5,13,14].includes(s.ability.ability_type))"
                :items-per-page="10"
                :sort-by.sync="sortBy"
                class="elevation-1"
                >
                <template v-slot:item.ability.ability_type="{ item }">
                  {{ abilityTypeString(skills.find(s=>s.abilities_id == item.ability.abilities_id).ability_type) }}
                </template>
                </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            v-if="Character.abilities.filter(s=>s.ability.ability_type==4).length > 0"
            >
            <v-expansion-panel-header color="primary" class="friendly-text"><strong>Spells</strong></v-expansion-panel-header>
            <v-expansion-panel-content color="primary">
              <v-data-table
                :headers="spells_talents_headers"
                :items="Character.abilities.filter(s=>s.ability.ability_type==4)"
                :items-per-page="10"
                :sort-by.sync="sortBy"
                class="elevation-1"
              >
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            v-if="Character.abilities.filter(s=>s.ability.ability_type==5).length > 0"
            >
            <v-expansion-panel-header color="primary" class="friendly-text"><strong>Talents</strong></v-expansion-panel-header>
            <v-expansion-panel-content color="primary">
              <v-data-table
                :headers="spells_talents_headers"
                :items="Character.abilities.filter(s=>s.ability.ability_type==5)"
                :items-per-page="10"
                :sort-by.sync="sortBy"
                class="elevation-1"
              >
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header color="primary" class="friendly-text"><strong>Character History</strong></v-expansion-panel-header>
            <v-expansion-panel-content color="primary">
              <v-data-table
                :headers="attendance_headers"
                :items="CharacterAttendance"
                :items-per-page="10"
                class="elevation-1"
              >
                <template v-slot:item.build_total="{ item }">
                  {{ getTotalEventBuild(item) }}
                </template>
                <template v-slot:item.base_build="{ item }">
                  {{ getBuildAmount(item, "base") }}
                </template>
                <template v-slot:item.bought_build="{ item }">
                  {{ getBuildAmount(item, "bought") }}
                </template>
                <template v-slot:item.npc_build="{ item }">
                  {{ getBuildAmount(item, "npc") }}
                </template>
                <template v-slot:item.lifestyle_build="{ item }">
                  {{ getBuildAmount(item, "lifestyle") }}
                </template>
                <template v-slot:item.marshal_build="{ item }">
                  {{ getBuildAmount(item, "marshal") }}
                </template>
                <template v-slot:item.death_build="{ item }">
                  {{ getBuildAmount(item, "death") }}
                </template>
                <template v-slot:item.other_build="{ item }">
                  {{ getBuildAmount(item, "other") }}
                </template>
                <template v-slot:item.gold="{ item }">
                  {{ getBuildAmount(item, "gold") }}
                </template>
                <template v-slot:item.roleplay_points="{ item }">
                  {{ getBuildAmount(item, "roleplay") }}
                </template>
                <template v-slot:item.card_updated="{ item }">
                  <v-simple-checkbox
                    disabled
                    v-model="item.card_updated"
                  >
                  </v-simple-checkbox>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header color="primary" class="friendly-text"><strong>Player History</strong></v-expansion-panel-header>
            <v-expansion-panel-content color="primary">
              <v-data-table
                :headers="service_points_headers"
                :items="ServicePoints"
                :items-per-page="10"
                class="elevation-1"
              >
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <br>
        <div 
          class="d-flex flex-column"
          >
          <v-btn
            v-show="user.player.id == Character.player"
            :disabled="CharacterMemo == Character.character_memo || CharacterMemo.length>200"
            @click="submitNotesUpdate('character_memo', CharacterMemo)"
            >
            Update Character Memo
          </v-btn>
          <br>
          <v-textarea
            outlined
            color="contrast"
            shaped
            counter="200"
            label="Character Memo"
            :disabled="user.player.id != Character.player"
            v-model="CharacterMemo">
          </v-textarea>
        </div>
        <div 
          class="d-flex flex-column"
          >
          <v-btn
            v-show="user.player.is_marshal || user.player.is_staff || user.player.is_director || user.player.is_admin"
            :disabled="STNotes == Character.st_notes"
            @click="submitNotesUpdate('st_notes', STNotes)"
            >
            Update Storyteller Notes
          </v-btn>
          <br>
          <v-textarea
            v-show="user.player.is_marshal || user.player.is_staff || user.player.is_director || user.player.is_admin"
            outlined
            color="contrast"
            shaped
            counter="200"
            label="Storyteller Notes"
            :disabled="!(user.player.is_marshal || user.player.is_staff || user.player.is_director || user.player.is_admin)"
            v-model="STNotes">
          </v-textarea>
        </div>
        <div 
          class="d-flex flex-column"
          >
          <v-btn
            v-show="user.player.is_marshal || user.player.is_staff || user.player.is_director || user.player.is_admin"
            :disabled="CardTeamNotes == Character.card_team_notes"
            @click="submitNotesUpdate('card_team_notes', CardTeamNotes)"
            >
            Update Card Team Notes
          </v-btn>
          <br>
          <v-textarea
            outlined
            color="contrast"
            shaped
            counter="200"
            label="Card Team Notes"
            :disabled="!(user.player.is_marshal || user.player.is_staff || user.player.is_director || user.player.is_admin)"
            v-model="CardTeamNotes">
          </v-textarea>
        </div>
        <div 
          class="d-flex flex-column"
          >
          <v-btn
            v-show="user.player.is_marshal || user.player.is_staff || user.player.is_director || user.player.is_admin"
            :disabled="OtherNotes == Character.other_notes"
            @click="submitNotesUpdate('other_notes', OtherNotes)"
            >
            Update Other Notes
          </v-btn>
          <br>
          <v-textarea
            outlined
            color="contrast"
            shaped
            counter="200"
            label="Other Notes"
            :disabled="!(user.player.is_marshal || user.player.is_staff || user.player.is_director || user.player.is_admin)"
            v-model="OtherNotes">
          </v-textarea>
          <v-card-title>Character Backstory</v-card-title>
          <v-card-text>
            <p 
            v-for="line in Backstory"
            :key="line">
            {{line}}
            </p>
          </v-card-text>
        </div>
  
      </v-card-text>
        </v-card>
  </v-container>
</v-layout>
</template>

<script>

import cardService from '@/services/cardService';

export default {
  name: 'CharacterView',
  
  data: () => ({
    class_list: Array,
    skills: Array,
    skills_by_class: Array,
    background_list: Array,
    race_list: Array,
    religion_list: Array,
    alignment_by_class: Array,
    Character: Object,
    CharacterAttendance: Array,
    CharacterMemo: String,
    OtherNotes: String,
    STNotes: String,
    CardTeamNotes: String,
    Backstory: Array,
    BuildHistory: Array,
    ServicePoints: Array,
    ServicePointsTotal: 0,
    sortBy: 'ability.name',
    class_names_list: ["Common List", "Lower List 1", "Lower List 2", "Lower List 3", "Lower List 4", "Master List 1", "Master List 2", "Master List 3", "Exalted List 1", "Legendary List 1", "Exalted List 2", "Legendary List 2"],
    skills_headers: [
          {
            text: 'Skill Name',
            align: 'start',
            sortable: true,
            value: 'ability.name',
          },
          { text: 'Quantity', 
            sortable: true,
            value: 'quantity' },
          { text: 'Ability Type', 
            sortable: true,
            value: 'ability.ability_type'},
          { text: 'Build Spent', 
            sortable: true,
            value: 'build_spent' },
        ],
    spells_talents_headers: [
          {
            text: 'Skill Name',
            align: 'start',
            sortable: true,
            value: 'ability.name',
          },
          { text: 'Rank', sortable:true, value: 'ability.rank' },
        ],

    attendance_headers: [
      {
        text: 'Event', align: 'start', value: 'event.name'
      },
      {
        text: 'Level', align: 'start', value: 'level'
      },
      {
        text: 'Earned Build', align: 'start', value: 'build_total'
      },
      {
        text: 'Gold', align: 'start', value: 'gold'
      },
      {
        text: 'Roleplay Points', align: 'start', value: 'roleplay_points'
      },
      {
        text: 'Updated?', align: 'start', value: 'card_updated'
      }
      /*
      {
        text: 'Base Build', align: 'start', value: 'base_build'
      },
      {
        text: 'Bought Build', align: 'start', value: 'bought_build'
      },
      {
        text: 'NPC Shift Build', align: 'start', value: 'npc_build'
      },
      {
        text: 'Lifestyle Build', align: 'start', value: 'lifestyle_build'
      },
      {
        text: 'Marshal Build', align: 'start', value: 'marshal_build'
      },
      {
        text: 'Other Build', align: 'start', value: 'other_build'
      },
      {
        text: 'Death Build', align: 'start', value: 'death_build'
      }
      */
    ],

    service_points_headers: [
      {
        text: 'Event', align: 'start', value: 'event.name'
      },
      {
        text: 'Earned SP', align: 'start', value: 'points'
      }
    ]

  }),
  props: {
    cardNumber: String
    },

  watch: {
  },
  computed: {
    loggedIn: {
      get() {
        return JSON.parse(this.$store.state.isLoggedIn)
      }
    },
    user: {
      get() {
        return JSON.parse(this.$store.state.authenticatedUser)
      }
    }
  },
  mounted: function(){
    this.fetchFormData();
  },
  methods: {

    fetchFormData(){
      /*
      this.fetchClassesData()
      this.fetchSkillsByClassData()
      this.fetchAlignmentsByClassData()
      this.fetchBackgrounds()
      this.fetchReligions()
      this.fetchRaces()
      this.updatePlayerProfile()
      */
      this.fetchSkillsData()
      this.fetchCharacterCard()
      this.fetchCharacterAttendance()
    },
    async fetchClassesData(){
      var response = await cardService.getClasses(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.class_list = response.data;
      }
    },
    async fetchSkillsData(){
      var response = await cardService.getSkills(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.skills = response.data;
      }
    },
    async fetchSkillsByClassData(){
      var response = await cardService.getSkillsByClass(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.skills_by_class = response.data;
      }
    },
    async fetchAlignmentsByClassData(){
      var response = await cardService.getAlignmentByClass(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.alignment_by_class = response.data;
      }
    },
    async fetchBackgrounds(){
      var response = await cardService.getBackgrounds(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.background_list = response.data;
      }
    },
    async fetchRaces(){
      var response = await cardService.getRaces(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.race_list = response.data.map(r=>r["name"]);
      }
    },
    async fetchReligions(){
      var response = await cardService.getReligions(this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.religion_list = response.data.map(r=>r["name"]);
      }
    },
    async updatePlayerProfile(){
      this.$store.dispatch('getUserFromToken', this.$store.state.token)
                .then((user) => {
                  if(user.is_verified){
                    this.$store.commit('updateSessionUserPlayerProfile', user.player)
                  }
                })
    },
    async fetchCharacterCard(){
      var response = await cardService.getCharacterCard(this.cardNumber, this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.Character = response.data;
        this.CharacterMemo = this.Character.character_memo
        this.OtherNotes = this.Character.other_notes
        this.STNotes = this.Character.st_notes
        this.CardTeamNotes = this.Character.card_team_notes
        this.Backstory = (((this.Character.backstory == null) || (this.Character.backstory.length<1))? ['No Backstory Provided'] : this.Character.backstory.split("\n"))
        this.fetchPlayerServicePoints()
        this.fetchCharacterBuildPoints()
      }
    },

    classString(kc){
      return kc.class_field.name + (kc.entity?`- ${kc.entity}`:"")
    },

    async fetchCharacterAttendance(){
      var response = await cardService.getCharacterAttendance(this.cardNumber, this.$store.state.token);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.CharacterAttendance = response.data.sort((a, b) => b.event.id > a.event.id && 1 || -1)
      }
    },

    async fetchPlayerServicePoints(){
      var response = await cardService.getPlayerServicePoints(this.Character.player);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.ServicePoints = response.data.sort((a, b) => b.event.id > a.event.id && 1 || -1);
        this.ServicePoints.forEach(s => this.ServicePointsTotal+= s.points, this)
      }
    },

    async fetchCharacterBuildPoints(){
      var response = await cardService.getCharacterBuildPoints(this.Character.cardnumber);
      if(response.status != 200) {
        console.log("Failed Call");
      }
      else{
        this.BuildHistory = response.data
      }
    },

    listAlignments(){
      let alignments = [(this.Character.is_light? "Light":null), (this.Character.is_dark? "Dark":null), (this.Character.is_order? "Order":null), (this.Character.is_chaos? "Chaos":null)]
      return alignments.filter(a=> a!=null).join(", ")
    },

    abilityTypeString(id){
      switch(id){
        case 1: return "Literacy"
        case 2: return "Passive"
        case 3: return "Periodic"
        case 4: return "Spell"
        case 5: return "Talent"
        case 6: return "Defense"
        case 7: return "Attack"
        case 8: return "Background"
        case 9: return "Attribute"
        case 10: return "Paragon"
        case 11: return "Lore"
        case 12: return "Hidden"
        case 13: return "Spell Purchase"
        case 14: return "Talent Purchase"
        case 15: return "Damage Proficiency"
        case 16: return "Prestige Skill"
        case 17: return "Prestige"
        case 18: return "Exalted"
        case 19: return "Finesse"
      }
    },

    getBodyTotal(){
      let bodyTotal = 0
      this.Character.known_classes.forEach(kc => bodyTotal += (kc.class_field.body * (kc.stamina?2:1)))
      return bodyTotal
    },

    getBuildAmount(item, buildField){
      let b = this.BuildHistory.find(b=> b.event == item.event.id)
      if (b===undefined){
        return 0
      }
      else{
        switch(buildField){
          case "base":
            return b.base
          case "npc":
            return b.npc_shift
          case "lifestyle":
            return b.lifestyle
          case "marshal":
            return b.marshal
          case "death":
            return b.death
          case "bought":
            return b.bought
          case "other":
            return b.other
          case "gold":
            return b.gold
          case "roleplay":
            return b.roleplay_points
          default:
            return 0
        }
      }
    },

    getServicePointsAmount(item){
      let s= this.ServicePoints.find(s=> s.event.id == item.event.id)
      return s === undefined ? 0 : s.points
    },

    getTotalEventBuild(item){
      let buildTotal = 0
      const buildFields = ["base", "npc", "lifestyle", "marshal", "death", "bought", "other"]
      buildFields.forEach(x => buildTotal+=this.getBuildAmount(item, x), this)
      return buildTotal
    },

    async submitNotesUpdate(noteType, note){
      let data = {
            note: note,
            note_type: noteType
          }
          var response = await cardService.updateCharacterNotes(data, this.Character.cardnumber);
          if(response.status != 200) {
              console.log("Failed Call");
              this.$notify({
                    group: 'authentication',
                    title: `Failed to updated character ${this.Character.cardnumber}`,
                    text: `Please refresh your page and try again.  If this issues persists, contact support.`,
                    type: 'error'
                })
          }
          else{
            this.$notify({
                    group: 'authentication',
                    title: `Approved`,
                    text: `Notes for Character ${this.Character.cardnumber} has been successfully updated`,
                    type: 'success'
                })
            switch(noteType){
              case "character_memo":
                this.Character.character_memo = this.CharacterMemo
                break
              case "st_notes":
                this.Character.st_notes = this.STNotes
                break
              case "card_team_notes":
                this.Character.card_team_notes = this.CardTeamNotes
                break
              case "other_notes":
                this.Character.other_notes = this.OtherNotes
                break
              default:
                break
            }
          }
    }
  }
}

</script>