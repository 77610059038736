import Vue from 'vue';
//import Vuetify from 'vuetify/lib';
import Vuetify from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import KRLogo from '../components/KRLogo'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values:{
      krlogo:{
        component: KRLogo
      }
    }
  },
  theme: {
    dark: localStorage.getItem('darkMode') === 'dark',
    options: { 
      customProperties: true,
      variations: true 
    },
    themes: {
      light: {
        primary: "#5599ee",
        secondary: '#d172b9',
        tertiary: '#E0E0E0',
        accent: '74cfa0',
        invert: "#ffffff",
        contrast: "#000000",
      },
      dark: {
        primary: "#8e2a28",
        secondary: "#0094b6",
        tertiary: "#1f1f1f",
        accent: "#00b64b",
        menu: "#661e1c",
        invert: "#000000",
        contrast: "#ffffff"
      }
      /*
      dark: {
        //primary: "#8e2a28",
        primary: "#623412",
        secondary: "#0094b6",
        tertiary: "#ebd5b3",
        //tertiary: "#1f1f1f",
        accent: "#00b64b",
        menu: "#661e1c",
        invert: "#000000",
        contrast: "#ffffff"
      }
      */
    }
  }
});
