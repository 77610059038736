<template>
  <v-navigation-drawer
    style="max-height: 100vh;"
    temporary
    :dark="$vuetify.theme.dark"
    right
    fixed
    width="256px"
    color="primary"
    v-if="drawer"
    v-model="drawer"
    :key="loggedIn"
    class="friendly-text"
  >

  <v-btn
    icon
    absolute
    right
    color= "#ffffff"
    @click="drawer = null"
  >
    <v-icon>close</v-icon>
  </v-btn>
  <v-container class="pa-5">
    <v-layout 
      column 
      align-center 
      justify-center
    >

      <h2 v-if="!user" class="title">EODRA</h2>
      <h2 v-else class="title">{{ user.name }}</h2>

      <p v-if="!user" class="body-2 text-center">The Realm of Souls</p>
      <p v-else class="body-2 text-center">{{ user.email }}</p>

         </v-layout>
  </v-container>

  <!-- User is authenticated -->
    <v-list dense nav>
      <v-subheader>Account</v-subheader>
      <v-list-item-group
        color="primary"
        v-if="user"
      >
        <v-list-item
          @click="changeDashboardComponent(link.dashboardComponent)"
          v-for="(link, i) in accountLinks.filter(l=> !l.external)"
          :key="i"
          :color="activeLinkColor"
          :to="link.target"
        >
          <v-list-item-icon :color="activeLinkColor">
            <v-icon v-text="link.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="link.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="(link, i) in accountLinks.filter(l=> l.external)"
          :key="i"
          :color="activeLinkColor"
          :href="link.target"
          :to="link.route"
          target="_blank"
        >
          <v-list-item-icon :color="activeLinkColor">
            <v-icon v-text="link.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="link.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <!-- User is NOT authenticated -->
    <v-list-item-group
      color="primary"
      v-else
    >
        <v-list-item
          @click="$eventHub.$emit('registration')"
          :color="activeLinkColor"
        >
          <v-list-item-icon color="primary">
            <v-icon>mdi-shield-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sign In</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <!-- Staff is authenticated -->
    <div
    v-if="user">
      <v-subheader>Player</v-subheader>
      <v-list-item-group
        color="primary"
      >
        <v-list-item
          @click="changeDashboardComponent(link.dashboardComponent)"
          v-for="(link, i) in playerLinks"
          :key="i"
          :color="activeLinkColor"
          :to="link.target"
        >
          <v-list-item-icon :color="activeLinkColor">
            <v-icon v-text="link.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="link.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
       <v-subheader
       v-if="user.player.is_marshal || user.player.is_staff || user.player.is_director || user.player.is_admin">Marshal</v-subheader>
      <v-list-item-group
        color="primary"
        v-if="user.player.is_marshal || user.player.is_staff || user.player.is_director || user.player.is_admin"
      >
        <v-list-item
          @click="changeDashboardComponent(link.dashboardComponent)"
          v-for="(link, i) in marshalLinks"
          :key="i"
          :color="activeLinkColor"
          :to="link.target"
        >
          <v-list-item-icon :color="activeLinkColor">
            <v-icon v-text="link.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="link.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-subheader
       v-if="user.player.is_staff || user.player.is_director || user.player.is_admin">Staff</v-subheader>
      <v-list-item-group
        color="primary"
        v-if="user.player.is_staff || user.player.is_director || user.player.is_admin"
      >
        <v-list-item
          @click="changeDashboardComponent(link.dashboardComponent)"
          v-for="(link, i) in staffLinks"
          :key="i"
          :color="activeLinkColor"
          :to="link.target"
        >
          <v-list-item-icon :color="activeLinkColor">
            <v-icon v-text="link.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="link.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-subheader
       v-if="user.player.is_director || user.player.is_admin">Director</v-subheader>
      <v-list-item-group
        color="primary"
        v-if="user.player.is_director || user.player.is_admin"
      >
        <v-list-item
          @click="changeDashboardComponent(link.dashboardComponent)"
          v-for="(link, i) in directorLinks"
          :key="i"
          :color="activeLinkColor"
          :to="link.target"
        >
          <v-list-item-icon :color="activeLinkColor">
            <v-icon v-text="link.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="link.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

    </div>
      <v-subheader>Social Media</v-subheader>
      <v-list-item-group 
        color="primary"
      >
        <v-list-item
          v-for="(link, i) in socialMediaLinks"
          :key="i"
          :color="activeLinkColor"
          :href="link.target"
          :to=link.route
          :target="link.external ? '_blank' : ''"
        >
          <v-list-item-icon color="primary">
            <v-icon v-text="link.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="link.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <v-subheader>Legal &amp; Docs</v-subheader>
      <v-list-item-group 
        color="primary"
      >
        <v-list-item
          v-for="(link, i) in legalLinks"
          :key="i"
          :color="activeLinkColor"
          :to="link.target"
        >
          <v-list-item-icon color="primary">
            <v-icon v-text="link.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="link.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    
    <v-layout column justify-center align-center>
      <v-btn
        v-if="!user"
        class="ma-4"
        
        color="menu"
        @click="$eventHub.$emit('registration')"
      >
      Sign Up
      </v-btn>

    <v-btn
        v-else
        class="ma-4"
        color="menu"
        to="/logout"
      >
      Logout
      </v-btn>
    <v-row
    class="d-flex flex-column flex-nowrap align-center justify-start">
    <v-switch
        v-model="$vuetify.theme.dark"
        label="Switch Theme"
        color="primary darken-3"
        persistent-hint
        @click="toggleGlobalTheme()"
      >
      </v-switch>
      <span>Theme: {{$vuetify.theme.dark?'Parchment': 'Steel'}}</span>
    </v-row>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      activeLinkColor: "#fff",
      accountLinks: [
        {
          "icon": "mdi-shield-account",
          "dashboardComponent": "account",
          "text": "My Account",
        },
        /*
        {
          "icon": "mdi-bug",
          "target": "https://larphive.atlassian.net/servicedesk/customer/portal",
          "text": "Support",
          "external": true
        },
        */
      ],
      playerLinks: [
        {
          "icon": "mdi-account-group",
          "target": "/characters",
          "text": "My Characters",
        },
        {
          "icon": "mdi-calendar",
          "target": "/calendar",
          "text": "Calendar of Events",
        }
      ],
      marshalLinks: [
        {
          "icon": "mdi-card-account-details-star",
          "target": "/marshal/approvals",
          "text": "Player Card Approvals"
        },
        {
          "icon": "mdi-clipboard-account",
          "target": "/marshal/update",
          "text": "Update Character Cards",
        },
        {
          "icon": "mdi-account-clock",
          "target": "/marshal/pending",
          "text": "Pending Updates",
        },
      ],
      staffLinks: [
        {
          "icon": "mdi-calendar-account",
          "target": "/staff/check_in",
          "text": "Check In",
        },
        {
          "icon": "mdi-calendar-plus",
          "target": "/staff/events",
          "text": "Event Management"
        },
        {
          "icon": "mdi-account-key",
          "target": "/staff/roles",
          "text": "Role Delegation",
        },
      ],
      directorLinks: [
      ],
      socialMediaLinks: [
        {
          "icon": "mdi-discord",
          "target": "https://discord.gg/vNCfXkgBr5",
          "text": "Discord",
          "external": true
        },
        {
          "icon": "mdi-facebook",
          "target": "https://www.facebook.com/KnightRealms/",
          "text": "Facebook",
          "external": true
        },
        {
          "icon": "mdi-twitter",
          "target": "https://twitter.com/knightrealms/",
          "text": "Twitter",
          "external": true
        }
      ],
      legalLinks: [
        {
          "icon": "mdi-shield-check",
          "target": "/terms-of-service",
          "text": "Terms of Service"
        },
        {
          "icon": "mdi-shield-lock",
          "target": "/privacy-policy",
          "text": "Privacy Policy"
        }
      ]
    }
  },
  methods: {
    changeDashboardComponent(targetComponent) {
      const user = JSON.parse(this.$store.state.authenticatedUser)
      if(!user.id) {
        this.$router.push("/logout")
        this.$store.commit('updateDrawer', false)
      } 
      else if (this.$route.name != 'dashboard') {
        this.$router.push("/")
        this.$router.replace("dashboard")
          .then(() => {
            this.$eventHub.$emit('changeDashboardComponent', targetComponent)
            this.$store.commit('updateDrawer', false)
          })
      } 
      else {
        this.$eventHub.$emit('changeDashboardComponent', targetComponent)
        this.$store.commit('updateDrawer', false)
      }
    },

    toggleGlobalTheme(){
      this.$store.commit('updateDarkMode', this.$vuetify.theme.dark ? 'dark' : 'light')
    }
  },
  computed: {
    loggedIn: {
      get() {
        return this.$store.state.isLoggedIn
      },
      set(value) {
        this.$store.commit('updateLoggedInState', value)
      }
    },
    user: {
      get() {
        return JSON.parse(this.$store.state.authenticatedUser)
      }
    },
    drawer: {
      get() {
        return this.$store.state.globalDrawer
      },
      set(val) {
        return this.$store.commit('updateDrawer', val)
      }
    }
  }
}
</script>
<style scoped>
.v-application .title, .v-application .body-2 {
  font-family: friendly !important;
}
</style>