<template>
<v-container>
  <v-layout column class="invert--text friendly-text">
    <v-row>
      <v-col>
        <h1>Welcome To EODRA!</h1>
        <p>
          This is the EODRA card database system.  EODRA is where all souls rest.
        </p>
      </v-col>
       <v-divider></v-divider>
    </v-row>
   
    <v-row 
      class="d-flex flex-xs-column-wrap flex-sm-column-wrap flex-md-column-wrap flex-row-wrap justify-space-around"
      >
      <v-card 
        class="rounded-xl elevation-5 flex-grow-0 flex-shrink-0 transparent"
        :width="$vuetify.breakpoint.smAndDown?'100%':$vuetify.breakpoint.mdOnly?'100%':'40%'" 
        color="primary">
        <v-card-title>Who will you be?™</v-card-title>
        <v-card-text>
          Knight Realms is a Live Action Role-Playing Game (also known as a "LARP") that involves both action 
          (live mock combat, using padded "boffer" weapons) and atmosphere (acting focusing on drama, personal 
          interactions, and other social activity).  We are based at our own land in Sparta, New Jersey, with a 
          player base that extends into the New York and Pennsylvania areas, and around the world. 
        </v-card-text>
        <v-card-actions row justify-space-between>
          <v-btn
            text
            color="contrast"
            class="ma-4 pa-4"
            target="_blank"
            href="http://www.knightrealms.com/"
          >
            Main Site
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="contrast"
            class="ma-4 pa-4"
            target="_blank"
            href="https://www.worldanvil.com/w/knight-realms3A-arawyn-gallion"
          >
            World Anvil
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    </v-layout>
  </v-container>
</template>
<script>


export default {
  data() {
    return {
    }
  },
  computed: {
  },
  components: {
    
  },
}
</script>

<style scoped>
/*@font-face{
            font-family: friendly;
            src: url("../../assets/ModernAntiqua-Regular.ttf") format("truetype");
            }*/

.module:nth-child(even) {
  flex-direction: row-reverse !important;
}

.module:nth-child(even) .flex {
  justify-items: space-between;
}

img {
  max-width: 50%;
}

p {
  line-height: 30px;
}

a {
  color: #fff !important;
  caret-color: #fff !important;
}

.transparent {
  opacity: 0.9;
}
</style>