<template>
  <v-app-bar
    color="primary flex-grow-0"
    :dark="$vuetify.theme.dark"
    short
  >
      <v-app-bar-nav-icon>
        <v-btn
                icon
                v-on:click="$router.replace('/')"
                >
              <v-icon x-large color="white">$krlogo</v-icon>
              </v-btn>
        </v-app-bar-nav-icon>
      <v-toolbar-title class="friendly-text font-weight-bold">Card System</v-toolbar-title>
    
    <v-spacer></v-spacer>

      <v-btn
        icon
        v-on:click="toggleDrawer()"
      >
        <v-icon>menu</v-icon>
      </v-btn>      


  </v-app-bar>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      user: JSON.parse(this.$store.state.authenticatedUser),
      loggedIn: false,
    }
  },
  methods: {
      toggleDrawer() {
        // eslint-disable-next-line
        const drawer = this.$store.state.globalDrawer
        if(drawer) {
          this.$store.commit('updateDrawer', false)
        } else {
          this.$store.commit('updateDrawer', true)
        }
      },
      sendOpenSignal(signal) {
        this.$eventHub.$emit(signal);
      },
      logout() {
        this.$store.dispatch('logout')
        this.$router.go()
      }
  },
  mounted() {
    const user = JSON.parse(this.$store.state.authenticatedUser)
    if(user) {
      this.loggedIn = true
    } else {
      this.loggedIn = false
    }
  },

  watch: {
    $route: function() {
      const user = JSON.parse(this.$store.state.authenticatedUser)
      if(user) {
        this.loggedIn = true
      } else {
        this.loggedIn = false
      }
    }
  }
}
</script>

<style scoped>

  .top {
    z-index: 99;
  }

</style>