<template>
  <v-container>
    <v-dialog 
    class="registration" 
    v-model="dialog" 
    max-width="900px" 
    overlay-color="primary"
  >
    <v-tabs 
      background-color="primary"
      centered
      slider-color="contrast"

    >
      <v-tab>Login</v-tab>
      <v-tab-item>
        <LoginForm :next="this.next" @closeDialog="closeDialog"/>
      </v-tab-item>
      <v-tab>Sign Up</v-tab>
      <v-tab-item background-color="primary">
        <RegistrationForm :next="this.next" @closeDialog="closeDialog" />
      </v-tab-item>
      <v-tab>Forgot My Password</v-tab>
      <v-tab-item background-color="primary">
        <ResetRequestForm :next="this.next" @closeDialog="closeDialog"/>
      </v-tab-item>
    </v-tabs>
    </v-dialog>
  </v-container>
</template>

<script>

import RegistrationForm from '@/components/registration/RegistrationForm';
import ResetRequestForm from '@/components/registration/ResetRequestForm';
import LoginForm from '@/components/registration/LoginForm';

export default {
  name: 'Registration',
  components: {
    RegistrationForm,
    LoginForm,
    ResetRequestForm
  },
  data() {
    return {
    step: 1,
    next: 'dashboard',
    user: false,
    dialog: false
    }
  },
  methods: {
    closeDialog(){
      this.dialog = false
    }
  },
  watch: {
    loggedIn: function() {
      this.dialog = false
    }
  },
  computed: {
    loggedIn: {
      get() {
        return JSON.parse(this.$store.state.isLoggedIn)
      }
    }
  },
  mounted() {
    this.$eventHub.$on('registration', data => {
      this.dialog = true;

      if(data.next) {
        this.next = data.next
      }
    })
  }
}

</script>

<style>
.v-stepper__content {
  padding: 0 !important;
}
</style>