<template>
  <v-card
    color="primary"
  >
  <v-container>
    <v-dialog
      v-model="dialog"
      width="500"
    >
        <v-card>
        <v-card-title class="invert--text friendly-text primary">
          Confirm Operation
        </v-card-title>

        <v-card-text>
          <p
            v-if="operation=='clear'"
          >
            Warning: This will clear all skills and classes selected for the current character.
            <br/>
            Are you sure you wish to proceed?
          </p>
          <p
            v-else-if="operation=='reset'"
          >
            Warning: This will clear all skills and classes selected for the current character, and will restore the builder to the originally loaded values.
            <br/>
            Are you sure you wish to proceed?
          </p>
          <p 
            v-else
            >
            Warning: You should not be seeing this text.
            <br/>
            If you are, please submit a support ticket with a screenshot and we will work to resolve this issue.
            <br/>
            Thanks.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="clearOrReset()"
            >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-form 
      ref="ClassSkillForm"
      class="pa-4"
    >
      <v-row class="mb-5 d-flex justify-space-between">
        <div>
          <v-btn
            class="ml-5"
            v-on:click="dialog=true; operation='clear';"
            >
            Clear
          </v-btn>
          <v-btn
            v-if="isWizard || !isNewCharacter"
            class="ml-5"
            v-on:click="dialog=true; operation='reset';"
            >
            Reset to Original
          </v-btn>
        </div>
      <div
        class="mr-5"
        >
        <v-icon
        large
        >mdi-heart</v-icon>
        &nbsp; Body: {{this.getBodyTotal()}}
      </div>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-row align="center">
          <v-autocomplete
          clearable
          @change="classUpdate(1)"
          v-model="classes[1]"
          label="Lower List #1"
          :items="lower_list"
          :key="counter"
          item-value="id"
          item-text='name'
          persistent-hint
          hint="Available from the start."
          required
          :dark="$vuetify.theme.dark"
          outlined
          color="contrast"
          item-color="contrast"
        ></v-autocomplete>
        
        <v-autocomplete
          clearable
          v-if="alignmentHasSelection(classes[1])"
          v-model="list_alignments[1]"
          @change="updateSpecificAlignment()"
          label="Source"
          :items="alignment_by_class.filter(row => row['class_field']==classes[1])"
          item-value="id"
          item-text="entity"
          required
          :dark="$vuetify.theme.dark"
          outlined
          color="contrast"
          item-color="contrast"
          ></v-autocomplete>
          
          </v-row>
          <v-row align="center">
          <v-autocomplete
          clearable
          v-if="this.$store.state.character.eventsAttended >= 3 || this.$store.state.character.buildTotal >= 75"
          @change="classUpdate(2)"
          v-model="classes[2]"
          label="Lower List #2"
          :items="lower_list"
          :key="counter"
          item-value="id"
          item-text='name'
          persistent-hint
          hint="Unlocked once a character has attended 3 events or has 75 build."
          :dark="$vuetify.theme.dark"
          outlined
          color="contrast"
          item-color="contrast"
        ></v-autocomplete>

        <v-autocomplete
          clearable
          v-if="alignmentHasSelection(classes[2])"
          v-model="list_alignments[2]"
          @change="updateSpecificAlignment()"
          label="Source"
          :items="alignment_by_class.filter(row => row['class_field']==classes[2])"
          item-value="id"
          item-text="entity"
          required
          :dark="$vuetify.theme.dark"
          outlined
          color="contrast"
          item-color="contrast"
          ></v-autocomplete>

          </v-row>
          <v-row align="center">
          <v-autocomplete
          clearable
          v-if="this.$store.state.character.eventsAttended >= 6 || this.$store.state.character.buildTotal >= 100"
          @change="classUpdate(3)"
          v-model="classes[3]"
          label="Lower List #3"
          :items="lower_list"
          :key="counter"
          item-value="id"
          item-text='name'
          persistent-hint
          hint="Unlocked once a character has attended 6 events or 100 build."
          :dark="$vuetify.theme.dark"
          outlined
          color="contrast"
          item-color="contrast"
        ></v-autocomplete>

        <v-autocomplete
          clearable
          v-if="alignmentHasSelection(classes[3])"
          v-model="list_alignments[3]"
          @change="updateSpecificAlignment()"
          label="Source"
          :items="alignment_by_class.filter(row => row['class_field']==classes[3])"
          item-value="id"
          item-text="entity"
          required
          :dark="$vuetify.theme.dark"
          outlined
          color="contrast"
          item-color="contrast"
          ></v-autocomplete>

          </v-row>
          <v-row align="center">
          <v-autocomplete
          clearable
          v-if="this.$store.state.character.eventsAttended >= 12 || this.$store.state.character.buildTotal >= 130"
          @change="classUpdate(4)"
          v-model="classes[4]"
          label="Lower List #4"
          :items="lower_list"
          :key="counter"
          item-value="id"
          item-text='name'
          persistent-hint
          hint="Unlocked once a character has attended 12 events or 130 build."
          :dark="$vuetify.theme.dark"
          outlined
          color="contrast"
          item-color="contrast"
        ></v-autocomplete>
        <v-autocomplete
          clearable
          v-if="alignmentHasSelection(classes[4])"
          v-model="list_alignments[4]"
          @change="updateSpecificAlignment()"
          label="Source"
          :items="alignment_by_class.filter(row => row['class_field']==classes[4])"
          item-value="id"
          item-text="entity"
          required
          :dark="$vuetify.theme.dark"
          outlined
          color="contrast"
          item-color="contrast"
          ></v-autocomplete>
          </v-row>
          <v-row align="center">
          <v-autocomplete
          clearable
          v-if="this.$store.state.character.eventsAttended >= 12 || this.$store.state.character.buildTotal >= 150"
          @change="classUpdate(5)"
          v-model="classes[5]"
          label="Master List #1"
          :items="master_list"
          :key="counter"
          item-value="id"
          item-text='name'
          persistent-hint
          hint="Unlocked once a character has attended 12 events or 150 build."
          :dark="$vuetify.theme.dark"
          outlined
          color="contrast"
          item-color="contrast"
        ></v-autocomplete>
          </v-row>
          <v-row align="center">
          <v-autocomplete
          clearable
          v-if="this.$store.state.character.eventsAttended >= 18 || this.$store.state.character.buildTotal >= 200"
          @change="classUpdate(6)"
          v-model="classes[6]"
          label="Master List #2"
          :items="master_list"
          :key="counter"
          item-value="id"
          item-text='name'
          persistent-hint
          hint="Unlocked once a character has attended 18 events or 200 build."
          :dark="$vuetify.theme.dark"
          outlined
          color="contrast"
          item-color="contrast"
        ></v-autocomplete>
          </v-row>
          <v-row align="center">
          <v-autocomplete
          clearable
          v-if="this.$store.state.character.eventsAttended >= 24 || this.$store.state.character.buildTotal >= 250"
          @change="classUpdate(7)"
          v-model="classes[7]"
          label="Master List #3"
          :items="master_list"
          :key="counter"
          item-value="id"
          item-text='name'
          persistent-hint
          hint="Unlocked once a character has attended 24 events or 250 build."
          :dark="$vuetify.theme.dark"
          outlined
          color="contrast"
          item-color="contrast"
        ></v-autocomplete>
          </v-row>
        </v-col>
        <v-col cols="9">
          <v-row class="mb-5">
            <v-col cols="5">
              <v-row class="ml-5 mb-1">
              <v-chip
              :class='{ "success" : this.$store.state.character.buildTotal - this.buildSpent > 0 , "error": this.$store.state.character.buildTotal - this.buildSpent < 0, "warning": this.$store.state.character.buildTotal - this.buildSpent == 0 }'
              >
                Build Remaining: {{this.getBuildTotal() - this.buildSpent}}
            </v-chip>
            </v-row>
            <v-row class="ml-5">
            <v-chip
              :class='{ "success" : this.$store.state.character.buildTotal - this.buildSpent > 0 , "error": this.$store.state.character.buildTotal - this.buildSpent < 0, "warning": this.$store.state.character.buildTotal - this.buildSpent == 0}'
              >
                Build Spent: {{this.buildSpent}}
              </v-chip>
            </v-row>
            </v-col>
            <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            :dark="$vuetify.theme.dark"
            dense
            outlined
            color="contrast"
            shaped
            hide-details
            width=20vh
          ></v-text-field>
            </v-col>
          </v-row>
          <v-expansion-panels
            :multiple="true"
            focusable
            inset>
            <!-- Expansion Panel for Common and Lower List Skills-->
            <v-expansion-panel>
              <v-expansion-panel-header color="tertiary">Common List Skills</v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- Base Skills Table-->
                <v-data-table
                :headers="headers"
                :items="shown_common"
                :key="counter"
                hide-default-footer
                :items-per-page="count"
                :search="search"
                fixed-header
                no-data-text="Please Select Your Class(es)"
                >
                <template v-slot:item.quantity="{ item }">
                  {{getKnownQuantity(item)}}
                </template>
                <template v-slot:item.purchased="{ item }">
                  <v-icon
                  medium 
                  :disabled="getKnownQuantity(item)<1"
                  @click="decrementSkill(item)"
                  >
                    mdi-minus-circle
                  </v-icon>
                  <v-icon
                    :disabled="!allowNextPurchase(item)"
                  medium 
                  @click="incrementSkill(item)"
                  >
                    mdi-plus-circle
                  </v-icon>
                </template>
                <template v-slot:item.nextPurchase="{item}">
                  {{getNthCost(item.abilities_id, getTotalPurchased(item.abilities_id)+1, item.cost, getModifier(item.abilities_id, classes), item.rank, item.ability_type)}}
                </template>
                <template v-slot:item.total="{item}">
                  {{getTotalCost(getTotalPurchased(item.abilities_id), item.cost, getModifier(item.abilities_id, classes), item.abilities_id, item.rank, item.ability_type)}}
                </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Lower List Skills-->
            <v-expansion-panel>
              <v-expansion-panel-header color="tertiary">Lower List Skills</v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- Base Skills Table-->
                <v-data-table
                :headers="headers"
                :items="shown_skills"
                :key="counter"
                hide-default-footer
                :items-per-page="count"
                :search="search"
                fixed-header
                no-data-text="Please Select Your Class(es)"
                >
                <template v-slot:item.quantity="{ item }">
                  {{getKnownQuantity(item)}}
                </template>
                <template v-slot:item.purchased="{ item }">
                  <v-icon
                  medium 
                  :disabled="getKnownQuantity(item)<1"
                  @click="decrementSkill(item)"
                  >
                    mdi-minus-circle
                  </v-icon>
                  <v-icon
                    :disabled="!allowNextPurchase(item)"
                  medium 
                  @click="incrementSkill(item)"
                  >
                    mdi-plus-circle
                  </v-icon>
                </template>
                <template v-slot:item.nextPurchase="{item}">
                  {{getNthCost(item.abilities_id, getTotalPurchased(item.abilities_id)+1, item.cost, getModifier(item.abilities_id, classes), item.rank, item.ability_type)}}
                </template>
                <template v-slot:item.total="{item}">
                  {{getTotalCost(getTotalPurchased(item.abilities_id), item.cost, getModifier(item.abilities_id, classes), item.abilities_id, item.rank, item.ability_type)}}
                </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Lower List 1 Selections -->
            <v-expansion-panel
              v-if="classes[1]!=null && weapon_prof_dict[1].length>1"
              >
              <v-expansion-panel-header color="tertiary">Lower List 1 Proficiencies - {{class_list.find(c => c.id == classes[1]).name}}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-autocomplete
                  @change="weaponProfUpdate()"
                  v-model="selected_weapon_prof[0]"
                  label="Proficiency #1"
                  :items="weapon_prof_dict[1]"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
                <v-autocomplete
                  @change="weaponProfUpdate()"
                  v-model="selected_weapon_prof[1]"
                  label="Proficiency #2"
                  :items="weapon_prof_dict[1]"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
                <v-autocomplete
                  @change="weaponProfUpdate()"
                  v-model="selected_weapon_prof[2]"
                  label="Proficiency #3"
                  :items="weapon_prof_dict[1]"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Lower List 2 Selections -->
            <v-expansion-panel
              v-if="classes[2]!=null && weapon_prof_dict[2].length>1"
              >
              <v-expansion-panel-header color="tertiary">Lower List 2 Proficiencies - {{class_list.find(c => c.id == classes[2]).name}}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-autocomplete
                  @change="weaponProfUpdate()"
                  v-model="selected_weapon_prof[3]"
                  label="Proficiency #1"
                  :items="weapon_prof_dict[2]"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
                <v-autocomplete
                  @change="weaponProfUpdate()"
                  v-model="selected_weapon_prof[4]"
                  label="Proficiency #2"
                  :items="weapon_prof_dict[2]"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
                <v-autocomplete
                  @change="weaponProfUpdate()"
                  v-model="selected_weapon_prof[5]"
                  label="Proficiency #3"
                  :items="weapon_prof_dict[2]"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Lower List 3 Selections -->
            <v-expansion-panel
              v-if="classes[3]!=null && weapon_prof_dict[3].length>1"
              >
              <v-expansion-panel-header color="tertiary">Lower List 3 Proficiencies - {{class_list.find(c => c.id == classes[3]).name}}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-autocomplete
                  @change="weaponProfUpdate()"
                  v-model="selected_weapon_prof[6]"
                  label="Proficiency #1"
                  :items="weapon_prof_dict[3]"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
                <v-autocomplete
                  @change="weaponProfUpdate()"
                  v-model="selected_weapon_prof[7]"
                  label="Proficiency #2"
                  :items="weapon_prof_dict[3]"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
                <v-autocomplete
                  @change="weaponProfUpdate()"
                  v-model="selected_weapon_prof[8]"
                  label="Proficiency #3"
                  :items="weapon_prof_dict[3]"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Lower List 4 Selections -->
            <v-expansion-panel
              v-if="classes[4]!=null && weapon_prof_dict[4].length>1"
              >
              <v-expansion-panel-header color="tertiary">Lower List 4 Proficiencies - {{class_list.find(c => c.id == classes[4]).name}}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-autocomplete
                  @change="weaponProfUpdate()"
                  v-model="selected_weapon_prof[9]"
                  label="Proficiency #1"
                  :items="weapon_prof_dict[4]"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
                <v-autocomplete
                  @change="weaponProfUpdate()"
                  v-model="selected_weapon_prof[10]"
                  label="Proficiency #2"
                  :items="weapon_prof_dict[4]"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
                <v-autocomplete
                  @change="weaponProfUpdate()"
                  v-model="selected_weapon_prof[11]"
                  label="Proficiency #3"
                  :items="weapon_prof_dict[4]"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Character Rank 0 Spells -->
            <v-expansion-panel 
              v-if="shown_spells.filter(s=>s.rank==0).length>0">
              <v-expansion-panel-header color="tertiary">Rank 0 Spells Selections</v-expansion-panel-header>
              <v-expansion-panel-content>
                Total Build Spent:  {{getTotalCost(getTotalPurchased(spell_purchase[0].abilities_id), spell_purchase[0].cost, getModifier(spell_purchase[0].abilities_id, classes), spell_purchase[0].abilities_id, 0, 13)}}
                <br>
                Next Purchase Cost: {{getNthCost(spell_purchase[0].abilities_id, getTotalPurchased(spell_purchase[0].abilities_id)+1, spell_purchase[0].cost, getModifier(spell_purchase[0].abilities_id, classes), 0, 13)}}
                <v-container fluid>
                  <v-checkbox v-for="spell in shown_spells.filter(s=>s.rank==0)" 
                    v-model="known_spells_dict[0]" 
                    :label="spell.name" 
                    :key="spell.abilities_id"
                    :value="spell.abilities_id" 
                    :disabled="getNthCost(spell_purchase[0].abilities_id, getTotalPurchased(spell_purchase[0].abilities_id)+1, spell_purchase[0].cost, getModifier(spell_purchase[0].abilities_id, classes), 0, 13) + buildSpent > getBuildTotal() && known_spells_dict[0].indexOf(spell.abilities_id) === -1"
                    @change="check($event, spell)">
                  </v-checkbox>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Character Rank 1 Spells -->
            <v-expansion-panel
            v-if="shown_spells.filter(s=>s.rank==1).length>0">
              <v-expansion-panel-header color="tertiary">Rank 1 Spells Selections</v-expansion-panel-header>
              <v-expansion-panel-content>
                Total Build Spent:  {{getTotalCost(getTotalPurchased(spell_purchase[1].abilities_id), spell_purchase[1].cost, getModifier(spell_purchase[1].abilities_id, classes), spell_purchase[1].abilities_id, 1, 13)}}
                <br>
                Next Purchase Cost: {{getNthCost(spell_purchase[1].abilities_id, getTotalPurchased(spell_purchase[1].abilities_id)+1, spell_purchase[1].cost, getModifier(spell_purchase[1].abilities_id, classes), 1, 13)}}
                <v-container fluid>
                  <v-checkbox v-for="spell in shown_spells.filter(s=>s.rank==1)" 
                    v-model="known_spells_dict[1]" 
                    :label="spell.name" 
                    :key="spell.abilities_id"
                    :value="spell.abilities_id" 
                    :disabled="getNthCost(spell_purchase[1].abilities_id, getTotalPurchased(spell_purchase[1].abilities_id)+1, spell_purchase[1].cost, getModifier(spell_purchase[1].abilities_id, classes), 1, 13) + buildSpent > getBuildTotal() && known_spells_dict[1].indexOf(spell.abilities_id) === -1"
                    @change="check($event, spell)">
                  </v-checkbox>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Character Rank 2 Spells -->
            <v-expansion-panel
            v-if="shown_spells.filter(s=>s.rank==2).length>0">
              <v-expansion-panel-header color="tertiary">Rank 2 Spells Selections</v-expansion-panel-header>
              <v-expansion-panel-content>
                Total Build Spent:  {{getTotalCost(getTotalPurchased(spell_purchase[2].abilities_id), spell_purchase[2].cost, getModifier(spell_purchase[2].abilities_id, classes), spell_purchase[2].abilities_id, 2, 13)}}
                <br>
                Next Purchase Cost: {{getNthCost(spell_purchase[2].abilities_id, getTotalPurchased(spell_purchase[2].abilities_id)+1, spell_purchase[2].cost, getModifier(spell_purchase[2].abilities_id, classes), 2, 13)}}
                <v-container fluid>
                  <v-checkbox v-for="spell in shown_spells.filter(s=>s.rank==2)" 
                    v-model="known_spells_dict[2]" 
                    :label="spell.name" 
                    :key="spell.abilities_id"
                    :value="spell.abilities_id" 
                    :disabled="getNthCost(spell_purchase[2].abilities_id, getTotalPurchased(spell_purchase[2].abilities_id)+1, spell_purchase[2].cost, getModifier(spell_purchase[1].abilities_id, classes), 2, 13) + buildSpent > getBuildTotal() && known_spells_dict[2].indexOf(spell.abilities_id) === -1"
                    @change="check($event, spell)">
                  </v-checkbox>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Character Rank 3 Spells -->
            <v-expansion-panel
            v-if="shown_spells.filter(s=>s.rank==3).length>0">
              <v-expansion-panel-header color="tertiary">Rank 3 Spells Selections</v-expansion-panel-header>
              <v-expansion-panel-content>
                Total Build Spent:  {{getTotalCost(getTotalPurchased(spell_purchase[3].abilities_id), spell_purchase[3].cost, getModifier(spell_purchase[3].abilities_id, classes), spell_purchase[3].abilities_id, 3, 13)}}
                <br>
                Next Purchase Cost: {{getNthCost(spell_purchase[3].abilities_id, getTotalPurchased(spell_purchase[3].abilities_id)+1, spell_purchase[3].cost, getModifier(spell_purchase[3].abilities_id, classes), 3, 13)}}
                <v-container fluid>
                  <v-checkbox v-for="spell in shown_spells.filter(s=>s.rank==3)" 
                    v-model="known_spells_dict[3]" 
                    :label="spell.name" 
                    :key="spell.abilities_id"
                    :value="spell.abilities_id" 
                    :disabled="getNthCost(spell_purchase[3].abilities_id, getTotalPurchased(spell_purchase[3].abilities_id)+1, spell_purchase[3].cost, getModifier(spell_purchase[1].abilities_id, classes), 3, 13) + buildSpent > getBuildTotal() && known_spells_dict[3].indexOf(spell.abilities_id) === -1"
                    @change="check($event, spell)">
                  </v-checkbox>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Character Rank 4 Spells -->
            <v-expansion-panel
            v-if="shown_spells.filter(s=>s.rank==4).length>0">
              <v-expansion-panel-header color="tertiary">Rank 4 Spells Selections</v-expansion-panel-header>
              <v-expansion-panel-content>
                Total Build Spent:  {{getTotalCost(getTotalPurchased(spell_purchase[4].abilities_id), spell_purchase[4].cost, getModifier(spell_purchase[4].abilities_id, classes), spell_purchase[4].abilities_id, 4, 13)}}
                <br>
                Next Purchase Cost: {{getNthCost(spell_purchase[4].abilities_id, getTotalPurchased(spell_purchase[4].abilities_id)+1, spell_purchase[4].cost, getModifier(spell_purchase[4].abilities_id, classes), 4, 13)}}
                <v-container fluid>
                  <!-- p>{{ known_spells_dict[4] }}</!-->
                  <v-checkbox v-for="spell in shown_spells.filter(s=>s.rank==4)" 
                    v-model="known_spells_dict[4]" 
                    :label="spell.name" 
                    :key="spell.abilities_id"
                    :value="spell.abilities_id" 
                    :disabled="getNthCost(spell_purchase[4].abilities_id, getTotalPurchased(spell_purchase[4].abilities_id)+1, spell_purchase[4].cost, getModifier(spell_purchase[1].abilities_id, classes), 4, 13) + buildSpent > getBuildTotal() && known_spells_dict[4].indexOf(spell.abilities_id) === -1"
                    @change="check($event, spell)">
                  </v-checkbox>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Character Rank 0 Talents -->
            <v-expansion-panel 
              v-if="shown_talents.filter(s=>s.rank==0).length>0">
              <v-expansion-panel-header color="tertiary">Rank 0 Talents Selections</v-expansion-panel-header>
              <v-expansion-panel-content>
                Total Build Spent:  {{getTotalCost(getTotalPurchased(talent_purchase[0].abilities_id), talent_purchase[0].cost, getModifier(talent_purchase[0].abilities_id, classes), talent_purchase[0].abilities_id, 0, 14)}}
                <br>
                Next Purchase Cost: {{getNthCost(talent_purchase[0].abilities_id, getTotalPurchased(talent_purchase[0].abilities_id)+1, talent_purchase[0].cost, getModifier(talent_purchase[0].abilities_id, classes), 0, 14)}}
                <v-container fluid>
                  <v-checkbox v-for="talent in shown_talents.filter(t=>t.rank==0)" 
                    v-model="known_talents_dict[0]" 
                    :label="talent.name" 
                    :key="talent.abilities_id"
                    :value="talent.abilities_id" 
                    :disabled="getNthCost(talent_purchase[0].abilities_id, getTotalPurchased(talent_purchase[0].abilities_id)+1, talent_purchase[0].cost, getModifier(talent_purchase[0].abilities_id, classes), 0, 14) + buildSpent > getBuildTotal() && known_talents_dict[0].indexOf(talent.abilities_id) === -1"
                    @change="check($event, talent)">
                  </v-checkbox>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Character Rank 1 Talents -->
            <v-expansion-panel
            v-if="shown_talents.filter(s=>s.rank==1).length>0">
              <v-expansion-panel-header color="tertiary">Rank 1 Talents Selections</v-expansion-panel-header>
              <v-expansion-panel-content>
                Total Build Spent:  {{getTotalCost(getTotalPurchased(talent_purchase[1].abilities_id), talent_purchase[1].cost, getModifier(talent_purchase[1].abilities_id, classes), talent_purchase[1].abilities_id, 1, 14)}}
                <br>
                Next Purchase Cost: {{getNthCost(talent_purchase[1].abilities_id, getTotalPurchased(talent_purchase[1].abilities_id)+1, talent_purchase[1].cost, getModifier(talent_purchase[1].abilities_id, classes), 1, 14)}}
                <v-container fluid>
                  <v-checkbox v-for="talent in shown_talents.filter(t=>t.rank==1)" 
                    v-model="known_talents_dict[1]" 
                    :label="talent.name" 
                    :key="talent.abilities_id"
                    :value="talent.abilities_id" 
                    :disabled="getNthCost(talent_purchase[1].abilities_id, getTotalPurchased(talent_purchase[1].abilities_id)+1, talent_purchase[1].cost, getModifier(talent_purchase[1].abilities_id, classes), 1, 14) + buildSpent > getBuildTotal() && known_talents_dict[1].indexOf(talent.abilities_id) === -1"
                    @change="check($event, talent)">
                  </v-checkbox>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Character Rank 2 Talents -->
            <v-expansion-panel
            v-if="shown_talents.filter(s=>s.rank==2).length>0">
              <v-expansion-panel-header color="tertiary">Rank 2 Talents Selections</v-expansion-panel-header>
              <v-expansion-panel-content>
                Total Build Spent:  {{getTotalCost(getTotalPurchased(talent_purchase[2].abilities_id), talent_purchase[2].cost, getModifier(talent_purchase[2].abilities_id, classes), talent_purchase[2].abilities_id, 2, 14)}}
                <br>
                Next Purchase Cost: {{getNthCost(talent_purchase[2].abilities_id, getTotalPurchased(talent_purchase[2].abilities_id)+1, talent_purchase[2].cost, getModifier(talent_purchase[2].abilities_id, classes), 2, 14)}}
                <v-container fluid>
                  <v-checkbox v-for="talent in shown_talents.filter(t=>t.rank==2)" 
                    v-model="known_talents_dict[2]" 
                    :label="talent.name" 
                    :key="talent.abilities_id"
                    :value="talent.abilities_id" 
                    :disabled="getNthCost(talent_purchase[2].abilities_id, getTotalPurchased(talent_purchase[2].abilities_id)+1, talent_purchase[2].cost, getModifier(talent_purchase[2].abilities_id, classes), 2, 14) + buildSpent > getBuildTotal() && known_talents_dict[2].indexOf(talent.abilities_id) === -1"
                    @change="check($event, talent)">
                  </v-checkbox>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Character Rank 3 Talents -->
            <v-expansion-panel
            v-if="shown_talents.filter(s=>s.rank==3).length>0">
              <v-expansion-panel-header color="tertiary">Rank 3 Talents Selections</v-expansion-panel-header>
              <v-expansion-panel-content>
                Total Build Spent:  {{getTotalCost(getTotalPurchased(talent_purchase[3].abilities_id), talent_purchase[3].cost, getModifier(talent_purchase[3].abilities_id, classes), talent_purchase[3].abilities_id, 3, 14)}}
                <br>
                Next Purchase Cost: {{getNthCost(talent_purchase[3].abilities_id, getTotalPurchased(talent_purchase[3].abilities_id)+1, talent_purchase[3].cost, getModifier(talent_purchase[3].abilities_id, classes), 3, 14)}}
                <v-container fluid>
                  <v-checkbox v-for="talent in shown_talents.filter(t=>t.rank==3)" 
                    v-model="known_talents_dict[3]" 
                    :label="talent.name" 
                    :key="talent.abilities_id"
                    :value="talent.abilities_id" 
                    :disabled="getNthCost(talent_purchase[3].abilities_id, getTotalPurchased(talent_purchase[3].abilities_id)+1, talent_purchase[3].cost, getModifier(talent_purchase[3].abilities_id, classes), 3, 14) + buildSpent > getBuildTotal() && known_talents_dict[3].indexOf(talent.abilities_id) === -1"
                    @change="check($event, talent)">
                  </v-checkbox>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Character Rank 4 Talents -->
            <v-expansion-panel
            v-if="shown_talents.filter(s=>s.rank==4).length>0">
              <v-expansion-panel-header color="tertiary">Rank 4 Talents Selections</v-expansion-panel-header>
              <v-expansion-panel-content>
                Total Build Spent:  {{getTotalCost(getTotalPurchased(talent_purchase[4].abilities_id), talent_purchase[4].cost, getModifier(talent_purchase[4].abilities_id, classes), talent_purchase[4].abilities_id, 4, 14)}}
                <br>
                Next Purchase Cost: {{getNthCost(talent_purchase[4].abilities_id, getTotalPurchased(talent_purchase[4].abilities_id)+1, talent_purchase[4].cost, getModifier(talent_purchase[4].abilities_id, classes), 4, 14)}}
                <v-container fluid>
                  <v-checkbox v-for="talent in shown_talents.filter(t=>t.rank==4)" 
                    v-model="known_talents_dict[4]" 
                    :label="talent.name" 
                    :key="talent.abilities_id"
                    :value="talent.abilities_id" 
                    :disabled="getNthCost(talent_purchase[4].abilities_id, getTotalPurchased(talent_purchase[4].abilities_id)+1, talent_purchase[4].cost, getModifier(talent_purchase[4].abilities_id, classes), 4, 14) + buildSpent > getBuildTotal() && known_talents_dict[4].indexOf(talent.abilities_id) === -1"
                    @change="check($event, talent)">
                  </v-checkbox>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Master List 1 Selections -->
            <v-expansion-panel
              v-if="classes[5]!=null"
              >
              <v-expansion-panel-header color="tertiary">Master List 1 Selections</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-autocomplete
                  @change="masterSkillUpdate()"
                  v-model="selected_master_skills[0]"
                  label="Skill #1"
                  :items="master_skills_list_dict[1].filter(s=>!([selected_master_skills[1], selected_master_skills[2]].includes(s.abilities_id)))"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
                <v-autocomplete
                  @change="masterSkillUpdate()"
                  v-model="selected_master_skills[1]"
                  label="Skill #2"
                  :items="master_skills_list_dict[1].filter(s=>!([selected_master_skills[0], selected_master_skills[2]].includes(s.abilities_id)))"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
                <v-autocomplete
                  @change="masterSkillUpdate()"
                  v-model="selected_master_skills[2]"
                  label="Skill #3"
                  :items="master_skills_list_dict[1].filter(s=>!([selected_master_skills[0], selected_master_skills[1]].includes(s.abilities_id)))"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Master List 2 Selections -->
            <v-expansion-panel
              v-if="classes[6]!=null"
              >
              <v-expansion-panel-header color="tertiary">Master List 2 Selections</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-autocomplete
                  @change="masterSkillUpdate()"
                  v-model="selected_master_skills[3]"
                  label="Skill #1"
                  :items="master_skills_list_dict[2].filter(s=>!([selected_master_skills[4], selected_master_skills[5]].includes(s.abilities_id)))"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
                <v-autocomplete
                  @change="masterSkillUpdate()"
                  v-model="selected_master_skills[4]"
                  label="Skill #2"
                  :items="master_skills_list_dict[2].filter(s=>!([selected_master_skills[3], selected_master_skills[5]].includes(s.abilities_id)))"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
                <v-autocomplete
                  @change="masterSkillUpdate()"
                  v-model="selected_master_skills[5]"
                  label="Skill #3"
                  :items="master_skills_list_dict[2].filter(s=>!([selected_master_skills[3], selected_master_skills[4]].includes(s.abilities_id)))"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Master List 3 Selections -->
            <v-expansion-panel
              v-if="classes[7]!=null"
              >
              <v-expansion-panel-header color="tertiary">Master List 3 Selections</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-autocomplete
                  @change="masterSkillUpdate()"
                  v-model="selected_master_skills[6]"
                  label="Skill #1"
                  :items="master_skills_list_dict[3].filter(s=>!([selected_master_skills[7], selected_master_skills[8]].includes(s.abilities_id)))"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
                <v-autocomplete
                  @change="masterSkillUpdate()"
                  v-model="selected_master_skills[7]"
                  label="Skill #2"
                  :items="master_skills_list_dict[3].filter(s=>!([selected_master_skills[6], selected_master_skills[8]].includes(s.abilities_id)))"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
                <v-autocomplete
                  @change="masterSkillUpdate()"
                  v-model="selected_master_skills[8]"
                  label="Skill #3"
                  :items="master_skills_list_dict[3].filter(s=>!([selected_master_skills[6], selected_master_skills[7]].includes(s.abilities_id)))"
                  item-value="abilities_id"
                  item-text='name'
                  :dark="$vuetify.theme.dark"
                  outlined
                  color="contrast"
                  item-color="contrast"
                  clearable
                ></v-autocomplete>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Expansion Panel for Master List Skills-->
            <v-expansion-panel
              v-if="!classes.slice(5,8).every(element=>element===null)">
              <v-expansion-panel-header color="tertiary">Master List Skills</v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- Master Skills Table -->
                <v-data-table
                :headers="headers"
                :items="shown_master_skills"
                :key="counter"
                hide-default-footer
                :items-per-page="count"
                :search="search"
                fixed-header
                no-data-text="Please Select Your Class(es)"
                >
                <template v-slot:item.quantity="{ item }">
                  {{getKnownQuantity(item)}}
                </template>
                <template v-slot:item.purchased="{ item }">
                  <v-icon
                  medium 
                  :disabled="getKnownQuantity(item)<1"
                  @click="decrementSkill(item)"
                  >
                    mdi-minus-circle
                  </v-icon>
                  <v-icon
                  :disabled="!allowNextPurchase(item)"
                  medium 
                  @click="incrementSkill(item)"
                  >
                    mdi-plus-circle
                  </v-icon>
                </template>
                <template v-slot:item.nextPurchase="{item}">
                  {{getNthCost(item.abilities_id, getTotalPurchased(item.abilities_id)+1, item.cost, getModifier(item.abilities_id, classes), item.rank, item.ability_type)}}
                </template>
                <template v-slot:item.total="{item}">
                  {{getTotalCost(getTotalPurchased(item.abilities_id), item.cost, getModifier(item.abilities_id, classes), item.abilities_id, item.rank, item.ability_type)}}
                </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-form>

  </v-container>
  </v-card>
</template>

<script>

export default {
  components: {},
  data: () => ({
    dialog: false,
    operation: '',
    count: 1000,
    counter: 0,
    search: '',
    headers: [
        {
          text: 'Skill Name',
          align: 'start',
          value: 'name',
          sortable: false,
          filterable: true
        },
        { 
          text: 'Type',
          value: 'type',
          sortable: false,
          filterable: true
        
        },
        { text: 'Quantity', value: 'quantity', sortable: false,},
        { text: 'Purchase', value: 'purchased', sortable: false, },
        { text: 'Next Purchase', value: 'nextPurchase', sortable: false,},
        { text: 'Total Spent', value: 'total', sortable: false,}
        
      ],
    lower_list: Array,
    master_list: Array,

    classes: [1, null, null, null, null, null, null, null, null, null],
    shown_skills: [],
    shown_common: [],
    shown_spells: [],
    shown_talents: [],
    shown_weapon_prof: [],
    shown_master_skills: [],
    hidden_skills: [],
    master_skills_list_dict: {1: [], 2: [], 3:[]},
    weapon_prof_dict: {1:[], 2:[], 3:[], 4:[]},
    list_alignments: [null, null, null, null, null, null, null, null, null, null],
    spells_purchased_dict: {0: 0, 1:0, 2:0, 3:0, 4:0},
    known_spells_dict: {0:[], 1:[], 2:[], 3:[], 4:[]},
    talents_purchased_dict: {0: 0, 1:0, 2:0, 3:0, 4:0},
    known_talents_dict: {0:[], 1:[], 2:[], 3:[], 4:[]},
    spell_purchase: {0:null, 1:null, 2:null, 3:null, 4:null},
    talent_purchase: {0:null, 1:null, 2:null, 3:null, 4:null},
    paragonCount: 0,
    attributeCount: 0,
    
  }),
  props: {
    skills: Array,
    skills_by_class: Array,
    class_list: Array,
    alignment_by_class: Array,
    originalCharacter: Object,
    isNewCharacter: Boolean,
    isWizard: Boolean
  },
  watch: {
  },
  computed: {
    selected_master_skills:{
      get(){
        return this.$store.state.character.selectedMasterSkills;
      },
      set(value){
        this.$store.commit('updateSelectedMasterSkills', value)
      }
    },
    selected_weapon_prof:{
      get(){
        return this.$store.state.character.selectedWeaponProf;
      },
      set(value){
        this.$store.commit('updateSelectedWeaponProf', value)
      }
    },
    list_entities_ids: {
      get(){
        return this.$store.state.character.entityList
      },
      set(value){
        this.$store.commit('updateEntityList', value)
      }
    },
    buildSpent: {
      get(){
        return this.$store.state.character.buildSpent
      },
      set(value){
        this.$store.commit('updateBuildSpent', value)
      }
    },
    classes: {
      get(){
        return this.$store.state.character.classes
      },
      set(value){
        this.$store.commit('updateClasses', value)
      }
    }
  },
  mounted: function(){
    //add type text string to skills
    this.skills.forEach(s => s.type = this.abilityTypeString(s.ability_type), this)
    //Fix paren issue for Lores
    this.setClassLists()
    this.setSpellTalentConstants()    
    //check to see if the skills/classes/alignments have been loaded
    //to cheat and save time, we check to see if Lower List 1 is loaded, since if
    //it is not, we can safely assume none of this page has yet been loaded in
    if(!this.isNewCharacter){
      this.populateOriginalCharacter()
    }
    //emit that we have visited classSkills page, so that way we can enable our overview page
    this.$emit("class_skill_visited")
  },

  methods: {

    setSpellTalentConstants(){
      for(let rank = 0; rank <=4; rank++){
        this.spell_purchase[rank] = this.skills.find(s=>s.rank==rank && s.ability_type==13)
        this.talent_purchase[rank] = this.skills.find(s=>s.rank==rank && s.ability_type==14)
      }
    },

    alignmentHasSelection(class_id){
      const alignmentList = this.alignment_by_class.filter(row => row["class_field"]==class_id)
      
      return alignmentList.length>2
    },

    getAlignment(class_id){
      const alignmentList = this.alignment_by_class.filter(row => row["class_field"]==class_id)
      
      let dedup = (items) => items.filter((v,i) => items.indexOf(v) === i)

      return alignmentList.length>1?dedup(alignmentList.map(a=>a.alignment)):(alignmentList.length==1?alignmentList[0].alignment:null)

    },

    updateAlignment(index){

      var alignment = this.getAlignment(this.classes[index])
      this.list_alignments[index] = alignment
      this.updateSpecificAlignment()
    },

    updateSpecificAlignment(){
      const abc = this.alignment_by_class

      var alignmentsByID = this.list_alignments.map(id => abc.filter(row =>row["id"]==id).map(c=>c["alignment"])[0])
      var alignmentsByName = this.list_alignments.filter(name => ["Light", "Dark", "Order", "Chaos"].includes(name))
      
      this.$store.commit('updateAlignments', alignmentsByID.concat(alignmentsByName))

      this.list_entities_ids = this.list_alignments.map(value => ["Light", "Dark", "Order", "Chaos", null].includes(value)? null : abc.filter(row=>row["id"]==value).map(c=>c["entity"])[0])
    },

    clearAlignments(){
      this.$store.commit('updateAlignments', [])
    },

    getClassList(ability_id){
      var classList = this.skills_by_class.filter(c => c.ability==ability_id)
      return classList.map(c => c.class_field)
    },
    getModifier(skill_id, classesKnown){
      //check to see if skills is currently known and has skillfullness
      var mod = 0;
      if(Object.prototype.hasOwnProperty.call(this.$store.state.character.skills, skill_id)){
        mod += this.$store.state.character.skills[skill_id].skillfullness
      }
      var s_id = parseInt(skill_id)
      if(this.selected_master_skills.includes(s_id)){
        mod+=this.selected_master_skills.filter(s => s===s_id).length
      }
      else if(this.selected_weapon_prof.includes(s_id)){
        //break selected weapon profs into 4 distinct groups by lower list selections to account for cascading/scaling costs
        let ll1 = this.selected_weapon_prof.slice(0,3)
        let ll2 = this.selected_weapon_prof.slice(3,6)
        let ll3 = this.selected_weapon_prof.slice(6,9)
        let ll4 = this.selected_weapon_prof.slice(9,12)

        mod += ll1.includes(s_id) ? 1 : 0
        mod += ll2.includes(s_id) ? 1 : 0
        mod += ll3.includes(s_id) ? 1 : 0
        mod += ll4.includes(s_id) ? 1 : 0
      }
      else{
        var cList = this.getClassList(skill_id);
        classesKnown.forEach(function(c)
          {
            if (cList.includes(c)){mod+=1}
          })
      }
      return mod;
    },

    updateShownSkills(){
      const classes = this.classes
      var shown = this.skills_by_class.filter(function (item) {
        return classes.slice(1,5).includes(item.class_field);        
      }).map(a => a.ability)

      var shownCommon = this.skills_by_class.filter(function (item) {
        return classes.slice(0,1).includes(item.class_field);        
      }).map(a => a.ability)

      let dedup = (items) => items.filter((v,i) => items.indexOf(v) === i)

      shown = dedup(shown)
      shownCommon = dedup(shownCommon)

      this.shown_skills = this.skills.filter(function (s){
        return shown.includes(s.abilities_id) && s.ability_type!=4 && s.ability_type!=5 && s.ability_type!=13 && s.ability_type !=14 && s.ability_type !=12 && s.ability_type !=16 && s.ability_type != 19
        }).sort(function (a,b){return a.name<b.name ? -1 : 1})

      this.shown_common = this.skills.filter(function (s){
        return shownCommon.includes(s.abilities_id) && s.ability_type!=4 && s.ability_type!=5 && s.ability_type!=13 && s.ability_type !=14 && s.ability_type != 12 && s.ability_type !=16 && s.ability_type != 19
        }).sort(function (a,b){return a.name<b.name ? -1 : 1})

      this.shown_spells= this.skills.filter(function (s){
        return shown.includes(s.abilities_id) && s.ability_type==4
        }).sort(function (a,b){return a.name<b.name ? -1 : 1})
      
      this.shown_talents = this.skills.filter(function (s){
        return shown.includes(s.abilities_id) && s.ability_type==5
        }).sort(function (a,b){return a.name<b.name ? -1 : 1})

      this.hidden_skills = this.skills.filter(function (s){
        return shown.includes(s.abilities_id) && (s.ability_type==13 || s.ability_type ==14 || s.ability_type==16 || s.ability_type==12)
        }).sort(function (a,b){return a.name<b.name ? -1 : 1})
    },

    updateShownMasterSkills(){
      const classes = this.classes
      const selectedSkills = this.selected_master_skills

      let dedup = (items) => items.filter((v,i) => items.indexOf(v) === i)

      for(var mList=1; mList <=3; mList++){
        var master_list = this.skills_by_class.filter(function (item) {
              return classes.slice(4+mList,5+mList).includes(item.class_field);        
            }).map(a => a.ability)

        this.master_skills_list_dict[mList]= this.skills.filter(function (s){
              return master_list.includes(s.abilities_id) && s.ability_type!=4 &&s.ability_type!=5
              }).sort(function (a,b){return a.name<b.name ? -1 : 1})
      }
      var shown = this.skills_by_class.filter(function (item) {
        return classes.slice(5,8).includes(item.class_field);        
      }).map(a => a.ability)


      shown = dedup(shown)
      shown = shown.filter(s => selectedSkills.includes(s))
      this.shown_master_skills = this.skills.filter(function (s){
        return shown.includes(s.abilities_id) && s.ability_type!=4 &&s.ability_type!=5
        }).sort(function (a,b){return a.name<b.name ? -1 : 1})
      
    },

    updateShownWeaponProfs(){
      const classes = this.classes
      const selectedSkills = this.selected_weapon_prof

      let dedup = (items) => items.filter((v,i) => items.indexOf(v) === i)

      for(var lList=1; lList <=4; lList++){
        var lower_list = this.skills_by_class.filter(function (item) {
              return classes.slice(lList,1+lList).includes(item.class_field);        
            }).map(a => a.ability)

        this.weapon_prof_dict[lList]= this.skills.filter(function (s){
              return lower_list.includes(s.abilities_id) && s.ability_type==15
              }).sort(function (a,b){return a.name<b.name ? -1 : 1})
      }
      var shown = this.skills_by_class.filter(function (item) {
        return classes.slice(1,5).includes(item.class_field);        
      }).map(a => a.ability)


      shown = dedup(shown)
      shown = shown.filter(s => selectedSkills.includes(s))
      this.shown_weapon_prof = this.skills.filter(function (s){
        return shown.includes(s.abilities_id) && s.ability_type==15
        }).sort(function (a,b){return a.name<b.name ? -1 : 1})
    },

    setClassLists(){
      this.lower_list = this.class_list.filter(function(c){return c.class_type!='exalted' && c.class_type!='legendary' && c.class_type!='master' && c.class_type!='common/prestige points'})
      this.master_list = this.class_list.filter(function(c){return c.class_type=='master'})
    },
    clearSkillsClasses(){
      this.classes = [null, null, null, null, null, null, null, null, null, null];
      this.updateShownSkills();
      this.updateShownMasterSkills();
      this.removeUnmatchedSkills();
      this.classes = [1, null, null, null, null, null, null, null, null, null]
      this.list_alignments = [null, null, null, null, null, null, null, null, null, null];
      this.list_entities_ids = [null, null, null, null, null, null, null, null, null, null];
      this.classUpdate();
      this.clearAlignments();
      this.shown_skills=[];
      this.shown_common=[];
      this.shown_master_skills=[];
      this.shown_spells=[];
      this.shown_talents=[];
      this.shown_weapon_prof=[];
      this.hidden_skills=[];
      this.spells_purchased_dict={0: 0, 1:0, 2:0, 3:0, 4:0};
      this.known_spells_dict={0:[], 1:[], 2:[], 3:[], 4:[]};
      this.talents_purchased_dict={0: 0, 1:0, 2:0, 3:0, 4:0};
      this.known_talents_dict={0:[], 1:[], 2:[], 3:[], 4:[]};
      this.weapon_prof_dict={1:[], 2:[], 3:[], 4:[]};
      this.selected_weapon_prof=[null, null, null, null, null, null, null, null, null, null, null, null];
      this.counter+=1;
      this.paragonCount=0;
      this.attributeCount=0;
    },

    masterSkillUpdate(){
      this.counter+=1;
      this.updateShownMasterSkills()
      this.removeUnmatchedSkills()
      this.recalculateBuildSpent()
    },

    weaponProfUpdate(){
      this.counter+=1;
      this.updateShownWeaponProfs()
      this.removeUnmatchedSkills()
      //Check to See if we know more instances of a weapon prof than we are allowed to and decrement
      let dedup = (items) => items.filter((v,i) => items.indexOf(v) === i)

      let profsKnown = dedup(this.selected_weapon_prof).filter(s => s!=null)
      let profSkills = this.skills.filter(s => s.ability_type==15)
      //Check if there are no more profs selected, if so clear all (fixes corner case where it cannot delete if there is nothing left)
      if(profsKnown.length==0){
        let shownProfs = profSkills.filter(s=>this.shown_skills.includes(s))
        shownProfs.forEach(function(prof){
          let kq = this.getKnownQuantity(prof)
          while(kq>0){
            this.decrementSkill(prof)
            kq -= 1
          }
        }, this)
      }
      else{
        profsKnown.forEach(function(prof_id){
          let prof = profSkills.find(s=>s.abilities_id==prof_id)
          let knownQuantity = this.getKnownQuantity(prof)
          let maxQuantity = this.selected_weapon_prof.filter(s => s==prof_id).length
          for(let x = knownQuantity; x>maxQuantity; x--){
            this.decrementSkill(prof)
          }
        },this)

        let shownProfs = profSkills.filter(s=>this.shown_skills.includes(s))
        let profsToBeRemoved = shownProfs.filter(s=> !profsKnown.includes(s))
        profsToBeRemoved.forEach(function(prof){
          let knownQuantity = this.getKnownQuantity(prof)
          let maxQuantity = this.selected_weapon_prof.filter(s => s==prof.abilities_id).length
          for(let x = knownQuantity; x>maxQuantity; x--){
            this.decrementSkill(prof)
          }
        },this)
      }
      this.recalculateBuildSpent()
    },

    spellTalentCountUpdate(){
      this.counter+=1;
      //Check to See if we know more instances of a spell or talent than we are allowed to and decrement

      //this.updateShownSkills();
      //this.removeUnmatchedSpellsTalents();

      let spellLevelsShown = this.hidden_skills.filter(s=>s.ability_type == 13)
      let talentLevelsShown = this.hidden_skills.filter(s=>s.ability_type == 14)
      
      spellLevelsShown.forEach(function(spellLevel){
        let maxSpellCount = this.shown_spells.filter(s=>s.rank==spellLevel.rank).length
        let currentKnownCount = this.known_spells_dict[spellLevel.rank].length
        let kq = this.getKnownQuantity(spellLevel)
        while(kq>maxSpellCount || kq>currentKnownCount){
          this.decrementSkill(spellLevel)
          kq -= 1
        }
      }, this)

      talentLevelsShown.forEach(function(talentLevel){
        let maxTalentCount = this.shown_talents.filter(s=>s.rank==talentLevel.rank).length
        let currentKnownCount = this.known_talents_dict[talentLevel.rank].length
        let kq = this.getKnownQuantity(talentLevel)
        while(kq>maxTalentCount || kq>currentKnownCount){
          this.decrementSkill(talentLevel)
          kq -= 1
        }
      }, this)
    },

    classUpdate: function(value) {
      switch(value){
        case 1:
          this.selected_weapon_prof[0]=null
          this.selected_weapon_prof[1]=null
          this.selected_weapon_prof[2]=null
          this.updateAlignment(value)
          this.weaponProfUpdate()
          if(this.weapon_prof_dict[1].length == 1){
            this.selected_weapon_prof[0]=this.weapon_prof_dict[1][0].abilities_id
            this.selected_weapon_prof[1]=this.weapon_prof_dict[1][0].abilities_id
            this.selected_weapon_prof[2]=this.weapon_prof_dict[1][0].abilities_id
          }
          break
        case 2:
          this.selected_weapon_prof[3]=null
          this.selected_weapon_prof[4]=null
          this.selected_weapon_prof[5]=null
          this.updateAlignment(value)
          this.weaponProfUpdate()
          if(this.weapon_prof_dict[2].length == 1){
            this.selected_weapon_prof[3]=this.weapon_prof_dict[2][0].abilities_id
            this.selected_weapon_prof[4]=this.weapon_prof_dict[2][0].abilities_id
            this.selected_weapon_prof[5]=this.weapon_prof_dict[2][0].abilities_id
          }
          break
        case 3:
          this.selected_weapon_prof[6]=null
          this.selected_weapon_prof[7]=null
          this.selected_weapon_prof[8]=null
          this.updateAlignment(value)
          this.weaponProfUpdate()
          if(this.weapon_prof_dict[3].length == 1){
            this.selected_weapon_prof[6]=this.weapon_prof_dict[3][0].abilities_id
            this.selected_weapon_prof[7]=this.weapon_prof_dict[3][0].abilities_id
            this.selected_weapon_prof[8]=this.weapon_prof_dict[3][0].abilities_id
          }
          break
        case 4:
          this.selected_weapon_prof[9]=null
          this.selected_weapon_prof[10]=null
          this.selected_weapon_prof[11]=null
          this.updateAlignment(value)
          this.weaponProfUpdate()
          if(this.weapon_prof_dict[4].length == 1){
            this.selected_weapon_prof[9]=this.weapon_prof_dict[4][0].abilities_id
            this.selected_weapon_prof[10]=this.weapon_prof_dict[4][0].abilities_id
            this.selected_weapon_prof[11]=this.weapon_prof_dict[4][0].abilities_id
          }
          break

        case 5:
          this.selected_master_skills[0]=null
          this.selected_master_skills[1]=null
          this.selected_master_skills[2]=null
          break

        case 6:
          this.selected_master_skills[3]=null
          this.selected_master_skills[4]=null
          this.selected_master_skills[5]=null
          break
        
        case 7:
          this.selected_master_skills[6]=null
          this.selected_master_skills[7]=null
          this.selected_master_skills[8]=null
          break

        default:
          break

      }
      //update ops counter
      this.counter+=1;
      this.$store.commit('updateClasses', this.classes)
      this.updateShownSkills()
      this.updateShownMasterSkills()
      this.updateShownWeaponProfs()
      this.removeUnmatchedSkills()
      this.spellTalentCountUpdate()
      this.recalculateBuildSpent()
    },
    getKnownQuantity(skill){
      var q = this.$store.state.character.skills[skill.abilities_id]
      return (q==null ? 0 : q.quantity)
    },

    getNthCost(ability_id, n, BaseCost, modifier, ability_rank, ability_type_id=0){
      var n_cost = BaseCost * 2**(Math.ceil(n/modifier)-1);
      switch (ability_type_id){
        case 1:
        case 2:
        case 10:
          return (n>1 ? "N/A": n_cost);
        case 9:
          n_cost = BaseCost * 2**(Math.ceil(n/modifier)-2);
          return (n>1 ? n_cost<100?n_cost:100 : 0 )
        case 13:
          return (n>this.shown_spells.filter(s=>s.rank==ability_rank).length ? "N/A": (n_cost<100)? n_cost: 100)
        case 14:
          return (n>this.shown_talents.filter(s=>s.rank==ability_rank).length ? "N/A": (n_cost<100)? n_cost: 100)
        case 15:
          var level_count = {1: 0, 2: 0, 3: 0}
          var weapon_prof_dict = {
            1: this.selected_weapon_prof.slice(0,3),
            2: this.selected_weapon_prof.slice(3,6),
            3: this.selected_weapon_prof.slice(6,9),
            4: this.selected_weapon_prof.slice(9,12)
          }
          for(let level = 1; level<=3; level++){
            Object.values(weapon_prof_dict).forEach(
              function(p){
                if (p.filter(s=> s==ability_id).length>=level){
                  level_count[level] +=1
                }
              }, this)
            } 
          if(n> level_count[1]+level_count[2]+level_count[3]){
            return "N/A"
          }
          else if(n>level_count[1]+level_count[2]){
            return BaseCost*4
          }
          else if (n>level_count[1]){
            return BaseCost*2
          }
          else{
            return BaseCost
          }
        default:
          return (n_cost<100 ? n_cost : 100);
      }
    },

    incrementSkill: function(skill) {
      //update ops counter
      this.counter+=1
      var q = this.getKnownQuantity(skill)
      if ((skill.ability_type == 1 || skill.ability_type == 2 || skill.ability_type == 10) && q==1){
        //Do Nothing
      }
      //Case for Spells and Talents
      else if (((skill.ability_type == 13 && q>=this.shown_spells.filter(s=>s.rank == skill.rank).length)|| (skill.ability_type == 14 && q>=this.shown_talents.filter(s=>s.rank == skill.rank).length))){
        //Do Nothing
      }
      //Case for Weapon Prof Skills
      else if(skill.ability_type == 15 && q>=this.selected_weapon_prof.filter(s => s == skill.abilities_id).length){
        //Do Nothing
      }
      else if (this.buildSpent + this.getNthCost(skill.abilities_id, q+1, skill.cost, this.getModifier(skill.abilities_id, this.classes), skill.rank, skill.ability_type) > this.getBuildTotal()){
        //Do Nothing
      }
      else{
        if (!Object.prototype.hasOwnProperty.call(this.$store.state.character.skills, skill.abilities_id) && this.getNthCost(skill.abilities_id, q+1, skill.cost, this.getModifier(skill.abilities_id, this.classes), skill.rank, skill.ability_type)+this.buildSpent > this.getBuildTotal()){
          //Do Nothing
        }
        else{
          var cost = this.getNthCost(skill.abilities_id, q+1, skill.cost, this.getModifier(skill.abilities_id, this.classes), skill.rank, skill.ability_type);

          this.$store.commit('updateSkill', [skill.abilities_id, "+", cost])
          this.buildSpent += cost
          //Check to see if incrementing Spells or Talents
          if(skill.ability_type == 13){
            this.spells_purchased_dict[skill.rank]+=1
          }
          else if(skill.ability_type == 14){
            this.talents_purchased_dict[skill.rank]+=1
          }
          else if(skill.ability_type == 10){
            this.paragonCount+=1
          }
          else if(skill.ability_type == 9 && this.getKnownQuantity(skill)==1){
            this.attributeCount+=1
          }
        }
      }
      this.$emit("skill_mutate")
    },
    decrementSkill: function(skill) {
      //update ops counter
      this.counter+=1;
      var cost = this.getNthCost(skill.abilities_id, this.getKnownQuantity(skill), skill.cost, this.getModifier(skill.abilities_id, this.classes), skill.rank, skill.ability_type)
      if(Object.prototype.hasOwnProperty.call(this.$store.state.character.skills, skill.abilities_id)){
        this.buildSpent -= cost
      }
      this.$store.commit('updateSkill', [skill.abilities_id, "-", cost])
      if(skill.ability_type == 10){
            this.paragonCount-=1
          }
      else if(skill.ability_type == 9 && this.getKnownQuantity(skill)==0){
            this.attributeCount-=1
          }
      this.$emit("skill_mutate")
    },

    getTotalPurchased(id){
      var t = this.$store.state.character.skills[id]
      return (t==null ? 0 : t.quantity)
    },

    getTotalCost(n, BaseCost, modifier, ability_id, ability_rank, ability_type){
      return (modifier==0? 0 :(n>0 ? this.getTotalCost(n-1, BaseCost, modifier, ability_id, ability_rank, ability_type) + this.getNthCost(ability_id, n, BaseCost, modifier, ability_rank, ability_type) : 0));
    },

    getBuildTotal(){
      var t = this.$store.state.character.buildTotal
      return (isNaN(t) ? 0 : t)
    },

    removeUnmatchedSkills(){
      const t = this.$store.state.character.skills;
      Object.keys(t).forEach(function(s){
        if((this.shown_skills.concat(
          this.shown_common).concat(
            this.shown_master_skills).concat(
              this.shown_spells).concat(
                this.shown_talents).concat(
                  this.hidden_skills)).findIndex(ability=>{return ability.abilities_id==s})!= -1){/*Do Nothing*/}
        else{
          this.$store.commit('updateSkill', [s, "x"])
          if(this.skills.find(a=>a.abilities_id == s).ability_type == 10){
            this.paragonCount -=1
          }
        }
      }, this)
      this.removeUnmatchedSpellsTalents()
      this.fixParagons()
    },

    removeUnmatchedSpellsTalents(){
      //Start with Spells
      const ksd = this.known_spells_dict
      this.known_spells_dict[0] = this.shown_spells.filter(a=>ksd[0].includes(a.abilities_id)).map(a=>a.abilities_id) 
      this.known_spells_dict[1] = this.shown_spells.filter(a=>ksd[1].includes(a.abilities_id)).map(a=>a.abilities_id) 
      this.known_spells_dict[2] = this.shown_spells.filter(a=>ksd[2].includes(a.abilities_id)).map(a=>a.abilities_id) 
      this.known_spells_dict[3] = this.shown_spells.filter(a=>ksd[3].includes(a.abilities_id)).map(a=>a.abilities_id)
      this.known_spells_dict[4] = this.shown_spells.filter(a=>ksd[4].includes(a.abilities_id)).map(a=>a.abilities_id)

      //Move onto Talents
      const ktd = this.known_talents_dict
      this.known_talents_dict[0] = this.shown_talents.filter(a=>ktd[0].includes(a.abilities_id)).map(a=>a.abilities_id) 
      this.known_talents_dict[1] = this.shown_talents.filter(a=>ktd[1].includes(a.abilities_id)).map(a=>a.abilities_id) 
      this.known_talents_dict[2] = this.shown_talents.filter(a=>ktd[2].includes(a.abilities_id)).map(a=>a.abilities_id) 
      this.known_talents_dict[3] = this.shown_talents.filter(a=>ktd[3].includes(a.abilities_id)).map(a=>a.abilities_id)
      this.known_talents_dict[4] = this.shown_talents.filter(a=>ktd[4].includes(a.abilities_id)).map(a=>a.abilities_id)

      //Handle Spell and Talent Purchases
      //If undefined, skill does not currently exist, set purchased count to zero
      let skillCheck = this.hidden_skills.find(skill => skill.rank==0 && skill.ability_type==13)
      this.spells_purchased_dict[0] = (skillCheck == undefined ? 0 : this.known_spells_dict[0].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==1 && skill.ability_type==13)
      this.spells_purchased_dict[1] = (skillCheck == undefined ? 0 : this.known_spells_dict[1].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==2 && skill.ability_type==13)
      this.spells_purchased_dict[2] = (skillCheck == undefined ? 0 : this.known_spells_dict[2].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==3 && skill.ability_type==13)
      this.spells_purchased_dict[3] = (skillCheck == undefined ? 0 : this.known_spells_dict[3].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==4 && skill.ability_type==13)
      this.spells_purchased_dict[4] = (skillCheck == undefined ? 0 : this.known_spells_dict[4].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==0 && skill.ability_type==14)
      this.talents_purchased_dict[0] = (skillCheck == undefined ? 0 : this.known_talents_dict[0].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==1 && skill.ability_type==14)
      this.talents_purchased_dict[1] = (skillCheck == undefined ? 0 : this.known_talents_dict[1].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==2 && skill.ability_type==14)
      this.talents_purchased_dict[2] = (skillCheck == undefined ? 0 : this.known_talents_dict[2].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==3 && skill.ability_type==14)
      this.talents_purchased_dict[3] = (skillCheck == undefined ? 0 : this.known_talents_dict[3].length)

      skillCheck = this.hidden_skills.find(skill => skill.rank==4 && skill.ability_type==14)
      this.talents_purchased_dict[4] = (skillCheck == undefined ? 0 : this.known_talents_dict[4].length)
    },

    getKnownParagonsByClass(){
      let t = this.$store.state.character.skills;
      const paragonsKnown = Object.keys(t).filter(function(s){
        try{
          return this.shown_skills.find(a=>a.abilities_id ==s).ability_type == 10
        }catch(e){
          //This Catch is required to catch the skills that don't show up in shown_skills
          //but are still known by the character like Spells, Talents, Hidden, and Master Skills
          return false
        }
        }, this)
      let paragonsByClass = {}
      this.classes.slice(1,5).forEach(c=>paragonsByClass[c]?paragonsByClass[c]["quantity"]+=1: paragonsByClass[c]={quantity:1, skills:[]})
      paragonsKnown.forEach(
        function(p){
          try{
            paragonsByClass[this.skills_by_class.find(
            abc=>abc.ability==p).class_field]["skills"].push(p)
          }catch(e){
            //Catches case where class is no longer known, as this is invoked prior to the unlearn
            return
          }
        }
        ,this)
      return paragonsByClass
    },

    fixParagons(){
      let paragonsByClass = this.getKnownParagonsByClass()
      Object.keys(paragonsByClass).forEach(function(k){
        let difference = paragonsByClass[k]["skills"].length - paragonsByClass[k]["quantity"]
        while(difference>0){
          let s = paragonsByClass[k]["skills"].pop()
          this.$store.commit('updateSkill', [s, "x"])
          this.paragonCount -=1
          difference--
        }
      }, this)
    },

    recalculateBuildSpent(){
      var newSpent = 0;
      var t = this.$store.state.character.skills;
      Object.keys(t).forEach(function(s){
        var cost = this.getTotalCost(this.getTotalPurchased(s), this.skills.find(ability => {return ability.abilities_id == s}).cost, this.getModifier(s, this.classes), s, this.skills.find(ability => ability.abilities_id == s).rank, this.skills.find(ability => ability.abilities_id == s).ability_type);
        newSpent += cost;
        this.$store.commit('updateSkill', [s, "u", cost])
      }, this)
      this.buildSpent = newSpent;
    },

    check(e, skill){
      //E == false when array is empty
      if(e == false){
        //Remove Spell/Talent from known skills
        this.$store.commit('updateSkill', [skill.abilities_id, "-", 0])
        if(skill.ability_type == 4){
            this.decrementSkill(this.spell_purchase[skill.rank])
          }
          else if(skill.ability_type == 5){
            this.decrementSkill(this.talent_purchase[skill.rank])
          }
      }
      //2 possible cases to check if E != false
      else{
        if(e.includes(skill.abilities_id)){
          //Add Spell/Talent to known skills
          this.$store.commit('updateSkill', [skill.abilities_id, "+", 0])
          //Increment Spell/Talent Purchased at Rank with the following check
          if(skill.ability_type == 4){
            this.incrementSkill(this.spell_purchase[skill.rank])
          }
          else if(skill.ability_type == 5){
            this.incrementSkill(this.talent_purchase[skill.rank])
          }
        }
        else{
          //Removed Spell/Talent from known skills
          this.$store.commit('updateSkill', [skill.abilities_id, "-", 0])
          if(skill.ability_type == 4){
            this.decrementSkill(this.spell_purchase[skill.rank])
          }
          else if(skill.ability_type == 5){
            this.decrementSkill(this.talent_purchase[skill.rank])
          }
        }
      }
      this.$emit("skill_mutate")
    },

    getBodyTotal(){
      let bodyTotal = 0
      this.classes.filter(c=> c!=null).forEach(c => bodyTotal+=this.class_list.find(cl=> cl.id == c).body, this)
      return bodyTotal;
    },

    abilityTypeString(id){
      switch(id){
        case 1: return "Literacy"
        case 2: return "Passive"
        case 3: return "Periodic"
        case 4: return "Spell"
        case 5: return "Talent"
        case 6: return "Defense"
        case 7: return "Attack"
        case 8: return "Background"
        case 9: return "Attribute"
        case 10: return "Paragon"
        case 11: return "Lore"
        case 12: return "Hidden"
        case 13: return "Spell Purchase"
        case 14: return "Talent Purchase"
        case 15: return "Damage Proficiency"
        case 16: return "Prestige Skill"
        case 17: return "Prestige"
      }
    },

    populateOriginalCharacter(){
      var knownClasses = this.classes
      var list_alignments = this.list_alignments
      const alignment_by_class = this.alignment_by_class
      this.originalCharacter.known_classes.forEach(kc => {
        knownClasses[kc.class_index] = kc.class_field.id
        //the following expression finds the abilties by class ID for a particular entity name for
        //a particular class
        //alignment_by_class.filter(row=> (row['class_field']==kc.class_field.id)&&row['entity']==kc.entity)[0].id
        if(kc.entity!=""){
          try{
            list_alignments[kc.class_index] = (kc.entity == "" ? null : alignment_by_class.find(row=> (row['class_field']==kc.class_field.id)&&row['entity']==kc.entity).id)
          }catch(e){
            list_alignments[kc.class_index] = (kc.entity == "" ? null : alignment_by_class.find(row=> (row['class_field']==kc.class_field.id).id))
          }
        }
        else{
          this.updateAlignment(kc.class_index)
        }
      }, this);

        //Populate the Known Skills
      var knownSkills = {}
      this.originalCharacter.abilities.forEach(ka => {
          knownSkills[ka.ability.abilities_id] = {quantity: ka.quantity, cost: ka.build_spent, skillfullness: ka.skillfullness}
          //Populate Purchased Spells
          if(ka.ability.ability_type == 13){
            this.spells_purchased_dict[ka.ability.rank] = ka.quantity
          }
          //Populate Purchased Talents
          else if(ka.ability.ability_type == 14){
            this.talents_purchased_dict[ka.ability.rank] = ka.quantity
          }
          //Populate Known Spellts
          else if(ka.ability.ability_type == 4){
            this.known_spells_dict[ka.ability.rank].push(ka.ability.abilities_id)
          }
          //Populate Known Talents
          else if(ka.ability.ability_type == 5){
            this.known_talents_dict[ka.ability.rank].push(ka.ability.abilities_id)
          }
          //Increment Paragon Count
          else if(ka.ability.ability_type == 10){
            this.paragonCount+=1
          }

          //Increment Attribute Count
          else if(ka.ability.ability_type == 0){
            this.attributeCount+=1
          }

        },this);
        this.$store.state.character.skills = knownSkills

        //Populate the Selected Master List Skills
        const masterSkills = this.originalCharacter.master_list_skills[0]
        this.selected_master_skills = [masterSkills.master_skill_1, masterSkills.master_skill_2, masterSkills.master_skill_3, masterSkills.master_skill_4, masterSkills.master_skill_5, masterSkills.master_skill_6, masterSkills.master_skill_7, masterSkills.master_skill_8, masterSkills.master_skill_9]
        
        //Populate the Selected Weapon Prof Skills
        const weaponProfs = this.originalCharacter.weapon_proficiency_skills[0]        
        this.$store.commit('updateSelectedWeaponProf', [weaponProfs.weapon_proficiency_skill_1, weaponProfs.weapon_proficiency_skill_2, weaponProfs.weapon_proficiency_skill_3, weaponProfs.weapon_proficiency_skill_4, weaponProfs.weapon_proficiency_skill_5, weaponProfs.weapon_proficiency_skill_6, weaponProfs.weapon_proficiency_skill_7, weaponProfs.weapon_proficiency_skill_8, weaponProfs.weapon_proficiency_skill_9, weaponProfs.weapon_proficiency_skill_10, weaponProfs.weapon_proficiency_skill_11, weaponProfs.weapon_proficiency_skill_12])

        //TODO add method to check for each rank of spell and talent known, confirm correct amount purchased
        //if less spells purchased at a rank thank currently known, decrement until correct
        this.$store.state.character.characterMemo = this.originalCharacter.character_memo
        //Refresh Everything
        this.classUpdate()
        //we can update a single alignment to update all alignments
        this.updateSpecificAlignment()
        this.updateShownSkills();
        this.updateShownMasterSkills();
        this.removeUnmatchedSkills();
    },

    resetToOriginalCharacter(){
      this.clearSkillsClasses()
      this.populateOriginalCharacter()
    },

    clearOrReset(){
      if(this.operation=='clear'){
        this.clearSkillsClasses()
      }
      else if(this.operation=='reset'){
        this.resetToOriginalCharacter()
      }
      this.dialog = false
    },

    allowNextPurchase(item){
      let cost = this.getNthCost(item.abilities_id, this.getTotalPurchased(item.abilities_id)+1, item.cost, this.getModifier(item.abilities_id, this.classes), item.rank, item.ability_type)
      let buildRemaining = this.getBuildTotal()-this.buildSpent
      let moreParagonsThanClasses = this.paragonCount>=this.classes.slice(1,5).filter(c=> c!=null).length
      if (cost == "N/A"){
        return false
      }
      else if(item.ability_type == 1 && this.$store.state.character.literacy==item.abilities_id){
        return false
      }
      else if (item.ability_type == 10 && moreParagonsThanClasses){
        return false
      }
      else if (item.ability_type == 10){
        let paragonsByClass = this.getKnownParagonsByClass()
        let item_class = this.skills_by_class.find(abc=>abc.ability == item.abilities_id).class_field
        if(paragonsByClass[item_class]["skills"].length >= paragonsByClass[item_class]["quantity"]){
          return false
        }
      }
      else if (item.ability_type == 9){
        if(this.getKnownQuantity(item) <1 && this.attributeCount >= 2){
          return false
        }
        else{
          return cost > buildRemaining ? false : true 
        }
      }
      return cost > buildRemaining ? false : true 
    }
  }
}
</script>